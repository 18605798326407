import {
  USER_FAILURE,
  USER_REQUEST,
  GET_ALL_USER_SUCCESS,
  CREATE_USER_SUCCESS,
  UPDATE_USER_SUCCESS,
  SEARCHED_USER_SUCCESS,
  DELETE_USER_SUCCESS,
} from "../constants";

const initialState = {
  data: [],
  loading: false,
  message: "",
  error: false,
};

export function userReducer(userState = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case USER_REQUEST:
      return {
        ...userState,
        loading: true,
        error: false,
        message: "",
      };

    case USER_FAILURE:
      return {
        ...userState,
        message: payload.message,
        loading: false,
        error: true,
      };

    case CREATE_USER_SUCCESS:
      return {
        ...userState,
        data: [payload, ...userState.data],
        error: false,
        loading: false,
      };

    case GET_ALL_USER_SUCCESS:
      return {
        ...userState,
        data: payload,
        error: false,
        loading: false,
      };

    case UPDATE_USER_SUCCESS:
      return {
        ...userState,
        data: userState?.data?.map((user) =>
          user._id === payload._id ? payload : user
        ),
        error: false,
        loading: false,
      };

    case DELETE_USER_SUCCESS:
      return {
        ...userState,
        data: userState.data.filter((user) => user._id !== payload._id),
        error: false,
        loading: false,
      };

    case SEARCHED_USER_SUCCESS:
      return {
        ...userState,
        data: payload,
        error: false,
        loading: false,
      };

    default:
      return userState;
  }
}
