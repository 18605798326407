import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Select,
  Spin,
  notification,
} from "antd";
import { GoogleOutlined, AppleFilled, FacebookFilled,LoadingOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useDispatch} from "react-redux";
import { signupOption } from "../../../redux/actions/login";
import axios from "axios";
import allCountries from '../../../utils/countries/countries.json'

const SubmitButton = ({ form ,spinner}) => {
  const [submittable, setSubmittable] = React.useState(false);
 
  // Watch all values
  const values = Form.useWatch([], form);
  React.useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        }
      );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  return (
    <Button
      type="primary"
      htmlType="submit"
      className="bg-[#002366] px-10 min-h-[40px] flex items-center justify-center rounded-2xl capitalize font-semibold text-base"
      disabled={!submittable}
    >
       signup {spinner? <Spin indicator={antIcon} className="ml-1" /> :
      <ArrowRightOutlined className="" />}
    </Button>
  );
};

const SignUp = () => {
  const [api, contextHolder] = notification.useNotification();
  // const [allCountries,setAllCountries] = useState([])
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const validateUserName = (rule, value, callback) => {
    const regex = /^[A-Za-z_ ]+$/; // Allow only letters (both uppercase and lowercase)
    if (!regex.test(value)) {
      callback('Only text characters are allowed!');
    } else {
      callback();
    }
  };
  const validateEmail = (rule, value, callback) => {
    // You can use a regular expression to validate the email format
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    
  if (!emailRegex.test(value)) {
      callback('Invalid email format');
    } else {
      callback();
    }
  };


  const numberValidation = (rule, value, callback) => {
    const numberPattern = /^[0-9]*$/;
    if (numberPattern.test(value)) {
      if (rule.field === "discountedPrice") {
        const originalPrice = form.getFieldValue("originalPrice");
        const discountedPrice = form.getFieldValue("discountedPrice");
        if (+discountedPrice > +originalPrice) {
          callback("Discounted Price should be less than Original Price");
        } else {
          callback();
        }
      }
      callback();
    } else {
      callback("Please enter only numbers");
    }
  };
  const onFinish = async (values) => {
    setLoading(true);
    const signResult = await dispatch(signupOption(values));
      if (signResult?.data) {
      api.success({ message:signResult?.message || "Success"  });
      form.resetFields()
      setLoading(false);
    } else {
      api.error({ message:signResult?.error || "Something went wrong" });
      setLoading(false);
    }

  };

  // useEffect(()=>{
  //   (async () => {
  //     try {
      
  //         const response = await axios.get(
  //           `https://restcountries.com/v2/all`
  //         ); 
  //           setAllCountries(response?.data)
        
  //     } catch (error) {
  //       console.log("errorr", error);
  //     }
  //   })();

  // },[])
  
  return (
    <>
      {contextHolder}
      <div className="flex sign_up_wrapper h-[100vh]">
        <div className="w-[45%] bg-[url('/public/images/backJp.jpg')] signup_col_with_bg bg-cover bg-no-repeat hideOnMobilesignup">
          <div className="text-white w-[70%] mx-auto">
            <h1 className="text-6xl mt-4 font-semibold pb-6 text-center">
              Welcome to our Sign Up Page
            </h1>
            <p className="font-semibold text-base text-[#CBD5E1] text-center">
            Join us at SocialProfit. Sign up now to open a door to possibilities for social and affiliate success
            </p>
          </div>
        </div>

        <div
          className=" bg-[#F8FAFC] mx-auto  items-center py-10 showOnMobile "
          style={{ maxHeight: "calc(100vh - 0px)", overflowY: "auto" }}
        >
          <div className="w-3/5 ml-[103px]  rounded-md flex flex-col justify-center ">
            <h1 className="text-5xl font-bold mt-0 mb-1 pb-4">Register With</h1>
            <p className="text-[#52525B] text-lg pt-2 pb-5">
              Join us at SocialProfit. Sign up now to open a door to possibilities for social and affiliate success
            </p>
            {/* <div className="flex flex-col my-4 gap-1 w-[55%]">
              <div className="flex flex-row gap-2 ">
                <button className="border rounded-md p-2 flex items-center ">
                  <GoogleOutlined className="mr-1" />
                  <span className="text-xs">Google</span>
                </button>
                <button className="border rounded-md p-2 flex items-center">
                  <AppleFilled className="mr-1" />
                  <span className="text-xs">Apple</span>
                </button>
                <button className="border rounded-md p-2 flex items-center">
                  <FacebookFilled className="mr-1" />
                  <span className="text-xs">Facebook</span>
                </button>
              </div>
              <Divider>
                <span className="border px-3 py-2 rounded-[50%] bg-white">
                  OR
                </span>
              </Divider>
            </div> */}

            <Form
              form={form}
              name="basic"
              layout="vertical"
              style={{ maxWidth: 600 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              className="modal_parent_wrapper"
              autoComplete="off"
            >
              <Form.Item
                label="Name"
                name="name"
                className="font-semibold mb-3"
                rules={[
                  {
                    validator: validateUserName,
                  },
                  { required: true, message: "Please input your username!" },
                ]}
              >
                <Input className="w-full  py-2 rounded-2xl" />
              </Form.Item>

              <Form.Item
                label="Email"
                name="email"
                className="font-semibold mb-3"
                rules={[
                  { required: true, message: 'Please enter your email address' },
                  { validator: validateEmail }, // Custom validation rule
                ]}
              >
                <Input className="w-full  py-2 rounded-2xl" />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                className="font-semibold mb-3"
                rules={[
                  { required: true, message: 'Please enter your password' },
                  { min: 8, message: 'Password must be at least 8 characters long' },
                  { pattern:/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]*$/, message: 'Password must contain at least one letter and one number and one special character' },
                ]}
              >
                <Input.Password className=" py-2 rounded-2xl" />
              </Form.Item>
              <Form.Item
                label="Phone"
                name="mobile"
                className="font-semibold mb-3"
                rules={[{ required: true, message: "This field is required." }]}
              >
                <Input className=" py-2 rounded-2xl" />
              </Form.Item>
              <Form.Item
                label="What is your age?"
                name="ageRange"
                className="font-semibold mb-3"
                rules={[
                  {
                    required: true,
                    message: "Please input a number!",
                  },
                  {
                    validator: numberValidation, // Use the custom validation function
                  },
                ]}
              >
                <Input className=" py-2 rounded-2xl" />

                {/* <Select className="w-full">
                  <Select.Option value="0 - 10">0 - 10</Select.Option>
                  <Select.Option value="10 - 20">10 - 20</Select.Option>
                  <Select.Option value="20 - 30">20 - 30</Select.Option>
                  <Select.Option value="40 - 50">40 - 50</Select.Option>
                  <Select.Option value="50 or above">50 or above</Select.Option>
                </Select> */}
              </Form.Item>
              <Form.Item
                label="What is your audience size across all platforms?"
                name="audienceSize"
                className="font-semibold mb-3"
                rules={[
                  {
                    required: true,
                    message: "Please input the audience size across all platforms!",
                  },
                ]}
              >
                <Select className="w-full">
                  <Select.Option value="0 - 1000">0 - 1000</Select.Option>
                  <Select.Option value="1000 - 10,000">
                    1000 - 10,000
                  </Select.Option>
                  <Select.Option value="10,000 - 20,000">
                    10,000 - 20,000
                  </Select.Option>
                  <Select.Option value="20,000 - 50,000">
                    20,000 - 50,000
                  </Select.Option>
                  <Select.Option value="50,000 - 1,00,000">
                    50,000 - 1,00,000
                  </Select.Option>
                  <Select.Option value="1,00,000 -  25,00,000">
                  1,00,000 -  25,00,000
                  </Select.Option>
                  <Select.Option value="25,00,000 or above">
                  25,00,000 or above
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Are you affiliated with any other network?"
                name="affiliateWithOtherSites"
                className="font-semibold mb-3"
                rules={[{ required: true, message: "This field is required." }]}
              >
                <Input className=" py-2 rounded-2xl" />
              </Form.Item>
              <Form.Item
                label="How do you plan to generate traffic?"
                name="trafficGenerationMethod"
                className="font-semibold mb-3"
                rules={[{ required: true, message: "This field is required." }]}
              >
                <Input className=" py-2 rounded-2xl" />
              </Form.Item>
              <Form.Item
                label="In which niche do you operate?"
                name="niche"
                className="font-semibold mb-3"
                rules={[{ required: true, message: "This field is required." }]}
              >
                <Input className=" py-2 rounded-2xl" />
              </Form.Item>
              <Form.Item
                label="In which country do you intend to operate?"
                name="clientCountries"
                className="font-semibold mb-3"
                rules={[{ required: true, message: "Please select country!" }]}
              >
                <Select className="w-full" showSearch>
                  {
                    allCountries?.map((country)=>{
                      return <Select.Option value={country?.name}>{country?.name || "loading..."}</Select.Option>
                    })
                  }
                 
                </Select>
              </Form.Item>
              <Form.Item
                label="Please enter the URL where you will be promoting your deals"
                name="promotingUrl"
                className="font-semibold mb-3"
                // rules={[{ required: true, message: "This field is required." },{ type: 'url'}]}
              >
                <Input className=" py-2 rounded-2xl" />
              </Form.Item>
              <Form.Item
                label="LinkedIn Url"
                name="linkedinUrl"
                className="font-semibold mb-3"
                // rules={[{ required: true, message: "This field is required." },{ type: 'url'}]}
              >
                <Input className=" py-2 rounded-2xl" />
              </Form.Item>
              <Form.Item
                label="Facebook Url"
                name="facebookUrl"
                className="font-semibold mb-3"
                // rules={[{ required: true, message: "This field is required." },{ type: 'url'}]}
              >
                <Input className=" py-2 rounded-2xl" />
              </Form.Item>
              <Form.Item
                label="Twitter Url"
                name="twitterUrl"
                className="font-semibold mb-3"
                // rules={[{ required: true, message: "This field is required." },{ type: 'url'}]}
              >
                <Input className=" py-2 rounded-2xl" />
              </Form.Item>
              <Form.Item
                label="Instagram Url"
                name="instagramUrl"
                className="font-semibold mb-3"
                // rules={[{ required: true, message: "This field is required." },{ type: 'url'}]}
              >
                <Input className=" py-2 rounded-2xl" />
              </Form.Item>

              <Form.Item
                name="termsAndConditions"
                valuePropName="checked"
                wrapperCol={{ offset: 0, span: 16 }}
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(new Error("Should accept agreement")),
                  },
                ]}
                noStyle
              >
                <Checkbox>
                  I accept the <b>terms & conditions</b>
                </Checkbox>
              </Form.Item>

              <Form.Item className="pt-5 mb-0">
                <SubmitButton form={form} spinner={loading}/>
                <br />
                <p className=" mt-1 text-[#64748B]">
                  Already have an account?{" "}
                  <Link to="/login">
                    <strong className="text-[#002366]">Sign In</strong>
                  </Link>
                </p>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
