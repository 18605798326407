import {
  LOGIN_USER_FAILURE,
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  SIGNUP_USER_FAILURE,
  SIGNUP_USER_REQUEST,
  SIGNUP_USER_SUCCESS,
} from "../constants";

const initialState = {
  data: [],
  loading: false,
  message: "",
  error: false,
};

export function loginReducer(loginState = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_USER_REQUEST: {
      loginState.loading = true;
      loginState.error = false;
      loginState.message = "";
      return loginState;
    }
    case LOGIN_USER_FAILURE: {
      loginState.message = payload.error.message;
      loginState.loading = false;
      loginState.error = true;
      return loginState;
    }

    case LOGIN_USER_SUCCESS: {
      loginState.data = [payload];
      loginState.error = false;
      loginState.loading = false;
      return loginState;
    }

    default:
      return loginState;
  }
}

export function signupReducer(signupState = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SIGNUP_USER_REQUEST: {
      signupState.loading = true;
      signupState.error = false;
      signupState.data = [];
      return signupState;
    }
    case SIGNUP_USER_FAILURE: {
      signupState.loading = false;
      signupState.error = true;
      signupState.message = payload.error;
      signupState.data = [];
      return signupState;
    }
    case SIGNUP_USER_SUCCESS: {
      signupState.loading = false;
      signupState.error = false;
      signupState.message = "";
      signupState.data = [payload];
      return signupState;
    }

    default:
      return signupState;
  }
}
