import React, { useState } from "react";
import Layout from "../../../common/layout";
import totalUserIcon from "../../../common/assets/images/totalUserCard.svg";
import totalRevenueIcon from "../../../common/assets/images/totalRevenueIcon.svg";
import totalAffiliateIcon from "../../../common/assets/images/totalAffiliateIcon.svg";
import adminFeeIcon from "../../../common/assets/images/adminFeeIcon.svg";
import statLine1 from "../../../common/assets/images/frame1.svg";
import statLine2 from "../../../common/assets/images/frame2.svg";
import statLine3 from "../../../common/assets/images/frame3.svg";
import statLine4 from "../../../common/assets/images/frame4.svg";
import BarChart from "../chart/BarChart";
import { Avatar, DatePicker, List } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getAllDashboardReports } from "../../../redux/actions/dashboardReports";
import LineChart from "../chart/LineChart";
import axios from "axios";
import { NOTIFICATIONS_URL } from "../../../utils/api/urls";
const { RangePicker } = DatePicker;

const AdminDashboard = ({ socketResponse }) => {
  const reportsData = useSelector((store) => store?.dashboardReport?.data);
  const profileId = useSelector((store) => store.profileData.data._id);
  const summaryData = reportsData.summaryData?.[0];
  const [filterReports, setFilterReports] = useState("");
  const [notificationData,setNotificationData] = useState([])
  const dispatch = useDispatch();


  useEffect(() => {
    (async () => {
      await dispatch(getAllDashboardReports(filterReports));
    })();
    // eslint-disable-next-line
  }, [filterReports]);

  useEffect(() => {
    (async () => {
      try {
        if (profileId) {
          const headers = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            // Add any other headers if needed
          };
          const response = await axios.get(
            `${NOTIFICATIONS_URL}/${profileId}`,
            { headers }
          ); 
          setNotificationData(response?.data?.data)
          
        } else {
          return;
        }
      } catch (error) {
      }
    })();
  }, [profileId]);
  

  // useEffect(()=>{
  //   if(socketResponse){

  //     setNotificationData([socketResponse,...notificationData])
  //   }


  // },[socketResponse])

  

  
  return (
    <Layout  profileId={profileId}>
      <div>
        <div>
          <div className="flex items-end justify-between ">
            <h1 className="pt-3 font-semibold text-2xl font-Mulish">
              Reporting & Analytics
            </h1>

            <RangePicker onChange={(D, FD) => setFilterReports(FD)} />

            {/* <Select
      defaultValue="Today"
      style={{
        width: 120,
      }}
      onChange={handleChange}
      
      options={[
        {
          value: 'today',
          label: 'Today',
        },
        {
          value: 'lastWeek',
          label: 'Last Week',
        },
        {
          value: 'lastMonth',
          label: 'Last Month',
        }
      ]}
    /> */}
          </div>
          <div className="flex  justify-between mt-5 gap-2">
            <div className="flex gradTotalUser px-2 py-3 items-center rounded-lg">
              <div className="border-dashed border-[2px] rounded-full max-w-[55px] max-h-[55px] min-w-[55px] min-h-[55px] p-2 flex items-center justify-center">
                <img
                  src={totalUserIcon}
                  className=" max-w-[32px] max-h-[32px]"
                  alt="total user icon"
                />
              </div>

              <div className="pl-4 text-white">
                <h6 className="font-semibold text-lg">Total User</h6>
                <p className="font-bold text-2xl">{summaryData?.users}</p>
              </div>
              <div>
                <img
                  src={statLine1}
                  className=" px-3 max-w-[85px] max-h-[26px]"
                  alt="straight line"
                />
              </div>
            </div>

            <div className="flex gradTotalRevenue px-2 py-3 items-center rounded-lg">
              <div className="border-dashed border-[2px] rounded-full max-w-[55px] max-h-[55px] min-w-[55px] min-h-[55px] p-2 flex items-center justify-center">
                <img
                  src={totalRevenueIcon}
                  className=" max-w-[32px] max-h-[32px]"
                  alt="total revenue icon"
                />
              </div>
              <div className="pl-4 text-white">
                <h6 className="font-semibold text-lg">Total Revenue</h6>
                <p className="font-bold text-2xl">
                  <strong>$</strong> {summaryData?.totalRevenue}
                </p>
              </div>
              <div>
                <img
                  src={statLine2}
                  className=" px-3 max-w-[85px] max-h-[26px]"
                  alt="straight line"
                />
              </div>
            </div>

            <div className="flex gradTotalAffiliate px-2 py-3 items-center rounded-lg">
              <div className="border-dashed border-[2px] rounded-full max-w-[55px] max-h-[55px] min-w-[55px] min-h-[55px] p-2 flex items-center justify-center">
                <img
                  src={totalAffiliateIcon}
                  className=" max-w-[32px] max-h-[32px]"
                  alt="total affiliate"
                />
              </div>
              <div className="pl-4 text-white">
                <h6 className="font-semibold text-lg">Total Affiliate</h6>
                <p className="font-bold text-2xl">
                  <strong>$</strong> {summaryData?.totalAffiliates}
                </p>
              </div>
              <div>
                <img
                  src={statLine3}
                  className=" px-3 max-w-[85px] max-h-[26px]"
                  alt="straight line"
                />
              </div>
            </div>

            <div className="flex gradadminFee px-2 py-3 items-center rounded-lg">
              <div className="border-dashed border-[2px] rounded-full max-w-[55px] max-h-[55px] min-w-[55px] min-h-[55px] p-2 flex items-center justify-center">
                <img
                  src={adminFeeIcon}
                  className=" max-w-[32px] max-h-[32px]"
                  alt="admin fees"
                />
              </div>
              <div className="pl-4 text-white">
                <h6 className="font-semibold text-lg">Admin Fee</h6>
                <p className="font-bold text-2xl">
                  <strong>$</strong> {summaryData?.totalAdminFee}
                </p>
              </div>
              <div>
                <img
                  src={statLine4}
                  className=" px-3 max-w-[85px] max-h-[26px]"
                  alt="static line"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex gap-4">
          <div className="w-1/2 bg-white mt-9 rounded">
            {/* <PieChart reports={reportsData}/> */}
            <LineChart reports={reportsData?.monthBasedData} />
          </div>

          <div className="w-1/2 bg-white mt-9 rounded">
            <BarChart reports={reportsData?.monthBasedData} />
          </div>
        </div>

        <div className="flex gap-4">
          <div className="w-full bg-white my-6 rounded p-5">
            <h3 className="pb-5">Notifications</h3>
            <List
              itemLayout="horizontal"
              dataSource={notificationData}
              renderItem={(item, index) => (
                
                <List.Item>
                  <List.Item.Meta
                    // avatar={
                    //   <Avatar
                    //     src={`https://xsgames.co/randomusers/avatar.php?g=pixel&key=${index}`}
                    //   />
                    // }
                    title={item?.message}
                    // description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                  />
                </List.Item>
              )}
            />
          </div>

          {/* <div className="w-1/2 bg-white my-6 rounded p-5">
          <h3 className=" pb-5">Store Analytics</h3>
          <List
            itemLayout="horizontal"
            dataSource={data2}
            renderItem={(item, index) => (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <Avatar
                      src={`https://xsgames.co/randomusers/avatar.php?g=pixel&key=${index}`}
                    />
                  }
                  title={<a href="https://ant.design">{item.title}</a>}
                  // description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                />
              </List.Item>
            )}
          />
        </div> */}
        </div>
      </div>
    </Layout>
  );
};

export default AdminDashboard;
