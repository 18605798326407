import { DASHBOARD_REPORT_FAILURE, DASHBOARD_REPORT_REQUEST, GET_ALL_DASHBOARD_REPORT_SUCCESS } from "../constants";


const initialState = {
    data: [],
    loading: false,
    message: "",
    error: false,
  };
  
  export function dashboardReportReducer(dashboardState = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case DASHBOARD_REPORT_REQUEST:
        return {
          ...dashboardState,
          loading: true,
          error: false,
          message: "",
        };
  
      case DASHBOARD_REPORT_FAILURE:
        return {
          ...dashboardState,
          message: payload.message,
          loading: false,
          error: true,
        };
  
      case GET_ALL_DASHBOARD_REPORT_SUCCESS:
        return {
          ...dashboardState,
          data: payload,
          error: false,
          loading: false,
        };
  
      default:
        return dashboardState;
    }
  }