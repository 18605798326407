import React, { useState } from "react";
import { Button, Form, Input, Spin, notification } from "antd";
import { ArrowRightOutlined,LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import { BASE_URL,  RESET_PASSWORD_URL } from "../../../utils/api/urls";
import { useNavigate, useParams } from "react-router-dom";

const SubmitButton = ({ form,spinner }) => {
  const [submittable, setSubmittable] = React.useState(false);
  

  // Watch all values

  const values = Form.useWatch([], form);
  React.useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        }
      );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 22,
      }}
      spin
    />
  );


    

  
  
  return (
    <Button
      type="primary"
      htmlType="submit"
      className="bg-[#002366] px-10 min-h-[40px] flex items-center justify-center rounded-2xl capitalize font-semibold text-base"
      disabled={!submittable}
    >
      Submit
      {spinner ? (
        <Spin indicator={antIcon} className="ml-1" />
      ) : (
        <ArrowRightOutlined className="" />

      )}
    </Button>
  );
};

const ConfirmPassword = () => {
  const [api, contextHolder] = notification.useNotification();

    const {token} = useParams();
    const navigate = useNavigate()
    console.log('token', token)
  const [form] = Form.useForm();
  const [success,setSuccess] = useState(false)
  const [loading, setLoading] = useState(false);



  const validateConfirmPassword = (_, value) => {
    const { password } = form.getFieldsValue(['password']);
    if (value && value !== password) {
      return Promise.reject(new Error('Password and Confirm Password does not match.'));
    }
    return Promise.resolve();
  };


  const onFinish = (values) => {
    setLoading(true)


    console.log("Success:", values);
    const {password} = values;
    axios.post(`${BASE_URL}${RESET_PASSWORD_URL}/${token}`,
    {password:password}
    ).then((res)=>{
        setSuccess(true)
      setLoading(false)
      form.resetFields()


      
    }).catch((error)=>{
      setLoading(false)

        console.log("errrrr",error)
    api.error({ message:error?.response?.data?.message || "Something went wrong" });

    })
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="flex">
        {contextHolder}
      <div className="w-[45%] bg-[url('/public/images/backJp.jpg')] bg-cover bg-no-repeat h- flex gap-4 h-screen pt-28">
        <div className="text-white w-[70%] mx-auto">
          <h1 className="text-6xl mt-3 font-semibold pb-6 text-center">
            Welcome to our Reset Password Page
          </h1>
          <p className="font-semibold text-base text-[#CBD5E1] text-center">
          Reset your password to ensure your account is safeguarded.
          </p>
        </div>
      </div>

      <div className="w-[55%] bg-[#F8FAFC] mx-auto  flex gap-4 h-screen items-center">
        <div className="w-3/5 ml-[103px]  min-h-[511px] rounded-md flex flex-col">
          <h1 className="text-5xl font-bold mt-0 mb-1 pb-4">Reset Password</h1>
          <p className="text-[#52525B] text-lg pb-9">
            Enter your password and confirm password to reset your password.
          </p>
          <Form
            name="basic"
            layout="vertical"
            form={form}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Password"
              name="password"
              className="font-semibold mb-3"
              rules={[
          { required: true, message: 'Please input your password!' },
          { min: 6, message: 'Password must be at least 6 characters!' },
        ]}
            >
              <Input.Password className="w-full  py-2 rounded-2xl" />
            </Form.Item>
            <Form.Item
              label="Confirm Password"
              name="confirmPassword"
              className="font-semibold mb-3"
              dependencies={['password']}
              rules={[
                { required: true, message: 'Please confirm your password!' },
                { validator: validateConfirmPassword },
              ]}
            >
              <Input.Password className="w-full  py-2 rounded-2xl" />
            </Form.Item>

            <Form.Item className="pt-5 ">
              <SubmitButton form={form} spinner={loading}/>
            </Form.Item>
          </Form>
          {
            success&&(
                <div className=" border border-[#1B7C18] bg-[#D6F8DD] rounded-[5px]  text-[#1B7C18] py-2 px-4">
            You have successfully reset your password. <span onClick={()=> navigate('/login')} className="underline cursor-pointer font-semibold">Click here</span> for navigate to login page.
          </div>

            )
          }
          
        </div>
      </div>
    </div>
  );
};

export default ConfirmPassword;
