import React from 'react'
import Layout from '../../../../common/layout'
import {  useSelector } from 'react-redux';
import userImage from "../../../../common/assets/images/user-icon.svg";





const SingleUserProfile = () => {
  const userProfileData = useSelector((store) => store.singleUserProfile.data);
  console.log('userProfileData', userProfileData)


  
  
  

  

  return (
    <Layout>

      <div>
        <div class="flex justify-between my-3">
          <h1 class="capitalize font-semibold text-3xl">Profile</h1>
          
        </div>
        <div className=" bg-white mt-2 p-5 rounded ">
          <img
            src={userProfileData.image || userImage}
            alt="avatar"
            className="w-28 border border-dashed p-1"
          />
          <div className="flex gap-20 mb-5 mt-5">
            <h2 className="font-medium w-1/3">Name</h2>
            <p className="w-2/3">{userProfileData.name}</p>
          </div>
          <div className="flex gap-20 mb-5">
            <h2 className="font-medium  w-1/3">Email</h2>
            <p className="w-2/3">{userProfileData.email}</p>
          </div>
          <div className="flex gap-20 mb-5">
            <h2 className="font-medium  w-1/3">Age</h2>
            <p className="w-2/3">{userProfileData.ageRange}</p>
          </div>
          <div className="flex gap-20 mb-5">
            <h2 className="font-medium  w-1/3">Role</h2>
            <p className="w-2/3 capitalize">{userProfileData.role}</p>
          </div>
          <div className="flex gap-20 mb-5">
            <h2 className="font-medium  w-1/3">Mobile No.</h2>
            <p className="w-2/3 capitalize">{userProfileData.mobile || "-"}</p>
          </div>
          <div className="flex gap-20 mb-5">
            <h2 className="font-medium  w-1/3">Media Platforms</h2>
            <p className="w-2/3">{userProfileData.mediaName || "-"}</p>
          </div>
          <div className="flex gap-20 mb-5">
            <h2 className="font-medium  w-1/3">Associated Website</h2>
            <p className="w-2/3">{userProfileData.affiliateWithOtherSites}</p>
          </div>
          <div className="flex gap-20 mb-5">
            <h2 className="font-medium  w-1/3">Niche</h2>
            <p className="w-2/3">{userProfileData.niche || '-'}</p>
          </div>
          
          <div className="flex gap-20 mb-5">
            <h2 className="font-medium  w-1/3">Status</h2>
            <p className="w-2/3 capitalize">{userProfileData.status}</p>
          </div>
          <div className="flex gap-20 mb-5">
            <h2 className="font-medium  w-1/3">Linked Url</h2>
            <p className="w-2/3 capitalize">{userProfileData.linkedinUrl || "-"}</p>
          </div>
          <div className="flex gap-20 mb-5">
            <h2 className="font-medium  w-1/3">Facebook Url</h2>
            <p className="w-2/3 capitalize">{userProfileData.facebookUrl || "-"}</p>
          </div>
          <div className="flex gap-20 mb-5">
            <h2 className="font-medium  w-1/3">Twitter Url</h2>
            <p className="w-2/3 capitalize">{userProfileData.twitterUrl || "-"}</p>
          </div>
          <div className="flex gap-20 mb-5">
            <h2 className="font-medium  w-1/3">Instagram Url</h2>
            <p className="w-2/3 capitalize">{userProfileData.instagramUrl || "-"}</p>
          </div>
        </div>
       
      </div>
    </Layout>
  )
}

export default SingleUserProfile