import React, { useEffect } from "react";
import { Button, Popconfirm, Space, Table, notification } from "antd";
import { useState } from "react";
import {
  CaretDownOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Dropdown } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import Layout from "../../../../common/layout";
import { useDispatch, useSelector } from "react-redux";
import { deleteAdminInvoice, getAllAdminInvoices, reviewInvoice } from "../../../../redux/actions/adminInvoice";
import CopyToClipboard from "react-copy-to-clipboard";

const CommissionPayout = () => {
  const dispatch = useDispatch();
  const allInovices = useSelector((store) => store?.adminInvoice);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [id,setId]= useState();
  const [api, contextHolder] = notification.useNotification();
  const handleDeleteOffer = async () => {
    const response = await dispatch(deleteAdminInvoice(id));
    if (response?.data) {
      api.success({
        message: response?.message || "Success",
        duration: 3,
      });
    } else {
      api.error({
        message: response?.message || "Something went wrong",
        duration: 3,
      });
    }
  };
  const otherCopy = () => {
    api.success({
      message: "Copied",
      duration: 3,
    });
  };
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const items = [
    {
      label: "Approve",
      key: "approve",
      icon: <CheckOutlined />,
    },
    {
      label: "Reject",
      key: "reject",
      icon: <CloseOutlined />,
    },
    {
      label: (
        <Popconfirm
          description="Are you sure you want to delete this offer?"
          okText="Yes"
          cancelText="No"
          onConfirm={handleDeleteOffer}
          // onCancel={handleUserDeleteCancel}
          style={{ maxWidth: "100px" }}
        >
          <DeleteOutlined />
          &nbsp; Delete
        </Popconfirm>
      ),
      key: "delete",

      icon: "",
    },
  ];

  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };
  const handleInvoiceActions = async (e,id) => {
    const {key} = e;
    setId(id);
    if(key==='delete')
    {
    setId(id)
    }
    else{
      const reviewStatus = await dispatch(reviewInvoice(id,key));
      if(reviewStatus?.data)
      {
        api.success({
          message: reviewStatus?.message || "Success",
          duration: 5,
        });
      } else {
        api.error({
          message: reviewStatus?.message || "Something went wrong" ,
          duration: 5,
        });
      }
    }
  };
 
  const columns = [
    {
      title: "User",
      dataIndex: "username",
      key: "username",
      filteredValue: filteredInfo.username || null,
      onFilter: (value, record) => record.username.includes(value),
      sorter: (a, b) => a.username.length - b.username.length,
      sortOrder: sortedInfo.columnKey === "username" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      filteredValue: filteredInfo.email || null,
      onFilter: (value, record) => record.email.includes(value),
      sorter: (a, b) => a.email.length - b.email.length,
      sortOrder: sortedInfo.columnKey === "email" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",

      filteredValue: filteredInfo.currency || null,
      onFilter: (value, record) => record.currency.includes(value),
      sorter: (a, b) => a.currency.length - b.currency.length,
      sortOrder: sortedInfo.columnKey === "currency" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "Payout Method",
      dataIndex: "payoutMethod",
      key: "payoutMethod",
      filteredValue: filteredInfo.payoutMethod || null,
      onFilter: (value, record) => record.payoutMethod.includes(value),
      sorter: (a, b) => a.payoutMethod.length - b.payoutMethod.length,
      sortOrder:
        sortedInfo.columnKey === "payoutMethod" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "Payout Details",
      dataIndex: "paymentInfo",
      key: "paymentInfo",
      filteredValue: filteredInfo.paymentInfo || null,
      onFilter: (value, record) => record.paymentInfo.includes(value),
      sorter: (a, b) => a.paymentInfo.length - b.paymentInfo.length,
      sortOrder:
        sortedInfo.columnKey === "paymentInfo" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "Amount Due",
      dataIndex: "payoutAmount",
      key: "payoutAmount",
      filteredValue: filteredInfo.payoutAmount || null,
      onFilter: (value, record) => record.payoutAmount.includes(value),
      sorter: (a, b) => a.payoutAmount.length - b.payoutAmount.length,
      sortOrder:
        sortedInfo.columnKey === "payoutAmount" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "Payout Month",
      dataIndex: "payoutMonth",
      key: "payoutMonth",
      filteredValue: filteredInfo.payoutMonth || null,
      onFilter: (value, record) => record.payoutMonth.includes(value),
      sorter: (a, b) => a.payoutMonth.length - b.payoutMonth.length,
      sortOrder:
        sortedInfo.columnKey === "payoutMonth" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "Payout Status",
      dataIndex: "payoutStatus",
      key: "payoutStatus",
      filteredValue: filteredInfo.payoutStatus || null,
      onFilter: (value, record) => record.payoutStatus.includes(value),
      sorter: (a, b) => a.payoutStatus.length - b.payoutStatus.length,
      sortOrder:
        sortedInfo.columnKey === "payoutStatus" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      filteredValue: filteredInfo.action || null,
      onFilter: (value, record) => record.action.includes(value),
      sorter: (a, b) => a.action.length - b.action.length,
      sortOrder: sortedInfo.columnKey === "action" ? sortedInfo.order : null,
      ellipsis: true,
    },
  ];

  const data = allInovices?.data?.map((invoice) => {
    return {
      key: invoice._id,
      username: invoice.username,
      email: invoice.email,
      currency: invoice.currency,
      payoutMethod: invoice.paymentMethod,
      paymentInfo:  <div className="flex gap-1"> <p className="truncate"> {invoice.paymentInfo}</p><CopyToClipboard onCopy={otherCopy} text={invoice.paymentInfo}>
      <button>
        <CopyOutlined />
      </button>
    </CopyToClipboard></div>,
      payoutAmount:invoice.payoutAmount,
      payoutMonth:months[invoice.payoutMonth],
      payoutStatus:
      invoice.status === "reject" ? (
        <p className="font-Mulish font-semibold text-base text-[#FC0303]">
          Rejected
        </p>
      ) : invoice.status === "pending" ? (
        <p className="font-Mulish font-semibold text-base text-[#FFA500]">
          Pending
        </p>
      ) : invoice.status === "approve" ? (
        <p className="font-Mulish font-semibold text-base text-[#047B25]">
          Approved
        </p>
      ) : null,

      action: (
        <Dropdown
          menu={{
            items,
            onClick: (e) =>
              handleInvoiceActions(
                e,
                invoice._id,
                invoice.username,
                invoice.email,
                invoice.currency,
                invoice.paymentMethod,
                invoice.paymentInfo,
                invoice.status,
              ),
          }}
          className="rounded-2xl bg-[#E3E0E0] border-[#B6B5B5]"
        >
          <Button>
            <Space>
              Action
              <CaretDownOutlined />
            </Space>
          </Button>
        </Dropdown>
      ),
    }
  })
   
   
useEffect(()=>{
  (async()=>{
     await dispatch(getAllAdminInvoices());
  })()
  // eslint-disable-next-line
},[])
  return (
    <Layout>
      {contextHolder}
      <div>
        <h1 className="pt-5 font-semibold text-2xl font-Mulish mb-5">
          Commission and Payout
        </h1>

        <Table
        
          columns={columns}
          dataSource={data}
          onChange={handleChange}
          className="userTable"
          loading={allInovices?.loading}
        />
      </div>
    </Layout>
  );
};

export default CommissionPayout;
