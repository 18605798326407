import {
  DELETE_ADMIN_INVOICE,
  GET_ALL_INVOICE,
  INVOICE_FAILURE,
  INVOICE_REQUEST,
  INVOICE_REVIEW_SUCCESS,
} from "../constants";

const initialState = {
  data: [],
  loading: false,
  message: "",
  error: false,
};

export const getAllAdminInvoicesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case INVOICE_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        message: "",
      };
    case GET_ALL_INVOICE:
      return {
        ...state,
        data: payload?.paginatedData,
        loading: false,
        error: false,
        message: "",
      };
      case INVOICE_REVIEW_SUCCESS:
        return{
          ...state,
          data: state?.data?.map((invoice) =>
          invoice._id === payload._id ? payload : invoice,       
        ),
        loading: false,
        error: false,
        message: "",
        }
        case DELETE_ADMIN_INVOICE:
          return{
            ...state,
            data: state.data.filter((invoice) => invoice._id !== payload._id),
            loading: false,
            error: false,
            message: "",
          }
    case INVOICE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        message: payload?.message,
      };
    default:
      return state;
  }
};
