import React, { useEffect, useMemo } from "react";
import {
  Button,
  Form,
  Modal,
  Select,
  Upload,
  notification,
  Tabs,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useState } from "react";
import { Input } from "antd";
import Layout from "../../../../common/layout";
import addIcon from "../../../../common/assets/images/addImage.svg";
import { useDispatch } from "react-redux";
import {
  addOffer,
  getAllOffer,
  getAllRequestedOffers,
  searchOffer,
  searchRequestedOffer,
} from "../../../../redux/actions/offer";
import AllAdminOffers from "./AllOffersTable";
import RequestedOffers from "./RequestedOffersTable";
import { useWatch } from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
/** @jsxImportSource theme-ui */
const { Search } = Input;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });



const AdminOffer = (props) => {
  const [api, contextHolder] = notification.useNotification();
  const [submittable, setSubmittable] = useState(false);
  const [form] = Form.useForm();

  // Watch all values
  const values = useWatch([], form);

  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState(false);
  // eslint-disable-next-line
  const [previewOpen, setPreviewOpen] = useState(false);
  // eslint-disable-next-line
  const [previewImage, setPreviewImage] = useState("");
  // eslint-disable-next-line
  const [previewTitle, setPreviewTitle] = useState("");
  // eslint-disable-next-line
  const [fileList, setFileList] = useState([]);
  const [position] = useState(["left", "right"]);
  const [reRender, setReRender] = useState(false);
  const [offersTabItem, setOffersTabKey] = useState("allOffers");
  const [activeTab, setActiveTab] = useState(); // Initialize the active tab to '1'
  const currentUserRole = localStorage.getItem("profile");

  // eslint-disable-next-line
  const handleCancel = () => setPreviewOpen(false);
  // eslint-disable-next-line
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  // eslint-disable-next-line
  const handleChangeP = ({ fileList: newFileList }) => setFileList(newFileList);
  // eslint-disable-next-line

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  const onFinish = async ({image,name,landingPageUrl,description,previewUrl,category,originalPrice,discountedPrice,discountType,discountedValue}) => {
    let formData = new FormData();
    formData.append("name",name) 
    formData.append("landingPageUrl",landingPageUrl) 
    formData.append("description",description)
    formData.append("previewUrl",previewUrl) 
    formData.append("category",category) 
    formData.append("originalPrice",originalPrice) 
    formData.append("discountedPrice",discountedPrice) 
    formData.append("discountType",discountType) 
    formData.append("discountedValue",discountedValue) 
    
    if (image && image.length > 0) {
      // If a new image is selected, use it
      formData.append("image", image[0].originFileObj);
    }
    const addOfferResult = await dispatch(addOffer(formData));
    if (addOfferResult?.data) {
      form.resetFields();
      setModalOpen(false);
      api.success({
        message: addOfferResult?.message || "Success",
        duration: 5,
      });
      setActiveTab("allOffers");
    } else {
      api.error({
        message: addOfferResult?.message || "Something went wrong",
        duration: 5,
      });
    }
  };
  const handleCancelModal = () => {
    setModalOpen(false);
    form.resetFields();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onSearch = (e) => {
    if (offersTabItem === "allOffers") {
      dispatch(searchOffer(e.target.value));
    } else {
      dispatch(searchRequestedOffer(e.target.value));
    }
  };

  const handleDiscountType = (e) =>{
    const discountedPrice = form.getFieldValue("discountedPrice");
    const originalPrice = form.getFieldValue("originalPrice");
    if(e==='percentage'){
      const percentage = ((originalPrice-discountedPrice)/originalPrice)*100
      form.setFieldsValue({
        discountedValue: percentage.toFixed(2)
      })
      
    }else{
      form.setFieldsValue({
        discountedValue: originalPrice-discountedPrice
      })
    }
  }



  const numberValidation = (rule, value, callback) => {
    const numberPattern = /^[0-9]*$/;
    if (numberPattern.test(value)) {
      if (rule.field === "discountedPrice") {
        const originalPrice = form.getFieldValue("originalPrice");
        const discountedPrice = form.getFieldValue("discountedPrice");
        if (+discountedPrice > +originalPrice) {
          callback("Discounted Price should be less than Original Price");
        } else {
          callback();
        }
      }
      callback();
    } else {
      callback("Please enter only numbers");
    }
  };

  // eslint-disable-next-line
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const OperationsSlot = {
    left: (
      <div className="flex items-center pl-3 pr-5 gap-3">
        <img
          alt=""
          src={addIcon}
          className="mb-[-2px] cursor-pointer"
          onClick={() => setModalOpen(true)}
        />
        <button
          sx={{
            color: "accent",
          }}
          className="align-top pt-4 pb-3 text-xl font-Mulish font-semibold"
          onClick={() => setModalOpen(true)}
        >
          Add Offer
        </button>
      </div>
    ),
    right: (
      <Search
        placeholder="search..."
        enterButton
        className="user_Search "
        onChange={onSearch}
      />
    ),
  };
  const slot = useMemo(() => {
    if (position.length === 0) return null;
    return position.reduce(
      (acc, direction) => ({
        ...acc,
        [direction]: OperationsSlot[direction],
      }),
      {}
    );
    // eslint-disable-next-line
  }, [position]);

  const adminTabItems = [
    {
      key: "allOffers",
      label: <p className="font-Mulish font-semibold text-xl">All Offers</p>,
      children: <AllAdminOffers />,
    },
    {
      key: "requestedOffers",
      label: (
        <p className="font-Mulish font-semibold text-xl">Requested Offers</p>
      ),
      children: <RequestedOffers />,
    },
  ];
  const partnerShipViewTabItems = [
    {
      key: "allOffers",
      label: <p className="font-Mulish font-semibold text-xl py-4">All Offers</p>,
      children: <AllAdminOffers />,
    },
    // {
    //   key: "requestedOffers",
    //   label: (
    //     <p className="font-Mulish font-semibold text-xl">Requested Offers</p>
    //   ),
    //   children: <RequestedOffers />,
    // },
  ];
  const partnerTabItems = [
    {
      key: "requestedOffers",
      label: (
        <p className="font-Mulish font-semibold text-xl py-3">Requested Offers</p>
      ),
      children: <RequestedOffers />,
    },
  ];

  const handleTabChange = (key) => {
    setActiveTab(key);
    setOffersTabKey(key);
    setReRender(true);
  };
  useEffect(() => {
    dispatch(getAllOffer());
    dispatch(getAllRequestedOffers());
    setReRender(false);
    // eslint-disable-next-line
  }, [reRender]);

  useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        }
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <Layout>
      {contextHolder}
      <div {...props}>  
        <h1 className="pt-5 font-semibold text-2xl font-Mulish">
          Offer Management
        </h1>
        <div className="bg-[#fff] w-full my-4 justify-between shadow">
          <div className="">
            <div {...props} className="">
              <Tabs
                activeKey={activeTab}
                tabBarExtraContent={currentUserRole==='businessDirector' ? slot : ''}
                items={currentUserRole==='businessDirector' ? adminTabItems : currentUserRole==='businessPartner' ? partnerTabItems : partnerShipViewTabItems}
                className="adminOffers"
                onChange={(e) => handleTabChange(e)}
              />
            </div>
            <Modal
              title=""
              centered
              form={form}
              open={modalOpen}
              width={700}
              className="p-0 modal_parent_wrapper"
              onOk={() => setModalOpen(true)}
              onCancel={handleCancelModal}
              footer={[
                <Button
                  form="myForm"
                  key="submit"
                  htmlType="submit"
                  type="primary"
                  className="bg-[#002366] rounded-[50px] min-w-[130px] min-h-[45px]"
                  disabled={!submittable}
                >
                  Submit
                </Button>,
              ]}
            >
              <h2 className="uppercase text-center bg-[#002366] text-white mb-4 p-4 text-[25px] font-medium">
                Add Offer
              </h2>
              <Form
                name="basic"
                layout="vertical"
                form={form}
                id="myForm"
                style={{ maxWidth: 700 }}
                onFinish={onFinish}
                className="p-4"
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  name="image"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                >
                  <Upload
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChangeP}
                    className="text-center"
                    name="image"
                    beforeUpload={() => false}
                  >
                    {fileList.length >= 8 ? null : uploadButton}
                  </Upload>
                </Form.Item>
                <Modal
                  open={previewOpen}
                  title={previewTitle}
                  footer={null}
                  onCancel={handleCancel}
                >
                  <img
                    alt="example"
                    style={{ width: "100%" }}
                    src={previewImage}
                  />
                </Modal>
                <div className="flex gap-4 pt-5">
                  <Form.Item
                    label="Name"
                    name="name"
                    className="font-semibold mb-3 w-1/2"
                    rules={[
                      {
                        required: true,
                        message: "Please input your name!",
                      },
                    ]}
                  >
                    <Input className=" py-2 rounded-2xl w-full" />
                  </Form.Item>
                  {/* <Form.Item
                    label="Affiliate Link"
                    name="link"
                    className="font-semibold mb-3 w-1/2"
                    rules={[
                      {
                        required: true,
                        message: "Please input the link!",
                      },
                      { type: "url" },
                    ]}
                  >
                    <Input className=" py-2 rounded-2xl w-full" />
                  </Form.Item> */}
                  <Form.Item
                    label="Landing Page URL"
                    name="landingPageUrl"
                    className="font-semibold mb-3 w-1/2"
                    rules={[
                      {
                        required: true,
                        message: "Please input the Landing Page URL link!",
                      },
                      { type: "url" },
                    ]}
                  >
                    <Input className=" py-2 rounded-2xl w-full" />
                  </Form.Item>
                </div>
                <div>
                <Form.Item
                    label="Description"
                    name="description"
                    className="font-semibold mb-3 w-full"
                    rules={[
                      {
                        required: true,
                        message: "Please input description!",
                      },
                    ]}
                  >
                    <TextArea rows={3} />
                  </Form.Item>
                </div>
                <div className="flex gap-4 pt-5">
                  
                  <Form.Item
                    label="Preview URL"
                    name="previewUrl"
                    className="font-semibold mb-3 w-1/2"
                    rules={[
                      {
                        required: true,
                        message: "Please input the Preview link!",
                      },
                      { type: "url" },
                    ]}
                  >
                    <Input className=" py-2 rounded-2xl w-full" />
                  </Form.Item>
                  <Form.Item
                    label="Categories"
                    name="category"
                    className="font-semibold mb-3 w-1/2"
                  >
                    <Select>
                      <Select.Option value="automotive">Automotive</Select.Option>
                      <Select.Option value="baby">Baby</Select.Option>
                      <Select.Option value="booksAndMagazines">Books & Magazines</Select.Option>
                      <Select.Option value="businessOpportunity">Business Opportunity</Select.Option>
                      <Select.Option value="clothingAndShoes">Clothing & Shoes</Select.Option>
                      <Select.Option value="computersAndNetworking">Computers & Networking</Select.Option>
                      <Select.Option value="consumerProducts">Consumer Products</Select.Option>
                      <Select.Option value="dating">Dating</Select.Option>
                      <Select.Option value="education">Education</Select.Option>
                      <Select.Option value="electronics">Electronics</Select.Option>
                      <Select.Option value="email/zipSubmit">Email / Zip submit</Select.Option>
                      <Select.Option value="entertainment">Entertainment</Select.Option>
                      <Select.Option value="financeAndInsurance">Finance & Insurance</Select.Option>
                      <Select.Option value="gamesAndConsoles">Games & Consoles</Select.Option>
                      <Select.Option value="giftCardsAndCertificates">Gift Cards & Certificates</Select.Option>
                      <Select.Option value="healthAndBeauty">Health & Beauty</Select.Option>
                      <Select.Option value="home">Home</Select.Option>
                      <Select.Option value="incentive">Incentive</Select.Option>
                      <Select.Option value="internet">Internet</Select.Option>
                      <Select.Option value="jewelryAndAccessories">Jewelry & Accessories</Select.Option>
                      <Select.Option value="kitchenwareAndAppliances">Kitchenware & Appliances</Select.Option>
                      <Select.Option value="lawnAndGarden">Lawn & Garden</Select.Option>
                      <Select.Option value="mobile">Mobile</Select.Option>
                      <Select.Option value="musicAndVideo">Music & Video</Select.Option>
                      <Select.Option value="officeProducts">Office Products</Select.Option>
                      <Select.Option value="other">Other</Select.Option>
                      <Select.Option value="personalAndHome">Personal & Home</Select.Option>
                      <Select.Option value="petSuppliesAndProducts">Pet Supplies & Products</Select.Option>
                      <Select.Option value="realEstate">Real Estate</Select.Option>
                      <Select.Option value="socialMedia">Social Media</Select.Option>
                      <Select.Option value="SportsAndOutdoors">Sports & Outdoors</Select.Option>
                      <Select.Option value="toysAndHobbies">Toys & Hobbies</Select.Option>
                      <Select.Option value="travel">Travel</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
                
                {/* <div className="flex gap-4 pt-5">
                  <Form.Item
                    label="Start Date"
                    name="startDate"
                    className="font-semibold mb-3 w-1/2"
                    rules={[
                      {
                        required: true,
                        message: "Please select an start date",
                      },
                    ]}
                  >
                    <DatePicker className="w-full" />
                  </Form.Item>
                  <Form.Item
                    label="End Date"
                    name="endDate"
                    className="font-semibold mb-3 w-1/2"
                    rules={[
                      {
                        required: true,
                        message: "Please select an end date",
                      },
                    ]}
                  >
                    <DatePicker
                      className="w-full"
                      disabledDate={disabledEndDate}
                    />
                  </Form.Item>
                </div> */}
                <div className="flex gap-4 pt-5">
                  <Form.Item
                    label="Revenue"
                    name="originalPrice"
                    className="font-semibold mb-3 w-1/2"
                    rules={[
                      {
                        required: true,
                        message: "Please input a number!",
                      },
                      {
                        validator: numberValidation, // Use the custom validation function
                      },
                    ]}
                  >
                    <Input className=" py-2 rounded-2xl w-full" />
                  </Form.Item>
                  <Form.Item
                    label="Payout"
                    name="discountedPrice"
                    className="font-semibold mb-3 w-1/2"
                    rules={[
                      {
                        required: true,
                        message: "Please input a number!",
                      },
                      {
                        validator: numberValidation, // Use the custom validation function
                      },
                    ]}
                  >
                    <Input className=" py-2 rounded-2xl w-full" />
                  </Form.Item>
                </div>

                <div className="flex gap-4 pt-5">
                  <Form.Item
                    label="Payout Type"
                    name="discountType"
                    className="font-semibold mb-3 w-1/2"
                  >
                    <Select className="w-full" onChange={handleDiscountType}>
                      <Select.Option value="fixed">Fixed</Select.Option>
                      <Select.Option value="percentage">Percentage</Select.Option>
                    </Select>
                  </Form.Item>

                  

                  <Form.Item
                    label="Value"
                    name="discountedValue"
                    className="font-semibold mb-3 w-1/2"
                    
                  >
                    <Input className="py-2 rounded-2xl w-full" />
                  </Form.Item>
                </div>


                <div className="flex justify-center"></div>
              </Form>
            </Modal>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AdminOffer;
