import axios from "../../utils/interceptor/index";
import {
  GET_SINGLE_USER_SUCCESS,
  PROFILE_FAILURE,
  PROFILE_REQUEST,
  PROFILE_SUCCESS,
  PROFILE_UPDATE_SUCCESS,
  SINGLE_USER_FAILURE,
  SINGLE_USER_REQUEST,
} from "../constants";
import {
  GET_PROFILE_URL,
  SINGLE_USER_PROFILE_URL,
  UPDATE_PROFILE,
} from "../../utils/api/urls";

export const profileData = () => {
  return async (dispatch) => {
    dispatch({ type: PROFILE_REQUEST });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Add any other headers if needed
      };
      const response = await axios.get(GET_PROFILE_URL, { headers });
      if (response?.data) {
        dispatch({
          type: PROFILE_SUCCESS,
          payload: response?.data?.data || {},
        });
        return response?.data?.data;
      }
    } catch (error) {
      dispatch({ type: PROFILE_FAILURE, payload: { error } });
    }
  };
};

export const updateProfile = (payload) => {
  return async (dispatch) => {
    dispatch({ type: PROFILE_REQUEST });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Add any other headers if needed
      };
      const result = await axios.put(UPDATE_PROFILE, payload, { headers });
      await dispatch({
        type: PROFILE_UPDATE_SUCCESS,
        payload: result?.data?.data || {},
      });
      return result?.data;
    } catch (error) {
      dispatch({type:PROFILE_FAILURE,payload:error?.response?.data?.message})
      return error?.response?.data?.message;
    }
  };
};

export const singleUserProfileData = (id) => {
  return async (dispatch) => {
    dispatch({ type: SINGLE_USER_REQUEST });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Add any other headers if needed
      };
      const response = await axios.get(`${SINGLE_USER_PROFILE_URL}/${id}`, { headers });
      if (response?.data) {
        dispatch({
          type: GET_SINGLE_USER_SUCCESS,
          payload: response?.data?.data || {},
        });
        return response?.data?.data;
      }
    } catch (error) {
      dispatch({ type: SINGLE_USER_FAILURE, payload: { error } });
    }
  };
};


