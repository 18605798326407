import axios from "axios";
import { useNavigate } from "react-router-dom";



axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {

    if (error.response.data.statusCode === 401) {
        localStorage.removeItem("accessToken")
        localStorage.removeItem("profile")
    }
    // Do something with response error
    return Promise.reject(error);
  }
);


// axiosConfig.js
// import axios from 'axios';
// import { useHistory } from 'react-router-dom';

// // Create an instance of Axios with custom configuration
// const axiosInstance = axios.create({
//   baseURL: '/api', // Your API base URL
// });

// // Add a response interceptor to handle 401 responses
// axiosInstance.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     if (error.response && error.response.status === 401) {
//       // Redirect to the login page
//       const history = useHistory();
//       history.push('/login'); // Replace with your login route
//     }
//     return Promise.reject(error);
//   }
// );

export default axios;

