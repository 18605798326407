import {
    GET_SINGLE_USER_SUCCESS,
    SINGLE_USER_FAILURE,
    SINGLE_USER_REQUEST,
  } from "../constants";
  
  const initialState = {
    data: {},
    loading: false,
    message: "",
    error: false,
  };
  export function singleProfileReducer(singleProfileState = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case SINGLE_USER_REQUEST:
        return {
          ...singleProfileState,
          loading: true,
          message: "",
          error: false,
        };
      case SINGLE_USER_FAILURE:
        return {
          ...singleProfileState,
          loading: false,
          message: payload,
          error: true,
        };
  
      case GET_SINGLE_USER_SUCCESS:
        return {
          ...singleProfileState,
          loading: false,
          message: "",
          error: false,
          data: payload,
        };
      
      
      default:
        return singleProfileState;
    }
  }
  
  