import React, { useEffect, useState } from "react";
import Layout from "../../../common/layout";
import affiliatePayout from "../../../common/assets/images/affiliatePayout.svg";
import { Avatar, DatePicker, List, Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getAllNews } from "../../../redux/actions/adminNews";
import emptyImage from '../../../common/assets/images/emptyImage.jpg'
import { useNavigate } from "react-router-dom";
import AllPromotedOffer from "../affiliate/Offer/AllPromotedOffer";
import { getAllAffiliateDashboardReports } from "../../../redux/actions/affiliateDashboard";
import AffiliateLineChart from "../chart/AffiliateLineChart";
/** @jsxImportSource theme-ui */
const { RangePicker } = DatePicker;


const AffiliateDashboard = (props) => {
  const [affiliateReports, setAffiliateReports] = useState({})
  console.log('affiliateReports', affiliateReports)
  const allNews = useSelector((store) => store.news.data);
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [filterReports, setFilterReports] = useState("");
  const items = [
    {
      key: "1",
      label: "All Categories",
      children: <AllPromotedOffer/>,
    },
    // {
    //   key: "2",
    //   label: "Forex",
    //   children: "Content of Tab Pane 2",
    // },
    // {
    //   key: "3",
    //   label: "FCA Regulated Brokers",
    //   children: "Content of Tab Pane 3",
    // },
    // {
    //   key: "4",
    //   label: "Forex & Crypto Weekly Payout",
    //   children: "Content of Tab Pane 4",
    // },
  ];


  useEffect(()=>{
    (async()=>{
      dispatch(getAllNews(3,1));
      const response = await dispatch(getAllAffiliateDashboardReports(filterReports))
      setAffiliateReports(response)

    })()
    // eslint-disable-next-line
  },[filterReports])

  return (
    <Layout>
      <div {...props}>
        <div className="flex justify-between items-end">
        <h1 className="capitalize font-semibold text-3xl mt-3">dashboard</h1>
        <RangePicker onChange={(D, FD) => setFilterReports(FD)} className="h-8 "/>


        </div>

        <hr className="bg-[#333333] opacity-40 h-px my-3 border-0" />
        <div>
          

          <div className="flex justify-between mt-5 gap-4 reduce_font_size">
            

            <div className="gradadminFee rounded-lg w-1/4 px-7 pt-6 pb-3 min-h-[130px]">
              <div className="flex mb-2 items-center">
                <div className="border-dashed border-[2px] rounded-full max-w-[67px] max-h-[67px] min-w-[67px] min-h-[67px] p-2 flex items-center justify-center">
                  <img
                    alt=""
                    src={affiliatePayout}
                    className=" max-w-[48px] max-h-[48px]"
                  />
                </div>

                <div className="pl-4 text-white">
                  <h6 className="font-medium text-lg capitalize">revenue</h6>
                  {/* <p className="font-extralight text-lg">Last 14 days</p> */}
                  <p className="font-medium text-lg">{`$${affiliateReports?.totalRevenue || 0}`}</p>
                </div>
              </div>
              <div className="flex items-center gap-1">
                <hr className="w-11/12" />
                {/* <p className="text-white font-Muli font-light text-sm">0</p> */}
              </div>
            </div>  
          </div>
        </div>

        <div className="flex gap-4">
          <div className="w-[70%]">
            <div>
              <div className="bg-white mt-6 p-2">

          <AffiliateLineChart reports={affiliateReports?.monthBasedPayouts}/>
              </div>
              <div className=" bg-white mt-6 mb-4 rounded p-5 h-min">
                <h3 className="pb-2 font-Mulish font-semibold text-2xl">
                  Offers to Promote
                </h3>

                <Tabs
                  defaultActiveKey="1"
                  items={items}
                  className="offerTabs"
                />
              </div>

              <button className="float-right bg-[#002366] text-white px-5 py-2 rounded-full " onClick={()=> navigate('/affiliate/offers')}>
                View More
              </button>
              <div className="clear-both"></div>
            </div>

            {/* <div className="my-12 rounded-sm">
              <div className="flex justify-between">
                <h2 className="font-Mulish font-semibold text-2xl">
                  Your Offer
                </h2>
                <Tabs
                  defaultActiveKey="1"
                  items={days}
                  className="removeArrow"
                />
              </div>
              <div className="flex justify-between p-5">
                <h3 className="font-Mulish font-semibold text-base">offer</h3>

                <div>
                  <Dropdown 
                    menu={{ items: dropdownItems, onClick }}
                    className="pr-12"
                  >
                    <span onClick={(e) => e.preventDefault()}>
                      <Space>
                        <span className="font-Mulish font-semibold text-base">
                          payout
                        </span>
                        <CaretDownOutlined />
                      </Space>
                    </span>
                  </Dropdown>
                  <button>change</button>
                </div>
              </div>
              <div>
                <hr className="bg-[#333333] opacity-40 my-4 border-0 h-px" />
                <p className="float-right font-Mulish font-semibold text-lg">
                  Aug 08-21,2023
                </p>
                <div className="clear-both"></div>
              </div>
            </div> */}

            
          </div>

          <div className="w-[30%]">
            <div className="bg-white mt-6 mb-4 rounded p-5 ">
              <h3 className=" pb-5 font-Mulish font-semibold text-2xl">News</h3>
              <List
                itemLayout="horizontal"
                dataSource={allNews}
                renderItem={(item, index) => (
                  <List.Item>
                    <List.Item.Meta
                      className="p-2"
                      avatar={
                        <Avatar
                          src={item.image || emptyImage}
                        />
                      }
                      title={<a href="https://ant.design">{item.title}</a>}
                      description={item.description}
                    />
                  </List.Item> 
                )}
              />
            </div>
            <button className="float-right bg-[#002366] text-white px-5 py-2 rounded-full " onClick={()=> navigate('/affiliate/news')}>
              View More
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AffiliateDashboard;
