import React, { useEffect, useState } from "react";
import Layout from "../../../common/layout";
import { Button, Form, Input, Modal, Upload, notification } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import userImage from "../../../common/assets/images/user-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { profileData, updateProfile } from "../../../redux/actions/profile";

const convertImageUrlToObject = async (imageUrl) => {
  const response = await fetch(imageUrl);
  const blob = await response.blob();

  // Create a File object with the Blob data
  const file = new File([blob], "image.jpg", { type: "image/jpeg" });
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      const fileObject = {
        uid: Date.now().toString(), // Generate a unique ID
        size: img.size, // You can set the actual size here if available
        name: imageUrl.split("/").pop(), // Extract the file name from the URL
        url: imageUrl,
        type: "image/jpeg", // Set the appropriate content type
        lastModified: Date.now(), // Set the current timestamp as last modified
        lastModifiedDate: new Date(),
        status: "done", // You can set the initial status here
        percent: 100,
        thumbUrl: imageUrl, // You can set a thumbnail URL if needed
        originFileObj: file, // You can set the original file object here if available
        response: null,
        error: null,
        linkProps: null,
        xhr: null,
        preview: imageUrl, // Set the preview URL
      };

      resolve(fileObject);
      return fileObject;
    };

    img.onerror = (error) => {
      reject(error);
    };
  });
};
const Profile = () => {
  const userProfileData = useSelector((store) => store.profileData.data);
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [modalOpen, setModalOpen] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const handleChangeP = ({ fileList: newFileList }) => setFileList(newFileList);
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const validateUserName = (rule, value, callback) => {
    const regex = /^[A-Za-z_ ]+$/; // Allow only letters (both uppercase and lowercase)
    if (!regex.test(value)) {
      callback("Only text characters are allowed!");
    } else {
      callback();
    }
  };
  const validateEmail = (rule, value, callback) => {
    // You can use a regular expression to validate the email format
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (!emailRegex.test(value)) {
      callback("Invalid email format");
    } else {
      callback();
    }
  };
  const handleCancelModal = () => {
    setModalOpen(false);
    form.resetFields();
  };
  const handleEditProfile = () => {
    setModalOpen(true);
    if (userProfileData.image) {
      convertImageUrlToObject(userProfileData.image)
        .then((result) => {
          form.setFieldsValue({
            key: userProfileData._id,
            name: userProfileData.name,
            image: [result],
            email: userProfileData.email,
            password: userProfileData.password,
            ageRange: userProfileData.ageRange,
            mediaName: userProfileData.mediaName,
            affiliateWithOtherSites: userProfileData.affiliateWithOtherSites,
          });
        })
        .catch((error) => {
          console.error("Error converting image:", error);
        });
    } else {
      form.setFieldsValue({
        key: userProfileData._id,
        name: userProfileData.name,
        email: userProfileData.email,
        password: userProfileData.password,
        ageRange: userProfileData.ageRange,
        mediaName: userProfileData.mediaName,
        affiliateWithOtherSites: userProfileData.affiliateWithOtherSites,
      });
    }
  };
  const handleRemoveProfileImage = (e) => {
    if (e.status === "done") {
      form.setFieldsValue({
        key: userProfileData._id,
        name: userProfileData.name,
        // image: [result],
        email: userProfileData.email,
        password: userProfileData.password,
        ageRange: userProfileData.ageRange,
        mediaName: userProfileData.mediaName,
        affiliateWithOtherSites: userProfileData.affiliateWithOtherSites,
      });
    }
  };
  
  const handleProfileUpdate = async (values) => {
    const { image, name, mediaName, email, ageRange, affiliateWithOtherSites } =
      values;
    const formData = new FormData();
    formData.append("name", name);
    formData.append("mediaName", mediaName);
    formData.append("email", email);
    formData.append("ageRange", ageRange);
    formData.append("affiliateWithOtherSites", affiliateWithOtherSites);

    if (image && image.length > 0) {
      // If a new image is selected, use it
      formData.append("image", image[0].originFileObj);
    } else {
      formData.append("image", "");
    }
    const response = await dispatch(updateProfile(formData));
    if (response?.data) {
      setModalOpen(false);
      api.success({
        message: response?.message,
        duration: 3,
      });
    } else {
      api.error({
        message: response,
        duration: 3,
      });
    }
  };
  useEffect(() => {
    (async () => {
      await dispatch(profileData());
    })();
    // eslint-disable-next-line
  }, []);
  return (
    <Layout>
      {contextHolder}
      <div>
        <div class="flex justify-between my-3">
          <h1 class="capitalize font-semibold text-3xl">Profile</h1>
          <button
            class="bg-[#002366] text-white py-2 px-4 rounded-[7px]"
            onClick={() => handleEditProfile()}
          >
            Edit Profile
          </button>
        </div>
        <div className=" bg-white mt-2 p-5 rounded ">
          <img
            src={userProfileData.image || userImage}
            alt="avatar"
            className="w-28 border border-dashed p-1"
          />
          <div className="flex gap-20 mb-5 mt-5">
            <h2 className="font-medium w-1/3">Name</h2>
            <p className="w-2/3">{userProfileData.name}</p>
          </div>
          <div className="flex gap-20 mb-5">
            <h2 className="font-medium  w-1/3">Email</h2>
            <p className="w-2/3">{userProfileData.email}</p>
          </div>
          <div className="flex gap-20 mb-5">
            <h2 className="font-medium  w-1/3">Age</h2>
            <p className="w-2/3">{userProfileData.ageRange}</p>
          </div>
          <div className="flex gap-20 mb-5">
            <h2 className="font-medium  w-1/3">Media Platforms</h2>
            <p className="w-2/3">{userProfileData.mediaName}</p>
          </div>
          <div className="flex gap-20 mb-5">
            <h2 className="font-medium  w-1/3">Associated Website</h2>
            <p className="w-2/3">{userProfileData.affiliateWithOtherSites}</p>
          </div>
        </div>
        <Modal
          title=""
          centered
          form={form}
          open={modalOpen}
          className="p-0 modal_parent_wrapper"
          onOk={() => setModalOpen(false)}
          onCancel={handleCancelModal}
          footer={[
            <Button
              form="myForm"
              key="submit"
              htmlType="submit"
              type="primary"
              className="bg-[#002366] rounded-[50px] min-w-[130px] min-h-[45px]"
              //   disabled={!submittable}
            >
              Update
            </Button>,
          ]}
        >
          <h2 className="uppercase text-center bg-[#002366] text-white mb-4 p-4 text-[25px] font-medium">
            Edit Profile
          </h2>
          <Form
            name="basic"
            layout="vertical"
            id="myForm"
            form={form}
            style={{ maxWidth: 600 }}
            className="p-4"
            initialValues={{ remember: true }}
            onFinish={handleProfileUpdate}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              name="image"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload
                // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChangeP}
                onRemove={handleRemoveProfileImage}
                className="text-center"
                name="image"
                maxCount={1}
                beforeUpload={() => false}
              >
                {fileList.length >= 8 ? null : uploadButton}
              </Upload>
            </Form.Item>
            <Modal
              open={previewOpen}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}
            >
              <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>

            <div className="flex gap-4 pt-5">
              <Form.Item
                label="Name"
                name="name"
                className="font-semibold mb-3 w-1/2"
                rules={[
                  {
                    required: true,
                    message: "Please input your name!",
                  },
                  {
                    validator: validateUserName,
                  },
                ]}
              >
                <Input className=" py-2 rounded-2xl w-full" />
              </Form.Item>
              <Form.Item
                label="Email Address"
                name="email"
                className="font-semibold mb-3 w-1/2"
                rules={[
                  {
                    required: true,
                    message: "Please enter your email address",
                  },
                  { validator: validateEmail }, // Custom validation rule
                ]}
              >
                <Input className="py-2 rounded-2xl w-full" />
              </Form.Item>
            </div>
            <div className="flex gap-4">
              <Form.Item
                label="Age"
                name="ageRange"
                className="font-semibold mb-3 w-full"
                rules={[
                  {
                    required: true,
                    message: "Please input your age!",
                  },
                ]}
              >
                <Input className=" py-2 rounded-2xl w-full" />
              </Form.Item>
            </div>
            <div className="flex gap-4">
              <Form.Item
                label="Media Platforms"
                name="mediaName"
                className="font-semibold mb-3 w-1/2"
                rules={[
                  { required: true, message: "Please enter Media Platforms" },
                ]}
              >
                <Input className="py-2 rounded-2xl w-full" />
              </Form.Item>
              <Form.Item
                label="Associated Website"
                name="affiliateWithOtherSites"
                className="font-semibold mb-3 w-1/2"
                rules={[
                  {
                    required: true,
                    message: "Please input your associated Websites!",
                  },
                ]}
              >
                <Input className=" py-2 rounded-2xl w-full" />
              </Form.Item>
            </div>
          </Form>
        </Modal>
      </div>
    </Layout>
  );
};

export default Profile;
