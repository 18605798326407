import React, { useEffect } from "react";
import { MenuOutlined, MailOutlined, BellOutlined } from "@ant-design/icons";
import userProfile from "../../common/assets/images/user-icon.svg";
import { Dropdown } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { profileData } from "../../redux/actions/profile";
/** @jsxImportSource theme-ui */

const TopNavBar = () => {
  const { name ,image} = useSelector((store) => store.profileData.data);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("profile");
    
    navigate("/login");
  };

  useEffect(() => {
    dispatch(profileData());

    // eslint-disable-next-line
  }, []);

  const items = [
    {
      key: "1",
      label: <button onClick={()=>navigate("/dashboard/profile")}>Profile</button>,
    },
    {
      key: "2",
      label: <button onClick={handleLogout}>logout</button>,
    },
  ];

  return (
    <>
      <div
        
        className=" h-20 flex justify-between"
        sx={{ background: "whitebg" }}
      >
        <MenuOutlined className="p-[30px] w-7 h-5 invisible" />
        <div className="flex items-center gap-4 pr-5 ">
          <Dropdown menu={{ items }} placement="bottom" arrow>
            <img src={image || userProfile} className="w-8 h-8 border rounded-full"  alt="" />
          </Dropdown>

          <p className="capitalize">{name || "demo"}</p>
          <MailOutlined />
          <BellOutlined />
        </div>
      </div>
    </>
  );
};

export default TopNavBar;
