import React from 'react'
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    BarElement, 
  } from 'chart.js'
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    BarElement
  );
  

const BarChart = (props) => {
    const data = {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December"
        ],
        datasets: [
          {
            label: 'Total Revenue',
            data: props?.reports?.totalRevenue,
            backgroundColor: '#2F904A', // Bar color
            borderColor: '#2F904A', // Border color
            borderWidth: 1, // Border width

          },
          {
            label: 'Total Affiliates',
            data: props?.reports?.totalAffiliates,
            backgroundColor: '#DCA51F', // Bar color
            borderColor: '#DCA51F', // Border color
            borderWidth: 1, // Border width
          },
          
          {
            label: 'Total Admin Fee',
            data: props?.reports?.totalAdminFee,
            backgroundColor: '#DE2121', // Bar color
            borderColor: '#DE2121', // Border color
            borderWidth: 1, // Border width
          },
        ],
      };



  return (
    
        <div>
      <Bar data={data} />
    </div>
  )
}

export default BarChart