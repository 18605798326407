import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../../../common/layout";
import addIcon from "../../../../common/assets/images/addImage.svg";

import {
  Button,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
  Space,
  Table,
  Tooltip,
  notification,
} from "antd";
import { useWatch } from "antd/es/form/Form";
import { useDispatch, useSelector } from "react-redux";
import Search from "antd/es/transfer/search";
import {
  CaretDownOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { getAllOffer } from "../../../../redux/actions/offer";
import {
  addReport,
  deleteReports,
  getAllEmail,
  getAllReports,
  searchReport,
  updateReports,
} from "../../../../redux/actions/reportsAndAnalytics";
import ColumnGroup from "antd/es/table/ColumnGroup";
import Column from "antd/es/table/Column";
const { RangePicker } = DatePicker;

const ReportsAndAnalytics = () => {
  const dispatch = useDispatch();
  const allReports = useSelector((store) => store?.reports);
  const allOffers = useSelector((store) => store?.offers?.data);

  const [form] = Form.useForm();
  const [updateForm] = Form.useForm();

  const values = useWatch([], form);

  const [api, contextHolder] = notification.useNotification();
  const [submittable, setSubmittable] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [id, setId] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [currPage, setCurrPage] = useState(1);
  const [itemCount, setItemCount] = useState(allReports?.data?.length);
  const [allEmail,setAllEmail] = useState([])
  const [filterReports, setFilterReports] = useState("");
  const currentUserRole = localStorage.getItem("profile");
  const onSearch = (value) => dispatch(searchReport(value.target.value));

  const handleDeleteUser = async () => {
    const deleteUserResult = await dispatch(deleteReports(id));
    if (deleteUserResult?.data) {
      api.success({
        message: deleteUserResult?.message || "Success",
        duration: 5,
      });
    } else {
      api.error({
        message: deleteUserResult?.message || "Something went wrong",
        duration: 5,
      });
    }
  };
  const handleCancelModal = () => {
    setModalOpen(false);
    form.resetFields();
  };

  const items = [
    {
      label: "Edit",
      key: "edit",

      icon: <EditOutlined />,
    },
    {
      label: (
        <Popconfirm
          description="Are you sure you want to delete this user?"
          okText="Yes"
          cancelText="No"
          onConfirm={handleDeleteUser}
        >
          <DeleteOutlined />
          &nbsp; Delete
        </Popconfirm>
      ),
      key: "delete",

      icon: "",
    },
  ];
  const onFinish = async (values) => {
    const {
      name,
      email,
      offerId,
      gross,
      unique,
      invalid,
      approvedqty,
      approvedCr,
      approvedPayout,
      pendingQty,
      pendingCr,
      rejectedQty,
      rejectedCr,
      calculationsAr,
    } = values;
    const clicks = {
      gross: gross,
      unique: unique,
      invalid: invalid,
    };
    const approvedConversions = {
      qty: approvedqty,
      cr: approvedCr,
      payout: approvedPayout,
    };
    const pendingConversions = {
      qty: pendingQty,
      cr: pendingCr,
    };
    const rejectedConversions = {
      qty: rejectedQty,
      cr: rejectedCr,
    };
    const calculations = {
      ar: calculationsAr,
    };

    const result = await dispatch(
      addReport({
        name: name,
        email: email,
        offerId: offerId,
        clicks,
        approvedConversions,
        pendingConversions,
        rejectedConversions,
        calculations,
      })
    );
    if (result?.data) {
      setModalOpen(false);
      api.success({
        message: result?.message || "Success",
        duration: 5,
      });
      form.resetFields();
    } else {
      api.error({
        message: result?.error || "Something went wrong",
        duration: 5,
      });
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const validateUserName = (rule, value, callback) => {
    const regex = /^[A-Za-z_ ]+$/; // Allow only letters (both uppercase and lowercase)
    if (!regex.test(value)) {
      callback("Only text characters are allowed!");
    } else {
      callback();
    }
  };

  const handleMenuClick = async (
    e,
    id,
    name,
    email,
    offerName,
    clicksGross,
    clicksUnique,
    clicksInvalid,
    approvedConversionsQty,
    approvedConversionsCr,
    approvedConversionsPayout,
    pendingConversionsQty,
    pendingConversionsCr,
    rejectedConversionsQty,
    rejectedConversionsCr,
    calculationsAr
  ) => {
    const { key } = e;
    setId(id);
    if (key === "edit") {
      setUpdateModalOpen(true);
      updateForm.setFieldsValue({
        name: name,
        email: email,
        offerId: offerName,
        gross: clicksGross,
        unique: clicksUnique,
        invalid: clicksInvalid,
        approvedqty: approvedConversionsQty,
        approvedCr: approvedConversionsCr,
        approvedPayout: approvedConversionsPayout,
        pendingQty: pendingConversionsQty,
        pendingCr: pendingConversionsCr,
        rejectedQty: rejectedConversionsQty,
        rejectedCr: rejectedConversionsCr,
        calculationsAr: calculationsAr,
      });
    } else {
      return;
    }
  };

  const onUpdateFinish = async (values) => {
    const {
      name,
      email,
      offerId,
      gross,
      unique,
      invalid,
      approvedqty,
      approvedCr,
      approvedPayout,
      pendingQty,
      pendingCr,
      rejectedQty,
      rejectedCr,
      calculationsAr,
    } = values;
    const clicks = {
      gross: gross,
      unique: unique,
      invalid: invalid,
    };
    const approvedConversions = {
      qty: approvedqty,
      cr: approvedCr,
      payout: approvedPayout,
    };
    const pendingConversions = {
      qty: pendingQty,
      cr: pendingCr,
    };
    const rejectedConversions = {
      qty: rejectedQty,
      cr: rejectedCr,
    };
    const calculations = {
      ar: calculationsAr,
    };
    const updateUserResult = await dispatch(
      updateReports(id, {
        name: name,
        email: email,
        offerId: offerId,
        clicks,
        approvedConversions,
        pendingConversions,
        rejectedConversions,
        calculations,
      })
    );
    if (updateUserResult?.data) {
      setUpdateModalOpen(false);
      updateForm.resetFields();
      api.success({
        message: updateUserResult?.message || "Success",
        duration: 3,
      });
    } else {
      api.error({
        message: updateUserResult?.error || "Something went wrong",
        duration: 3,
      });
      setUpdateModalOpen(false);
    }
  };
  const onUpdateFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const handleChange = (pagination, filters, sorter) => {
    setCurrPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const newData =
    allReports?.data?.length > 0
      ? allReports?.data?.map((item) => {
          return {
            key: item._id,
            name: item.name,
            email: item.email,
            offerId: item?.offerId?.name,
            gross: item.clicks.gross,
            unique: item.clicks.unique,
            invalid: item.clicks.invalid,
            approvedqty: item.approvedConversions.qty,
            approvedCr: `${item.approvedConversions.cr}%`,
            approvedPayout: item.approvedConversions.payout,
            pendingQty: item.pendingConversions.qty,
            pendingCr: `${item.pendingConversions.cr}%`,
            rejectedQty: item.rejectedConversions.qty,
            rejectedCr: `${item.rejectedConversions.cr}%`,
            calculationsAr: `${item.calculations.ar}%`,
            action: (
              <Dropdown
                menu={{
                  items,
                  onClick: (e) =>
                    handleMenuClick(
                      e,
                      item._id,
                      item.name,
                      item.email,
                      item.offerId._id,
                      item.clicks.gross,
                      item.clicks.unique,
                      item.clicks.invalid,
                      item.approvedConversions.qty,
                      item.approvedConversions.cr,
                      item.approvedConversions.payout,
                      item.pendingConversions.qty,
                      item.pendingConversions.cr,
                      item.rejectedConversions.qty,
                      item.rejectedConversions.cr,
                      item.calculations.ar
                    ),
                }}
                className="rounded-2xl bg-[#E3E0E0] border-[#B6B5B5]"
              >
                <Button>
                  <Space>
                    Action
                    <CaretDownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            ),
          };
        })
      : [];

  const paginationConfig = {
    pageSize: pageSize, // Number of items to display per page
    total: itemCount || 0, // Total number of items in the data source
    showSizeChanger: true, // Show the "Show X items per page" dropdown
    showQuickJumper: true, // Show the quick jumper input field
    pageSizeOptions: ["10", "20", "30"], // Options for items per page dropdown
  };

  useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        }
      );
    // eslint-disable-next-line
  }, [values]);

  useEffect(() => {
    (async () => {
      const response = await dispatch(
        getAllReports(pageSize, currPage, filterReports)
      );
      await dispatch(getAllOffer());
      setItemCount(response?.itemCount);
    })();
    // eslint-disable-next-line
  }, [pageSize, currPage, filterReports]);

  useEffect(()=>{
    (async ()=> {
      const response = await dispatch(getAllEmail())
      setAllEmail(response)
    })()

  },[])



  return (
    <Layout>
      <div>
        {contextHolder}
        <h1 className="pt-5 font-semibold text-2xl font-Mulish dark:text-white">
          Reports & Analytics
        </h1>

        <div className="bg-[#fff] w-full flex my-4 justify-between shadow dark:bg-black">
          <div className="w-[70%]">
            <div className="flex">
              <button
                className="py-4 pl-3 border-r-2 border-[#ACA8A8] pr-5 text-xl font-Mulish font-semibold dark:text-white"
                onClick={() => setPageSize(allReports?.data?.length)}
              >
                All Reports
                
              </button>
    {
      currentUserRole==='businessDirector' ?  
      <div className="flex items-center pl-5 gap-3">
      <img
        alt=""
        src={addIcon}
        className="mb-[-2px] cursor-pointer dark:brightness-[1] dark:invert-[1]"
        onClick={() => setModalOpen(true)}
      />
      <button
        className="align-top pt-4 pb-3 text-xl font-Mulish font-semibold dark:text-white"
        onClick={() => setModalOpen(true)}
      >
        Add Report
      </button>
      <div className="w-1/2">
        <RangePicker onChange={(D, FD) => setFilterReports(FD)} />
      </div>
    </div> : '' 
    }
             
            </div>
            <Modal
              title=""
              centered
              form={form}
              open={modalOpen}
              width={700}
              className="p-0 modal_parent_wrapper"
              onOk={() => setModalOpen(false)}
              onCancel={handleCancelModal}
              footer={[
                <Button
                  form="myForm"
                  key="submit"
                  htmlType="submit"
                  type="primary"
                  className="bg-[#002366] rounded-[50px] min-w-[130px] min-h-[45px]"
                  disabled={!submittable}
                >
                  Submit
                </Button>,
              ]}
            >
              <h2 className="uppercase text-center bg-[#002366] text-white mb-4 p-4 text-[25px] font-medium">
                Add Report
              </h2>
              <Form
                name="basic"
                layout="vertical"
                id="myForm"
                form={form}
                style={{ maxWidth: 700 }}
                className="p-4"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <div className="flex gap-4 pt-5">
                  <Form.Item
                    label="Name"
                    name="name"
                    className="font-semibold mb-3 w-1/2"
                    rules={[
                      {
                        required: true,
                        message: "Please input your name!",
                      },
                      {
                        validator: validateUserName,
                      },
                    ]}
                  >
                    <Input className=" py-2 rounded-2xl w-full font-normal" />
                  </Form.Item>
                  <Form.Item
                    label="Email"
                    name="email"
                    className="font-semibold mb-3 w-1/2"
                    rules={[
                      {
                        required: true,
                        message: "Please select the email",
                      },
                    ]}
                  >
                    <Select className="w-full" showSearch>
                  {
                    allEmail?.map((item,index)=>{
                      return <Select.Option value={item} key={index}>{ item || "loading..."}</Select.Option>
                    })
                  }
                 
                </Select>
                  </Form.Item>
                </div>

                <div className="flex gap-4">
                  <Form.Item
                    label="Choose Offer"
                    className="font-semibold mb-3 w-full"
                    name="offerId"
                  >
                    <Select className="w-full font-normal">
                      {allOffers?.map((offer) => {
                        return (
                          <Select.Option value={offer._id}>
                            {offer.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    label="Clicks"
                    name="clicks"
                    className="font-semibold mb-3 w-full"
                  >
                    <div className="flex gap-2">
                      <Form.Item
                        name="gross"
                        rules={[{ required: true }]}
                        className="mb-3 w-1/3 font-normal"
                      >
                        <Input
                          placeholder="gross"
                          className=" py-2 rounded-2xl w-full"
                        />
                      </Form.Item>
                      <Form.Item
                        name="unique"
                        rules={[{ required: true }]}
                        className="mb-3 w-1/3 font-normal"
                      >
                        <Input
                          placeholder="unique"
                          className=" py-2 rounded-2xl w-full"
                        />
                      </Form.Item>
                      <Form.Item
                        name="invalid"
                        rules={[{ required: true }]}
                        className="mb-3 w-1/3 font-normal"
                      >
                        <Input
                          placeholder="invalid"
                          className=" py-2 rounded-2xl w-full"
                        />
                      </Form.Item>
                    </div>
                  </Form.Item>
                </div>

                <div>
                  <Form.Item
                    label="Approved Conversions"
                    name="approvedConversions"
                    className="font-semibold mb-3 w-full"
                  >
                    <div className="flex gap-2">
                      <Form.Item
                        name="approvedqty"
                        rules={[{ required: true }]}
                        className="mb-3 w-1/3 font-normal"
                      >
                        <Input
                          placeholder="quantity"
                          className=" py-2 rounded-2xl w-full"
                        />
                      </Form.Item>
                      <Form.Item
                        name="approvedCr"
                        rules={[{ required: true }]}
                        className="mb-3 w-1/3 font-normal"
                      >
                        <Input
                          placeholder="cr"
                          className=" py-2 rounded-2xl w-full"
                        />
                      </Form.Item>
                      <Form.Item
                        name="approvedPayout"
                        rules={[{ required: true }]}
                        className="mb-3 w-1/3 font-normal"
                      >
                        <Input
                          placeholder="payout"
                          className=" py-2 rounded-2xl w-full"
                        />
                      </Form.Item>
                    </div>
                  </Form.Item>
                </div>

                <div>
                  <Form.Item
                    label="Pending Conversions"
                    name="pendingConversions"
                    className="font-semibold mb-3 w-full"
                  >
                    <div className="flex gap-2">
                      <Form.Item
                        name="pendingQty"
                        rules={[{ required: true }]}
                        className="mb-3 w-1/2 font-normal"
                      >
                        <Input
                          placeholder="quantity"
                          className=" py-2 rounded-2xl w-full"
                        />
                      </Form.Item>
                      <Form.Item
                        name="pendingCr"
                        rules={[{ required: true }]}
                        className="mb-3 w-1/2 font-normal"
                      >
                        <Input
                          placeholder="cr"
                          className=" py-2 rounded-2xl w-full"
                        />
                      </Form.Item>
                    </div>
                  </Form.Item>
                </div>

                <div>
                  <Form.Item
                    label="Rejected Conversions"
                    name="pendingConversions"
                    className="font-semibold mb-3 w-full"
                  >
                    <div className="flex gap-2">
                      <Form.Item
                        name="rejectedQty"
                        rules={[{ required: true }]}
                        className="mb-3 w-1/2 font-normal"
                      >
                        <Input
                          placeholder="quantity"
                          className=" py-2 rounded-2xl w-full"
                        />
                      </Form.Item>
                      <Form.Item
                        name="rejectedCr"
                        rules={[{ required: true }]}
                        className="mb-3 w-1/2 font-normal"
                      >
                        <Input
                          placeholder="cr"
                          className=" py-2 rounded-2xl w-full"
                        />
                      </Form.Item>
                    </div>
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    label="Calculations"
                    name="calculationsAr"
                    rules={[{ required: true }]}
                    className="w-full font-semibold"
                  >
                    <Input
                      placeholder="AR"
                      className=" py-2 rounded-2xl w-full font-normal"
                    />
                  </Form.Item>
                </div>
              </Form>
            </Modal>
            <Modal
              title=""
              centered
              form={updateForm}
              open={updateModalOpen}
              width={700}
              className="p-0 modal_parent_wrapper"
              onOk={() => setUpdateModalOpen(false)}
              onCancel={() => setUpdateModalOpen(false)}
              footer={[
                <Button
                  form="myUpdateForm"
                  key="submit"
                  htmlType="submit"
                  type="primary"
                  className="bg-[#002366] rounded-[50px] min-w-[130px] min-h-[45px]"
                >
                  Update
                </Button>,
              ]}
            >
              <h2 className="uppercase text-center bg-[#002366] text-white mb-4 p-4 text-[25px] font-medium">
                Update Report
              </h2>
              <Form
                name="basic"
                layout="vertical"
                id="myUpdateForm"
                form={updateForm}
                style={{ maxWidth: 700 }}
                className="p-4"
                initialValues={{ remember: true }}
                onFinish={onUpdateFinish}
                onFinishFailed={onUpdateFinishFailed}
                autoComplete="off"
              >
                <div className="flex gap-4 pt-5">
                  <Form.Item
                    label="Name"
                    name="name"
                    className="font-semibold mb-3 w-1/2"
                    rules={[
                      {
                        required: true,
                        message: "Please input your name!",
                      },
                      {
                        validator: validateUserName,
                      },
                    ]}
                  >
                    <Input className=" py-2 rounded-2xl w-full font-normal" />
                  </Form.Item>
                  <Form.Item
                    label="Email"
                    name="email"
                    className="font-semibold mb-3 w-1/2"
                    rules={[
                      {
                        required: true,
                        message: "Please select the email",
                      },
                    ]}
                  >
                    <Select className="w-full" showSearch>
                  {
                    allEmail?.map((item,index)=>{
                      return <Select.Option value={item} key={index}>{ item || "loading..."}</Select.Option>
                    })
                  }
                 
                </Select>
                  </Form.Item>
                </div>

                <div className="flex gap-4">
                  <Form.Item
                    label="Choose Offer"
                    className="font-semibold mb-3 w-full"
                    name="offerId"
                  >
                    <Select
                      // value={allOffers.find(o=> o._id ===)._id}
                      className="w-full font-normal"
                    >
                      {allOffers?.map((offer) => {
                        return (
                          <Select.Option value={offer._id}>
                            {offer.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    label="Clicks"
                    name="clicks"
                    className="font-semibold mb-3 w-full"
                  >
                    <div className="flex gap-2">
                      <Form.Item
                        name="gross"
                        rules={[{ required: true }]}
                        className="mb-3 w-1/3 font-normal"
                      >
                        <Input
                          placeholder="gross"
                          className=" py-2 rounded-2xl w-full"
                        />
                      </Form.Item>
                      <Form.Item
                        name="unique"
                        rules={[{ required: true }]}
                        className="mb-3 w-1/3 font-normal"
                      >
                        <Input
                          placeholder="unique"
                          className=" py-2 rounded-2xl w-full"
                        />
                      </Form.Item>
                      <Form.Item
                        name="invalid"
                        rules={[{ required: true }]}
                        className="mb-3 w-1/3 font-normal"
                      >
                        <Input
                          placeholder="invalid"
                          className=" py-2 rounded-2xl w-full"
                        />
                      </Form.Item>
                    </div>
                  </Form.Item>
                </div>

                <div>
                  <Form.Item
                    label="Approved Conversions"
                    name="approvedConversions"
                    className="font-semibold mb-3 w-full"
                  >
                    <div className="flex gap-2">
                      <Form.Item
                        name="approvedqty"
                        rules={[{ required: true }]}
                        className="mb-3 w-1/3 font-normal"
                      >
                        <Input
                          placeholder="quantity"
                          className=" py-2 rounded-2xl w-full"
                        />
                      </Form.Item>
                      <Form.Item
                        name="approvedCr"
                        rules={[{ required: true }]}
                        className="mb-3 w-1/3 font-normal"
                      >
                        <Input
                          placeholder="cr"
                          className=" py-2 rounded-2xl w-full"
                        />
                      </Form.Item>
                      <Form.Item
                        name="approvedPayout"
                        rules={[{ required: true }]}
                        className="mb-3 w-1/3 font-normal"
                      >
                        <Input
                          placeholder="payout"
                          className=" py-2 rounded-2xl w-full"
                        />
                      </Form.Item>
                    </div>
                  </Form.Item>
                </div>

                <div>
                  <Form.Item
                    label="Pending Conversions"
                    name="pendingConversions"
                    className="font-semibold mb-3 w-full"
                  >
                    <div className="flex gap-2">
                      <Form.Item
                        name="pendingQty"
                        rules={[{ required: true }]}
                        className="mb-3 w-1/2 font-normal"
                      >
                        <Input
                          placeholder="quantity"
                          className=" py-2 rounded-2xl w-full"
                        />
                      </Form.Item>
                      <Form.Item
                        name="pendingCr"
                        rules={[{ required: true }]}
                        className="mb-3 w-1/2 font-normal"
                      >
                        <Input
                          placeholder="cr"
                          className=" py-2 rounded-2xl w-full"
                        />
                      </Form.Item>
                    </div>
                  </Form.Item>
                </div>

                <div>
                  <Form.Item
                    label="Rejected Conversions"
                    name="pendingConversions"
                    className="font-semibold mb-3 w-full"
                  >
                    <div className="flex gap-2">
                      <Form.Item
                        name="rejectedQty"
                        rules={[{ required: true }]}
                        className="mb-3 w-1/2 font-normal"
                      >
                        <Input
                          placeholder="quantity"
                          className=" py-2 rounded-2xl w-full"
                        />
                      </Form.Item>
                      <Form.Item
                        name="rejectedCr"
                        rules={[{ required: true }]}
                        className="mb-3 w-1/2 font-normal"
                      >
                        <Input
                          placeholder="cr"
                          className=" py-2 rounded-2xl w-full"
                        />
                      </Form.Item>
                    </div>
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    label="Calculations"
                    name="calculationsAr"
                    rules={[{ required: true }]}
                    className="w-full font-semibold"
                  >
                    <Input
                      placeholder="AR"
                      className=" py-2 rounded-2xl w-full font-normal"
                    />
                  </Form.Item>
                </div>
              </Form>
            </Modal>
          </div>
          <div className="w-[30%] flex justify-end pt-4 pb-2 pr-3">
            <Search
              placeholder="search..."
              // onSearch={onSearch}
              onChange={onSearch}
              enterButton
              className="w-1/2 user_Search "
            />
          </div>
        </div>

        {/* <Table
          columns={columns}
          dataSource={newData}
          onChange={handleChange}
          className="userTable"
          pagination={paginationConfig}
          loading={allReports?.loading}
        /> */}

        <Table
          dataSource={newData}
          className="reportsTableCell userTable"
          bordered
          scroll={{ x: 1790 }}
          loading={allReports?.loading}
          onChange={handleChange}
          pagination={paginationConfig}
        >
          <Column title="Name" dataIndex="name" key="name" fixed="left" />
          <Column title="Email" dataIndex="email" key="email" />
          <Column title="Offer" dataIndex="offerId" key="offerId" />
          <ColumnGroup title="Clicks">
            <Column title="Gross" dataIndex="gross" key="gross" />
            <Column title="Unique" dataIndex="unique" key="unique" />
            <Column title="Invalid" dataIndex="invalid" key="invalid" />
          </ColumnGroup>
          <ColumnGroup
            title={
              <div className="flex items-center">
                <Tooltip title="Approved Conversion">
                  <div
                    className="w-[9px] h-[9px] rounded-full"
                    style={{ backgroundColor: "rgb(39, 174, 96)" }}
                  ></div>
                </Tooltip>
                <span className="pl-4">Conversions</span>
              </div>
            }
            className="approved_conversion relative before:content-[''] before:w-2 before:h-2 before:absolute before:left-0 before:top-0 "
          >
            <Column title="Qty" dataIndex="approvedqty" key="approvedqty" />
            <Column title="CR" dataIndex="approvedCr" key="approvedCr" />
            <Column
              title="Payout"
              dataIndex="approvedPayout"
              key="approvedPayout"
            />
          </ColumnGroup>
          <ColumnGroup
            title={
              <div className="flex items-center">
                <Tooltip title="Pending Conversion">
                  <div
                    className="w-[9px] h-[9px] rounded-full"
                    style={{ backgroundColor: "rgb(243, 183, 90)" }}
                  ></div>
                </Tooltip>
                <span className="pl-4">Conversions</span>
              </div>
            }
          >
            <Column title="Qty" dataIndex="pendingQty" key="pendingQty" />
            <Column title="CR" dataIndex="pendingCr" key="pendingCr" />
            {/* <Column title="Payout" dataIndex="payout" key="payout" /> */}
          </ColumnGroup>
          <ColumnGroup
            title={
              <div className="flex items-center">
                <Tooltip title="Rejected Conversion">
                  <div
                    className="w-[9px] h-[9px] rounded-full"
                    style={{ backgroundColor: "rgb(246, 90, 99)" }}
                  ></div>
                </Tooltip>
                <span className="pl-4">Conversions</span>
              </div>
            }
          >
            <Column title="Qty" dataIndex="rejectedQty" key="rejectedQty" />
            <Column title="CR" dataIndex="rejectedCr" key="rejectedCr" />
            {/* <Column title="Payout" dataIndex="payout" key="payout" /> */}
          </ColumnGroup>
          <ColumnGroup title="Calculations">
            <Column
              title="AR"
              dataIndex="calculationsAr"
              key="calculationsAr"
            />
          </ColumnGroup>
          <Column
            title="Action"
            dataIndex="action"
            key="action"
            // render={(tags) => (
            //   <>
            //     <Dropdown
            //       menu={{
            //         items,
            //         onClick: (e) => handleMenuClick(e),
            //       }}
            //       className="rounded-2xl bg-[#E3E0E0] border-[#B6B5B5]"
            //     >
            //       <Button>
            //         <Space>
            //           Action
            //           <CaretDownOutlined />
            //         </Space>
            //       </Button>
            //     </Dropdown>
            //   </>
            // )}
          />
        </Table>
      </div>
    </Layout>
  );
};

export default ReportsAndAnalytics;
