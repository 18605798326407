import React, { useEffect, useState } from "react";

import { Input, Table, notification, Tooltip, Select } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import * as moment from "moment";
import {
    getAllMyApprovedOffer,
  searchMyOfferByCategories,
  searchMyOffersAffiliate,
} from "../../../../redux/actions/offer";
import { CopyToClipboard } from "react-copy-to-clipboard";
import emptyImage from "../../../../common/assets/images/emptyImage.jpg";

const { Search } = Input;

const MyOfferTable = () => {
  const offerData = useSelector((state) => state.offers);
  const [itemCount, setItemCount] = useState(offerData?.data?.length);
  const [pageSize, setPageSize] = useState(10);
  const [currPage, setCurrPage] = useState(1);
  const [api, contextHolder] = notification.useNotification();
  const [filterBySearch, setFilterBySearch] = useState("");
  const [filterByCategory, setFilterByCategory] = useState("");
  const dispatch = useDispatch();

  const onSearch = async (e) => {
    setFilterBySearch(e.target.value);
    if (!filterByCategory) {
      await dispatch(searchMyOffersAffiliate(e.target.value));
    } else {
      await dispatch(searchMyOfferByCategories(filterByCategory, e.target.value));
    }
  };

  const otherCopy = () => {
    api.success({
      message: "Copied",
      duration: 3,
    });
  };
  const columns = [
    {
      title: "Offer",
      dataIndex: "offer",
      fixed: "left",
    },
    {
      title: "Link",
      dataIndex: "link",
      ket: "link",
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 3,
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      ket: "description",
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 3,
      },
    },
    {
      title: "Category",
      dataIndex: "category",
      ket: "category",
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 3,
      },
    },
    // {
    //   title: "Original Price",
    //   dataIndex: "originalPrice",
    //   key: "type",
    //   ellipsis: true,
    // },
    {
      title: "Affiliate Commission",
      dataIndex: "discountedPrice",
      key: "value",
      ellipsis: true,
    },
    // {
    //   title: "Discount Type",
    //   dataIndex: "discountType",
    //   sorter: {
    //     compare: (a, b) => a.english - b.english,
    //     multiple: 1,
    //   },
    // },
    // {
    //   title: "Discount Percentage/ Value",
    //   dataIndex: "discountPercentage",
    //   sorter: {
    //     compare: (a, b) => a.english - b.english,
    //     multiple: 1,
    //   },
    // },
    {
      title: "Created At",
      dataIndex: "createdAt",
      sorter: {
        compare: (a, b) => a.english - b.english,
        multiple: 1,
      },
    },

    {
      title: "Action",
      dataIndex: "action",
    },
  ];

  
  const newData = offerData?.data?.map((item) => {
    return {
      offer: (  
        <div className="flex items-center gap-5">
          {/* <Rate count={1} className="affiliateTableStar" /> */}
          <img
            src={item.image || emptyImage}
            alt=""
            className="max-w-none"
            width={40}
            height={40}
          />
          <p className="font-Mulish font-semibold text-base max-w-[80px]">
            {item.name}
          </p>
        </div>
      ),
      link: (
        <>
          {item.landingPageUrl ? (
            <>
              <div className="flex gap-1 affilateOfferLinkWrapper">
                <Tooltip title={item.landingPageUrl}>
                  <span className="affilateOfferLink">
                    {item.landingPageUrl}
                  </span>
                </Tooltip>
                <CopyToClipboard onCopy={otherCopy} text={item.landingPageUrl}>
                  <button>
                    <CopyOutlined />
                  </button>
                </CopyToClipboard>
              </div>
            </>
          ) : (
            ""
          )}
        </>
      ),
      description: <Tooltip title={item.description}><p className="truncate">{item.description}</p></Tooltip>,
      category: item.category,
      originalPrice: item.originalPrice,
      discountedPrice: item.discountedPrice,
      discountPercentage: item.discountedValue,
      discountType: <p className="capitalize">{item.discountType}</p>,
      createdAt: moment(item.createdAt).format("DD-MM-YYYY"),
      action: <p className="font-Mulish font-semibold text-base text-[#047B25]">
      Approved
    </p>,
    };
  });

  const paginationConfig = {
    pageSize: pageSize, // Number of items to display per page
    total: itemCount || 0, // Total number of items in the data source
    showSizeChanger: true, // Show the "Show X items per page" dropdown
    showQuickJumper: true, // Show the quick jumper input field
    pageSizeOptions: ["10", "20", "30"], // Options for items per page dropdown
  };

  const onChangeTable = (pagination, filters, sorter, extra) => {
    setCurrPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const handleCategoriesFilter = async (e) => {
    setFilterByCategory(e);

    await dispatch(searchMyOfferByCategories(e, filterBySearch));
  };

  useEffect(() => {
    (async () => {
      const response = await dispatch(getAllMyApprovedOffer(pageSize, currPage));
      setItemCount(response?.itemCount);
    })();
    // eslint-disable-next-line
  }, [pageSize, currPage]);

  return (
    <div className="bg-white">
      {contextHolder}
      <div className=" flex gap-6 px-5 pb-5 pt-3 ">
        <Search
          placeholder="input search text"
          // onSearch={onSearch}
          onChange={onSearch}
          enterButton
          className="max-w-[290px] affiliateOfferSearch"
        />

        <Select
          className="min-w-[230px]"
          placeholder="Filter by Categories"
          onChange={handleCategoriesFilter}
        >
          <Select.Option value={null}>Select a Category</Select.Option>
          <Select.Option value="automotive">Automotive</Select.Option>
          <Select.Option value="baby">Baby</Select.Option>
          <Select.Option value="booksAndMagazines">
            Books & Magazines
          </Select.Option>
          <Select.Option value="businessOpportunity">
            Business Opportunity
          </Select.Option>
          <Select.Option value="clothingAndShoes">
            Clothing & Shoes
          </Select.Option>
          <Select.Option value="computersAndNetworking">
            Computers & Networking
          </Select.Option>
          <Select.Option value="consumerProducts">
            Consumer Products
          </Select.Option>
          <Select.Option value="dating">Dating</Select.Option>
          <Select.Option value="education">Education</Select.Option>
          <Select.Option value="electronics">Electronics</Select.Option>
          <Select.Option value="email/zipSubmit">
            Email / Zip submit
          </Select.Option>
          <Select.Option value="entertainment">Entertainment</Select.Option>
          <Select.Option value="financeAndInsurance">
            Finance & Insurance
          </Select.Option>
          <Select.Option value="gamesAndConsoles">
            Games & Consoles
          </Select.Option>
          <Select.Option value="giftCardsAndCertificates">
            Gift Cards & Certificates
          </Select.Option>
          <Select.Option value="healthAndBeauty">Health & Beauty</Select.Option>
          <Select.Option value="home">Home</Select.Option>
          <Select.Option value="incentive">Incentive</Select.Option>
          <Select.Option value="internet">Internet</Select.Option>
          <Select.Option value="jewelryAndAccessories">
            Jewelry & Accessories
          </Select.Option>
          <Select.Option value="kitchenwareAndAppliances">
            Kitchenware & Appliances
          </Select.Option>
          <Select.Option value="lawnAndGarden">Lawn & Garden</Select.Option>
          <Select.Option value="mobile">Mobile</Select.Option>
          <Select.Option value="musicAndVideo">Music & Video</Select.Option>
          <Select.Option value="officeProducts">Office Products</Select.Option>
          <Select.Option value="other">Other</Select.Option>
          <Select.Option value="personalAndHome">Personal & Home</Select.Option>
          <Select.Option value="petSuppliesAndProducts">
            Pet Supplies & Products
          </Select.Option>
          <Select.Option value="realEstate">Real Estate</Select.Option>
          <Select.Option value="socialMedia">Social Media</Select.Option>
          <Select.Option value="SportsAndOutdoors">
            Sports & Outdoors
          </Select.Option>
          <Select.Option value="toysAndHobbies">Toys & Hobbies</Select.Option>
          <Select.Option value="travel">Travel</Select.Option>
        </Select>
        {/* <Dropdown menu={menuProps}>
          <Button className="rounded-full bg-[#EEEEEE]">
            <Space>
              <span className="font-Mulish text-base">Availability</span>
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>

        <Dropdown menu={menuProps}>
          <Button className="rounded-full bg-[#EEEEEE]">
            <Space>
              <span className="font-Mulish text-base">Category</span>
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>

        <Dropdown menu={menuProps}>
          <Button className="rounded-full bg-[#EEEEEE]">
            <Space>
              <span className="font-Mulish text-base">Country</span>
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>

        <Checkbox
          onChange={onChange}
          className="affiliateOfferCheckbox items-center capitalize font-Mulish text-base"
        >
          news
        </Checkbox>
        <Checkbox
          onChange={onChange}
          className="affiliateOfferCheckbox items-center capitalize font-Mulish text-base"
        >
          top
        </Checkbox>
        <Checkbox
          onChange={onChange}
          className="affiliateOfferCheckbox items-center capitalize font-Mulish text-base"
        >
          exclusive
        </Checkbox>
        <Checkbox
          onChange={onChange}
          className="affiliateOfferCheckbox items-center"
        >
          <StarFilled
            style={{ color: "#F3CD70" }}
            className="affiliateTableStar"
          />
        </Checkbox> */}
      </div>
      <div className="px-5">
        <p className="float-right pb-2 text-base font-medium">
          {offerData?.data?.length || 0} Result
        </p>
        <Table
          columns={columns}
          dataSource={newData}
          onChange={onChangeTable}
          className="userTable"
          pagination={paginationConfig}
          loading={offerData?.loading}
          scroll={{ x: 1600 }}
        />
      </div>
    </div>
  );
};

export default MyOfferTable;
