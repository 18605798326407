import React, { useState } from "react";
import Layout from "../../../../common/layout";
import { useLocation } from "react-router-dom";
import userImage from "../../../../common/assets/images/user-icon.svg";
import { ArrowUpOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { requestOffer } from "../../../../redux/actions/offer";
import { Table, notification } from "antd";
const SingleOffer = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  console.log('stateeeeeeeeeeeeeeeeeeeeeeeeeee', state)
  const [api, contextHolder] = notification.useNotification();
  const [requestButton, setRequestedButton] = useState(false);

  const approvedConversion = state[0].approvedConversions;

  const payout = approvedConversion.reduce((acc, curr) => {
    return acc + +curr.payout;
  }, 0);

  const { format } = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const newData = state.map((item) => {
    console.log('@!@!@!itemmmmmmm', item,payout)
    return {
      payout: payout !==0 ?  format(payout) :  format(item.discountedPrice),
      category: item.category,
    };
  });

  const columns = [
    {
      title: "Payout",
      dataIndex: "payout",
      width:'50%'
    },  
    {
      title: "Category",
      dataIndex: "category",
      width: '50%'
    },
  ];

  const handleOfferRequest = async (id) => {
    // event.stopPropagation();
    const response = await dispatch(requestOffer(id));
    setRequestedButton(response?.data?.hasRequested);
    if (response?.data) {
      api.success({
        message: response?.message || "Success",
        duration: 3,
      });
    } else {
      api.error({
        message: response?.message || "Something went wrong",
        duration: 3,
      });
    }
  };
  return (
    <Layout>
      <div>
        {contextHolder}
        <div class="flex justify-between my-3">
          <h1 class="capitalize font-semibold text-3xl">Offers</h1>
        </div>
        {state.map((item) => (
          <div className=" bg-white mt-2 p-5 rounded ">
            {  console.log('itemmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm', item)}
            <div className="w-full ">
              <div className=" flex gap-5 justify-between">
                <img
                  src={item.image || userImage}
                  alt="avatar"
                  width={140}
                  height={140}
                  className=" h-[120px] w-[120px] border border-dashed p-1"
                />
                <div>
                  <h2 className="text-2xl font-semibold">{item.name}</h2>
                  <div className=" gap-4 mt-5 mr-5 mb-4">
                    <button
                      onClick={() =>
                        window.open(
                          item.landingPageUrl || "",
                          "_blank",
                          "noreferrer"
                        )
                      }
                      className="border-2 border-[#002366]  px-3 py-1 rounded-md  text-[#002366] text-center mb-5 font-semibold"
                    >
                      Landing Page{" "}
                      <ArrowUpOutlined className="rotate-45  text-xl" />
                    </button>
                    <br />
                    {item.hasRequested || requestButton ? (
                      <button
                        className="bg-[#002366] opacity-75 rounded-md text-white py-2 px-5 min-w-[163px]"
                        disabled={item.hasRequested}
                        onClick={(event) => event.stopPropagation()}
                      >
                        Requested
                      </button>
                    ) : (
                      <button
                        className="bg-[#002366] rounded-md text-white py-2 px-5 min-w-[163px]"
                        onClick={(event) => handleOfferRequest(item._id, event)}
                      >
                        Request
                      </button>
                    )}
                  </div>
                </div>
              </div>

              <div className="  my-[10px]   ">
                <Table
                  columns={columns}
                  dataSource={newData}
                  // onChange={onChangeTable}
                  className="offerTable rowPointer singleOfferTable"
                  pagination={false}
                  // loading={offerData?.loading}
                  // scroll={{ x: 1600 }}
                  // onRow={rowProps}
                  col
                />
                {/* <hr className="mb-5"/> */}
                {/* <div className="flex gap-20 mb-5 border-b pb-3">
                <h2 className="font-medium  w-1/3">Payout</h2>
                <p className="w-2/3">{format(payout)|| 0}</p>
              </div> */}
                <div className="border min-h-[200px] px-4 py-4 mt-10 bg-[#fafafa] rounded-lg" dangerouslySetInnerHTML={{__html: item?.description?.replaceAll("\r\n","<br/><br/>")}}/> 
              </div>
            </div>
          </div>
        ))}
      </div>
    </Layout>
  );
};

export default SingleOffer;
