import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
const Protected = ({ Component, allowedRoles ,socketResponse }) => {
  const isLoggedIn = !!localStorage.getItem("accessToken")
  const navigate = useNavigate()


  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/');
    } else if (allowedRoles && allowedRoles.length > 0) {
      const profile = localStorage.getItem('profile')

      if (!allowedRoles.includes(profile)) {
        navigate('*');
      }
    }
  }, [isLoggedIn, allowedRoles, navigate]);
  return(
    <div>
      <Component socket={socketResponse}/>
    </div>
  )
 
  
};
export default Protected;