import React, { useState } from "react";
import userIcon from "../../../common/assets/images/user-icon(40.svg";
import offerIcon from "../../../common/assets/images/vectorManagement.svg";
import commissionIcon from "../../../common/assets/images/payout_management.svg";
import contentCreativeIcon from "../../../common/assets/images/creative_management.svg";
import supportIcon from "../../../common/assets/images/support.png";
import anayliticIcon from "../../../common/assets/images/Reporting_Management.svg";
import { NavLink, useNavigate } from "react-router-dom";
import { Menu } from "antd";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const adminItems = [
  getItem(
    "User Management",
    "/dashboard/user-management",
    <div className="bg-[#D8D8D8] border-[2px] border-[#6178A5] rounded-full max-w-[55px] max-h-[55px] min-w-[55px] min-h-[55px] p-2 flex items-center justify-center">
      <img src={userIcon} className=" max-w-[32px] max-h-[32px]" alt="" />
    </div>
  ),
  getItem(
    "Offer Management",
    "/dashboard/offer-management",
    <div className="bg-[#D8D8D8] border-[2px] border-[#6178A5] rounded-full max-w-[55px] max-h-[55px] min-w-[55px] min-h-[55px] p-2 flex items-center justify-center">
      <img src={offerIcon} className=" max-w-[32px] max-h-[32px]" alt="" />
    </div>
  ),
  getItem(
    "Commission and Payout Management",
    "/dashboard/commission-payout",
    <div className="bg-[#D8D8D8] border-[2px] border-[#6178A5] rounded-full max-w-[55px] max-h-[55px] min-w-[55px] min-h-[55px] p-2 flex items-center justify-center">
      <img src={commissionIcon} className=" max-w-[32px] max-h-[32px]" alt="" />
    </div>
  ),
  getItem(
    "News",
    "/dashboard/news",
    <div className="bg-[#D8D8D8] border-[2px] border-[#6178A5] rounded-full max-w-[55px] max-h-[55px] min-w-[55px] min-h-[55px] p-2 flex items-center justify-center">
      <img
        src={contentCreativeIcon}
        className=" max-w-[32px] max-h-[32px]"
        alt=""
      />
    </div>
  ),
  getItem(
    "Blogs",
    "/dashboard/blogs",
    <div className="bg-[#D8D8D8] border-[2px] border-[#6178A5] rounded-full max-w-[55px] max-h-[55px] min-w-[55px] min-h-[55px] p-2 flex items-center justify-center">
      <img src={supportIcon} className=" max-w-[32px] max-h-[32px]" alt="" />
    </div>
  ),
  getItem(
    "Reporting & Analytics",
    "/dashboard/reportsAndAnalytics",
    <div className="bg-[#D8D8D8] border-[2px] border-[#6178A5] rounded-full max-w-[55px] max-h-[55px] min-w-[55px] min-h-[55px] p-2 flex items-center justify-center">
      <img src={anayliticIcon} className=" max-w-[32px] max-h-[32px]" alt="" />
    </div>
  ),
];
const partnerShipItems = [
  getItem(
    "User Management",
    "/dashboard/user-management",
    <div className="bg-[#D8D8D8] border-[2px] border-[#6178A5] rounded-full max-w-[55px] max-h-[55px] min-w-[55px] min-h-[55px] p-2 flex items-center justify-center">
      <img src={userIcon} className=" max-w-[32px] max-h-[32px]" alt="" />
    </div>
  ),
  getItem(
    "Offer Management",
    "/dashboard/offer-management",
    <div className="bg-[#D8D8D8] border-[2px] border-[#6178A5] rounded-full max-w-[55px] max-h-[55px] min-w-[55px] min-h-[55px] p-2 flex items-center justify-center">
      <img src={offerIcon} className=" max-w-[32px] max-h-[32px]" alt="" />
    </div>
  ),
  getItem(
    "News",
    "/dashboard/news",
    <div className="bg-[#D8D8D8] border-[2px] border-[#6178A5] rounded-full max-w-[55px] max-h-[55px] min-w-[55px] min-h-[55px] p-2 flex items-center justify-center">
      <img
        src={contentCreativeIcon}
        className=" max-w-[32px] max-h-[32px]"
        alt=""
      />
    </div>
  ),
  getItem(
    "Blogs",
    "/dashboard/blogs",
    <div className="bg-[#D8D8D8] border-[2px] border-[#6178A5] rounded-full max-w-[55px] max-h-[55px] min-w-[55px] min-h-[55px] p-2 flex items-center justify-center">
      <img src={supportIcon} className=" max-w-[32px] max-h-[32px]" alt="" />
    </div>
  ),

];
const AdminSidebar = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [collapsed, setCollapsed] = useState(false);
  const [selectedKey, setSelectedKey] = useState(window.location.pathname);
  const currentUserRole = localStorage.getItem("profile");

  const handleMenu = ({ key, domEvent }) => {
    setSelectedKey(key);
    navigate(key);
  };

  return (
    <div className="bg-[#002366] h-screen">
      <NavLink to="/dashboard">
        <div className="bg-[#011842]">
          <h1 className="text-white text-center text-3xl font-semibold leading-[45px] pt-[17px] pb-[18px]">
            Admin Dashboard
          </h1>
          <hr />
        </div>
      </NavLink>
      <Menu
        onClick={handleMenu}
        selectedKeys={[selectedKey]}
        mode="inline"
        className="bg-[#002366] text-white sidebarItems"
        inlineCollapsed={collapsed}
        items={currentUserRole==='partnershipView' ? partnerShipItems : adminItems}
      />
    </div>
  );
};

export default AdminSidebar;
