import React, { useEffect } from "react";
import Layout from "../../../../common/layout";
import { Collapse, Switch } from "antd";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getAllNews } from "../../../../redux/actions/adminNews";
import emptyImage from '../../../../common/assets/images/emptyImage.jpg'

const News = () => {
  const dispatch = useDispatch();
  const allNews = useSelector((store) => store.news.data);
  const allNewsList = (
    allNews.map((news)=>{
      return {
        key: news._id,
        label: (
          <div className="flex gap-11 items-center font-Mulish font-medium text-xl">
            <img src={news.image || emptyImage} width={65} height={65} alt="" />
            <p>{news.title}</p>
          </div>
        ),
        children: <div dangerouslySetInnerHTML={{__html: news?.description?.replaceAll("\r\n","<br/><br/>")}}/>,
      }
    })
    
  );
  useEffect(()=>{
    dispatch(getAllNews());
    // eslint-disable-next-line
  },[])
  return (
    <Layout>
      <div>
        <div className="flex justify-between my-3">
          <h1 className="capitalize font-semibold text-3xl">news</h1>
          <button className="bg-[#002366] text-white py-2 px-4 rounded-[7px]">
            Mark all as read
          </button>
        </div>
        <hr className="bg-[#333333] opacity-40 h-px border-0" />

        <div className="my-5 flex gap-2">
          <div className="w-3/4 bg-white p-5">
            <Collapse
              accordion
              items={allNewsList}
              expandIconPosition="end"
              expandIcon={({ isActive }) =>
                isActive ? <MinusCircleOutlined /> : <PlusCircleOutlined />
              }
              className="newsAccordian"
            />
          </div>
          <div className="bg-white w-1/4">
            <div className="flex gap-3 items-center p-5">
              <Switch defaultChecked className="newsSwitch" />
              <p className="capitalize font-Mulish font-semibold text-2xl">
                on my offers
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default News;
