import axios from "../../utils/interceptor/index";
import {
  ADD_OFFER_URL,
  ADD_PROMOTE_OFFER_URL,
  DELETE_OFFER_URL,
  GET_ALL_MY_APPROVED_OFFER_URL,
  GET_ALL_OFFER_URL,
  GET_ALL_PROMOTED_URL,
  GET_ALL_REQUESTED_OFFERS_URL,
  REMOVE_PROMOTED_OFFER_URL,
  REQUEST_OFFER_URL,
  REVIEW_OFFER_REQUESTS_URL,
  UPDATE_OFFER_URL,
} from "../../utils/api/urls";
import {
  CREATE_OFFER_SUCCESS,
  CREATE_PROMOTE_SUCCESS,
  DELETE_OFFER_SUCCESS,
  GET_ALL_MY_APPROVED_OFFER_SUCCESS,
  GET_ALL_OFFER_SUCCESS,
  GET_ALL_PROMOTED_OFFER_SUCCESS,
  GET_ALL_REQUESTED_OFFER_SUCCESS,
  OFFER_FAILURE,
  OFFER_REQUEST,
  REMOVE_PROMOTED_OFFER_SUCCESS,
  SEARCHED_OFFER_BY_CATEGORY_SUCCESS,
  SEARCHED_OFFER_SUCCESS,
  SEARCHED_REQUESTED_OFFERS,
  UPDATE_OFFER_ISREQUESTED_SUCCESS,
  UPDATE_OFFER_SUCCESS,
  UPDATE_REQUESTED_OFFER_LINK_SUCCESS,
  UPDATE_REQUESTED_OFFER_SUCCESS,
} from "../constants";

export const addOffer = (payload) => {
  return async (dispatch) => {
    dispatch({ type: OFFER_REQUEST });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Add any other headers if needed
      };
      const response = await axios.post(ADD_OFFER_URL, payload, {
        headers,
      });
      if (response?.data) {
        dispatch({
          type: CREATE_OFFER_SUCCESS,
          payload: response?.data?.data || {},
        });
        return response?.data;
      }
    } catch (error) {
      dispatch({ type: OFFER_FAILURE, payload: { error } });
      return error?.response?.data;
    }
  };
};

export const addToPromote = (id , description)=>{
  const promotePayload = {offer: id, description: description}
  return async (dispatch)=>{
    dispatch({type: OFFER_REQUEST});
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Add any other headers if needed
      };
      const response = await axios.post(ADD_PROMOTE_OFFER_URL, promotePayload, {
        headers,
      });
      if (response?.data) {
        dispatch({
          type: CREATE_PROMOTE_SUCCESS,
          payload: response?.data?.data || {},
        });
        return response?.data;
      }
    } catch (error) {
      dispatch({ type: OFFER_FAILURE, payload: { error } });
      return error?.response?.data;
    }

  }
}

export const removePromote = (id) => {
  return async (dispatch) => {
    dispatch({ type: OFFER_REQUEST });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Add any other headers if needed
      };
      const result = await axios.delete(
        `${REMOVE_PROMOTED_OFFER_URL}/${id}`,
        { headers }
      );
      dispatch({
        type: REMOVE_PROMOTED_OFFER_SUCCESS,
        payload: result?.data?.data || {},
      });
      return result?.data;
    } catch (err) {
      dispatch({ type: OFFER_FAILURE, payload: err });
      return err?.response?.data;
    }
  };
};

export const getAllPromotedOffer = (page = 10, currPage = 1) => {
  return async (dispatch) => {
    dispatch({ type: OFFER_REQUEST });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Add any other headers if needed
      };
      const response = await axios.get(`${GET_ALL_PROMOTED_URL}?limit=${page}&pageNo=${currPage}`, { headers });
      if (response?.data) {
        dispatch({
          type: GET_ALL_PROMOTED_OFFER_SUCCESS,
          payload: response?.data?.data,
        });
        return response?.data?.data;
      }
    } catch (error) {
      dispatch({ type: OFFER_FAILURE, payload: { error } });
    }
  };
};

export const getAllMyApprovedOffer = (page = 10, currPage = 1) => {
  return async (dispatch) => {
    dispatch({ type: OFFER_REQUEST });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Add any other headers if needed
      };
      const response = await axios.get(`${GET_ALL_MY_APPROVED_OFFER_URL}?limit=${page}&pageNo=${currPage}`, { headers });
      if (response?.data) {
        dispatch({
          type: GET_ALL_MY_APPROVED_OFFER_SUCCESS,
          payload: response?.data?.data?.paginatedData,
        });
        return response?.data?.data;
      }
    } catch (error) {
      dispatch({ type: OFFER_FAILURE, payload: { error } });
    }
  };
};
export const getAllOffer = (page = 10, currPage = 1) => {
  return async (dispatch) => {
    dispatch({ type: OFFER_REQUEST });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Add any other headers if needed
      };
      const response = await axios.get(`${GET_ALL_OFFER_URL}?limit=${page}&pageNo=${currPage}`, { headers });
      if (response?.data) {
        dispatch({
          type: GET_ALL_OFFER_SUCCESS,
          payload: response?.data?.data?.paginatedData,
        });
        return response?.data?.data;
      }
    } catch (error) {
      dispatch({ type: OFFER_FAILURE, payload: { error } });
    }
  };
};

export const updateOffer = (id, payload) => {
  return async (dispatch) => {
    dispatch({ type: OFFER_REQUEST });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Add any other headers if needed
      };
      const result = await axios.put(
        `${UPDATE_OFFER_URL}/${id}`,
        payload,
        { headers }
      );

      dispatch({
        type: UPDATE_OFFER_SUCCESS,
        payload: result?.data?.data || {},
      });
      return result?.data;
    } catch (err) {
      dispatch({ type: OFFER_FAILURE, payload: err });
      return err?.response?.data;
    }
  };
};

export const deleteOffer = (id) => {
  return async (dispatch) => {
    dispatch({ type: OFFER_REQUEST });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Add any other headers if needed
      };
      const result = await axios.delete(
        `${DELETE_OFFER_URL}/${id}`,
        { headers }
      );
      dispatch({
        type: DELETE_OFFER_SUCCESS,
        payload: result?.data?.data || {},
      });
      return result?.data;
    } catch (err) {
      dispatch({ type: OFFER_FAILURE, payload: err });
      return err?.response?.data;
    }
  };
};


//all offer in affiliate dashboard
export const searchOfferByCategories = (categoryParams,searchParams) => {
  return async (dispatch) => {
    dispatch({ type: OFFER_REQUEST });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Add any other headers if needed
      };
      const response = await axios.get(categoryParams && searchParams?
        `${GET_ALL_OFFER_URL}?category=${categoryParams}&search=${searchParams}`: categoryParams ? `${GET_ALL_OFFER_URL}?category=${categoryParams}` : GET_ALL_OFFER_URL ,

        {
          headers,
        }
      );

      if (response?.data) {
        dispatch({
          type: SEARCHED_OFFER_BY_CATEGORY_SUCCESS,
          payload: response?.data?.data?.paginatedData || {},
        });
        return response?.data?.data || {};
      }
    } catch (error) {
      dispatch({ type: OFFER_FAILURE, payload: { error } });
    }
  };
};

//SEARCH MY OFFER FOR AFFILIATE DASHBOARD
export const searchMyOfferByCategories = (categoryParams,searchParams) => {
  return async (dispatch) => {
    dispatch({ type: OFFER_REQUEST });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Add any other headers if needed
      };
      const response = await axios.get(categoryParams && searchParams?
        `${GET_ALL_MY_APPROVED_OFFER_URL}&category=${categoryParams}&search=${searchParams}`: categoryParams ? `${GET_ALL_MY_APPROVED_OFFER_URL}&category=${categoryParams}` : GET_ALL_MY_APPROVED_OFFER_URL ,

        {
          headers,
        }
      );

      if (response?.data) {
        dispatch({
          type: SEARCHED_OFFER_BY_CATEGORY_SUCCESS,
          payload: response?.data?.data?.paginatedData || {},
        });
        return response?.data?.data || {};
      }
    } catch (error) {
      dispatch({ type: OFFER_FAILURE, payload: { error } });
    }
  };
};
export const searchOffer = (searchParams) => {
  return async (dispatch) => {
    dispatch({ type: OFFER_REQUEST });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Add any other headers if needed
      };
      const response = await axios.get(
        `${GET_ALL_OFFER_URL}?search=${searchParams}`,

        {
          headers,
        }
      );

      if (response?.data) {
        dispatch({
          type: SEARCHED_OFFER_SUCCESS,
          payload: response?.data?.data?.paginatedData || {},
        });
        return response?.data?.data || {};
      }
    } catch (error) {
      dispatch({ type: OFFER_FAILURE, payload: { error } });
    }
  };
};

//affiliate dashboard offers- my offers
export const searchMyOffersAffiliate = (searchParams) => {
  return async (dispatch) => {
    dispatch({ type: OFFER_REQUEST });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Add any other headers if needed
      };
      const response = await axios.get(
        `${GET_ALL_MY_APPROVED_OFFER_URL}&search=${searchParams}`,

        {
          headers,
        }
      );

      if (response?.data) {
        dispatch({
          type: SEARCHED_OFFER_SUCCESS,
          payload: response?.data?.data?.paginatedData || {},
        });
        return response?.data?.data || {};
      }
    } catch (error) {
      dispatch({ type: OFFER_FAILURE, payload: { error } });
    }
  };
};

export const requestOffer = (id) => {
  return async (dispatch) => {
    dispatch({ type: OFFER_REQUEST });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      };
      const result = await axios.post(
        `${REQUEST_OFFER_URL}/${id}`,
        null,
        {
          headers,
        }
      );
      dispatch({
        type: UPDATE_OFFER_ISREQUESTED_SUCCESS,
        payload: result?.data?.data || {},
      });
      return result?.data;
    } catch (err) {
      dispatch({ type: OFFER_FAILURE, payload: err });
      return err?.response?.data;
    }
  };
};

export const getAllRequestedOffers = (page = 10, currPage = 1) => {
  return async (dispatch) => {
    dispatch({ type: OFFER_REQUEST });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Add any other headers if needed
      };
      const requestedOffers = await axios.get(
        `${GET_ALL_REQUESTED_OFFERS_URL}?limit=${page}&pageNo=${currPage}`,
        {
          headers,
        }
      );
      if (requestedOffers?.data?.data) {
        dispatch({
          type: GET_ALL_REQUESTED_OFFER_SUCCESS,
          payload: requestedOffers?.data?.data?.paginatedData,
        });
      }
      return requestedOffers?.data?.data;
    } catch (error) {
      dispatch({
        type: OFFER_FAILURE,
      });
    }
  };
};

export const searchRequestedOffer = (searchParams) => {
  return async (dispatch) => {
    dispatch({ type: OFFER_REQUEST });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Add any other headers if needed
      };
      const response = await axios.get(
        `${GET_ALL_REQUESTED_OFFERS_URL}?search=${searchParams}`,

        {
          headers,
        }
      );

      if (response?.data) {
        dispatch({
          type: SEARCHED_REQUESTED_OFFERS,
          payload: response?.data?.data?.paginatedData || {},
        });
        return response?.data?.data || {};
      }
    } catch (error) {
      dispatch({ type: OFFER_FAILURE, payload: { error } });
    }
  };
};

export const approveDisapproveRequestedOffer = (id, key) => {
  return async (dispatch) => {
    dispatch({ type: OFFER_REQUEST });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Add any other headers if needed
      };
      const response = await axios.post(
        `${REVIEW_OFFER_REQUESTS_URL}/${id}`,
        {
          isApproved: key,
        },
        {
          headers,
        }
      );
      if (response?.data) {
        dispatch({
          type: UPDATE_REQUESTED_OFFER_SUCCESS,
          payload: response?.data?.data,
        });
        return response?.data;
      }
    } catch (error) {
      dispatch({ type: OFFER_FAILURE, payload: error });
      return error;
    }
  };
};

export const updateRequestedUrlLink = (id,userId, payload) => {
  return async (dispatch) => {
    dispatch({ type: OFFER_REQUEST });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Add any other headers if needed
      };
      const response = await axios.put(
        `${UPDATE_OFFER_URL}/${id}`,
        {...payload,userId},
        {
          headers,
        }
      );
      if (response?.data) {
        dispatch({
          type: UPDATE_REQUESTED_OFFER_LINK_SUCCESS,
          payload: response?.data?.data,
        });
        return response?.data;
      }
    } catch (error) {
      dispatch({ type: OFFER_FAILURE, payload: error });
      return error;
    }
  };
};
