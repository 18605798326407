import axios from "../../utils/interceptor/index";
import {
  DELETE_ADMIN_INVOICE,
  GET_ALL_INVOICE,
  INVOICE_REQUEST,
  INVOICE_REVIEW_REQUEST,
  INVOICE_REVIEW_SUCCESS,
} from "../constants";
import { DELETE_ADMIN_INVOICE_URL, GET_INVOICE_URL, REVIEW_INVOICE_URL } from "../../utils/api/urls";

export const getAllAdminInvoices = () => {
  return async (dispatch) => {
    dispatch({ type: INVOICE_REQUEST });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Add any other headers if needed
      };
      const invoiceData = await axios.get(GET_INVOICE_URL, { headers });
      if (invoiceData?.data) {
        dispatch({
          type: GET_ALL_INVOICE,
          payload: invoiceData?.data?.data,
        });
        return invoiceData?.data;
      }
    } catch (error) {
      return error?.response?.data;
    }
  };
};

export const reviewInvoice = (id, key) => {
  return async (dispatch) => {
    dispatch({ type: INVOICE_REVIEW_REQUEST });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Add any other headers if needed
      };
      const reviewResponse = await axios.put(
        `${REVIEW_INVOICE_URL}/${id}`,
        { status: key },
        { headers }
      );
      if (reviewResponse?.data) {
        dispatch({
          type: INVOICE_REVIEW_SUCCESS,
          payload: reviewResponse?.data?.data,
        });
      }
      return reviewResponse?.data;
    } catch (error) {
    }
  };
};
export const deleteAdminInvoice = (id) => {
  return async (dispatch) => {
    dispatch({ type: INVOICE_REQUEST });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      };
      const response = await axios.delete(
        `${DELETE_ADMIN_INVOICE_URL}/${id}`,
        { headers }
      );
      if (response?.data) {
        dispatch({
          type: DELETE_ADMIN_INVOICE,
          payload: response?.data?.data,
        });
      }
      return response?.data;
    } catch (error) {
      console.log("error", error);
    }
  };
};
