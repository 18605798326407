import React from "react";
import Layout from "../../../../common/layout";

import PaymentMethod from "./PaymentMethod";

const AffiliateInvoice = () => {

  return (
    <Layout>
      <div>
      <h3 className="capitalize font-semibold text-3xl my-3">
          Billing
        </h3>
        <hr class="bg-[#333333] opacity-40 h-px border-0"></hr>
        {/* <Balance/> */}
        <PaymentMethod/>
        {/* <Invoices/> */}
      </div>
    </Layout>
  );
};

export default AffiliateInvoice;
