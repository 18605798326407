import React from "react";
import {
  Button,
  Dropdown,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
  Space,
  Table,
  Tooltip,
  Upload,
  notification,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  CaretDownOutlined,
  CheckCircleOutlined,
  StarFilled
} from "@ant-design/icons";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addToPromote,
  deleteOffer,
  getAllOffer,
  removePromote,
  updateOffer,
} from "../../../../redux/actions/offer";
import { useEffect } from "react";
import { useWatch } from "antd/es/form/Form";
import moment from "moment";
import TextArea from "antd/es/input/TextArea";
/** @jsxImportSource theme-ui */
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const convertImageUrlToObject = async (imageUrl) => {
  const response = await fetch(imageUrl);
  const blob = await response.blob();

  // Create a File object with the Blob data
  const file = new File([blob], "image.jpg", { type: "image/jpeg" });
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      const fileObject = {
        uid: Date.now().toString(), // Generate a unique ID
        size: img.size, // You can set the actual size here if available
        name: imageUrl.split("/").pop(), // Extract the file name from the URL
        url: imageUrl,
        type: "image/jpeg", // Set the appropriate content type
        lastModified: Date.now(), // Set the current timestamp as last modified
        lastModifiedDate: new Date(),
        status: "done", // You can set the initial status here
        percent: 100,
        thumbUrl: imageUrl, // You can set a thumbnail URL if needed
        originFileObj: file, // You can set the original file object here if available
        response: null,
        error: null,
        linkProps: null,
        xhr: null,
        preview: imageUrl, // Set the preview URL
      };

      resolve(fileObject);
      return fileObject;
    };

    img.onerror = (error) => {
      reject(error);
    };
  });
};

const AllAdminOffers = (props) => {
  const [api, contextHolder] = notification.useNotification();
  const allOffers = useSelector((store) => store.offers);
  const dispatch = useDispatch();
  const [updateForm] = Form.useForm();
  const values = useWatch([], updateForm);
  const [itemCount, setItemCount] = useState(allOffers?.data?.length);
  const [pageSize, setPageSize] = useState(10);
  const [currPage, setCurrPage] = useState(1);
  const [submittable, setSubmittable] = useState(false);
  // const [deletePopup, setDeletePopup] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false); // eslint-disable-next-line
  const [previewOpen, setPreviewOpen] = useState(false);
  // eslint-disable-next-line
  const [previewImage, setPreviewImage] = useState("");
  // eslint-disable-next-line
  const [previewTitle, setPreviewTitle] = useState("");
  // eslint-disable-next-line
  const [fileList, setFileList] = useState([]);
  const [id, setId] = useState("");
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const currentUserRole = localStorage.getItem("profile");

  // eslint-disable-next-line
  const handleCancel = () => setPreviewOpen(false);
  // eslint-disable-next-line
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  // eslint-disable-next-line
  const handleChangeP = ({ fileList: newFileList }) => setFileList(newFileList);
  // eslint-disable-next-line

  const handleMenuClick = async (
    e,
    id,
    image,
    name,
    landingPageUrl,
    description,
    previewUrl,
    category,
    originalPrice,
    discountedPrice,
    discountPercentage,
    discountType,
    discountedValue
  ) => {
    const { key } = e;
    setId(id);
    if (key === "edit") {
      if (image && image.length > 0) {
        // If a new image is selected, use it
        // formData.append("image", image[0].originFileObj);
        convertImageUrlToObject(image)
          .then((result) => {
            setUpdateModalOpen(true);
            updateForm.setFieldsValue({
              key: id,
              name: name,
              image: [result],
              landingPageUrl: landingPageUrl,
              description: description,
              previewUrl: previewUrl,
              category: category,
              originalPrice: originalPrice,
              discountedPrice: discountedPrice,
              discountPercentage: discountPercentage,
              discountType: discountType,
              discountedValue: discountedValue,
            });
          })
          .catch((error) => {
            console.error("Error converting image:", error);
          });
      } else {
        setUpdateModalOpen(true);
        updateForm.setFieldsValue({
          key: id,
          name: name,
          landingPageUrl: landingPageUrl,
          description: description,
          previewUrl: previewUrl,
          category: category,
          originalPrice: originalPrice,
          discountedPrice: discountedPrice,
          discountPercentage: discountPercentage,
          discountType: discountType,
          discountedValue: discountedValue,
        });
      }
      setUpdateModalOpen(true);
      // form.setFieldValue()
    } else if(key === "delete"){
      //  setDeletePopup(true)
      setId(id);
    }else if(key === "addPromote"){
      const response = await dispatch(addToPromote(id,description))
    }
    else{
      const response = await dispatch(removePromote(id))
    }
  };
  const handleDeleteOffer = async () => {
    const deleteOfferResult = await dispatch(deleteOffer(id));
    if (deleteOfferResult?.data) {
      api.success({
        message: deleteOfferResult?.message || "Success",
        duration: 3,
      });
    } else {
      api.error({
        message: deleteOfferResult?.message || "Something went wrong",
        duration: 3,
      });
    }
  };
  const handleUserDeleteCancel = () => {
    // setDeletePopup(false)
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  const items1 = [
    {
      label: "Edit",
      key: "edit",

      icon: <EditOutlined />,
    },
    {
      label: (
        <Popconfirm
          description="Are you sure you want to delete this offer?"
          okText="Yes"
          cancelText="No"
          onConfirm={handleDeleteOffer}
          onCancel={handleUserDeleteCancel}
          style={{ maxWidth: "100px" }} 
        >
          <DeleteOutlined />
          &nbsp; Delete
        </Popconfirm>
      ),
      key: "delete",

      icon: "",
    },
    {
      label: "Add to Promote",
      key: "addPromote",  
      icon: <CheckCircleOutlined />,

    },
  ];
  const items2 = [
    {
      label: "Edit",
      key: "edit",

      icon: <EditOutlined />,
    },
    {
      label: (
        <Popconfirm
          description="Are you sure you want to delete this offer?"
          okText="Yes"
          cancelText="No"
          onConfirm={handleDeleteOffer}
          onCancel={handleUserDeleteCancel}
          style={{ maxWidth: "100px" }} 
        >
          <DeleteOutlined />
          &nbsp; Delete
        </Popconfirm>
      ),
      key: "delete",

      icon: "",
    },
    {
      label: "Remove Promote",
      key: "removePromote",  
      icon: <CheckCircleOutlined />,

    },
  ];

  const newData = allOffers?.data?.map((item) => {

    return {
      name: <p>{item.name}<sup>{item?.isPromoted?<StarFilled className="text-yellow-300"/>:""}</sup></p>,
      // link: item.link, 
      landingPageUrl: item.landingPageUrl,
      description: <Tooltip title={item.description}><p className="truncate">{item.description}</p></Tooltip>,
      previewUrl: item.previewUrl,
      category: item.category,
      originalPrice: item.originalPrice,
      discountedPrice: item.discountedPrice,
      discountPercentage: item.discountedValue,
      discountType: <p className="capitalize">{item.discountType}</p>,
      createdAt: moment(item.createdAt).format("DD-MM-YYYY"),

      action: (
        <Dropdown
          menu={{
            items: item.isPromoted? items2: items1,
            onClick: (e) =>
              handleMenuClick(
                e,
                item._id,
                item.image,
                item.name,
                item.landingPageUrl,
                item.description,
                item.previewUrl,
                item.category,
                item.originalPrice,
                item.discountedPrice,
                item.discountPercentage,
                item.discountType,
                item.discountedValue
              ),
          }}  
          className="rounded-2xl bg-[#E3E0E0] border-[#B6B5B5]"
        >
          <Button>
            <Space>
              Action
              <CaretDownOutlined />
            </Space>
          </Button>
        </Dropdown>
      ),
    };
  });

  const handleDiscountType = (e) => {
    const discountedPrice = updateForm.getFieldValue("discountedPrice");
    const originalPrice = updateForm.getFieldValue("originalPrice");
    if (e === "percentage") {
      const percentage =
        ((originalPrice - discountedPrice) / originalPrice) * 100;
      updateForm.setFieldsValue({
        discountedValue: percentage.toFixed(2),
      });
    } else {
      updateForm.setFieldsValue({
        discountedValue: originalPrice - discountedPrice,
      });
    }
  };

  const onUpdateFinish = async ({
    image,
    name,
    landingPageUrl,
    description,
    previewUrl,
    category,
    originalPrice,
    discountedPrice,
    discountType,
    discountedValue,
  }) => {
    let formData = new FormData();
    formData.append("name", name);
    formData.append("landingPageUrl", landingPageUrl);
    formData.append("description", description);
    formData.append("previewUrl", previewUrl);
    formData.append("category", category);
    formData.append("originalPrice", originalPrice);
    formData.append("discountedPrice", discountedPrice);
    formData.append("discountType", discountType);
    formData.append("discountedValue", discountedValue);

    if (image && image.length > 0) {
      // If a new image is selected, use it
      formData.append("image", image[0].originFileObj);
    }
    const updateOfferResult = await dispatch(updateOffer(id, formData));
    if (updateOfferResult?.data) {
      setUpdateModalOpen(false);
      updateForm.resetFields();
      api.success({
        message: updateOfferResult?.message || "Success",
        duration: 5,
      });
    } else {
      api.error({
        message: updateOfferResult?.message || "Something went wrong",
        duration: 5,
      });
    }
  };

  const onUpdateFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleChange = (pagination, filters, sorter) => {
    setCurrPage(pagination.current);
    setPageSize(pagination.pageSize);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };
  // eslint-disable-next-line

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      filteredValue: filteredInfo.name || null,
      onFilter: (value, record) => record.name.includes(value),
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
      ellipsis: true,
      fixed: "left",
    },
    {
      title: "Landing Page Url",
      dataIndex: "landingPageUrl",
      key: "landingPageUrl",
      filteredValue: filteredInfo.landingPageUrl || null,
      onFilter: (value, record) => record.landingPageUrl.includes(value),
      sorter: (a, b) => a.name.length - b.landingPageUrl.length,
      sortOrder:
        sortedInfo.columnKey === "landingPageUrl" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      filteredValue: filteredInfo.description || null,
      onFilter: (value, record) => record.description.includes(value),
      sorter: (a, b) => a.name.length - b.description.length,
      sortOrder:
        sortedInfo.columnKey === "description" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "Preview Url",
      dataIndex: "previewUrl",
      key: "previewUrl",
      filteredValue: filteredInfo.previewUrl || null,
      onFilter: (value, record) => record.previewUrl.includes(value),
      sorter: (a, b) => a.name.length - b.previewUrl.length,
      sortOrder:
        sortedInfo.columnKey === "previewUrl" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "category",
      dataIndex: "category",
      key: "category",
      filteredValue: filteredInfo.categories || null,
      onFilter: (value, record) => record.category.includes(value),
      sorter: (a, b) => a.category.length - b.category.length,
      sortOrder: sortedInfo.columnKey === "category" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "Revenue",
      dataIndex: "originalPrice",
      key: "type",
      ellipsis: true,
    },
    {
      title: "Payout",
      dataIndex: "discountedPrice",
      key: "value",
      ellipsis: true,
    },

    {
      title: "Payout Type",
      dataIndex: "discountType",
      key: "status",
      ellipsis: true,
    },
    {
      title: "Payout Percentage",
      dataIndex: "discountPercentage",
      key: "commission",
      ellipsis: true,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "commission",
      ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      filteredValue: filteredInfo.address || null,
      onFilter: (value, record) => record.address.includes(value),
      sorter: (a, b) => a.address.length - b.address.length,
      sortOrder: sortedInfo.columnKey === "address" ? sortedInfo.order : null,
      ellipsis: true,
      // fixed: 'right'
    },
  ];
  // Conditionally filter out columns based on user role
  const filteredColumns = columns.filter((column) => {
    // Define logic to determine if the column should be visible for the current user role
    if (currentUserRole === "businessDirector") {
      return true; // Show all columns for admin
    } else if (currentUserRole === "partnershipView") {
      return column.key !== "action"; // Hide column with key 'key2' for regular users
    }
    return true; // Default to showing the column
  });
  // eslint-disable-next-line
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const paginationConfig = {
    pageSize: pageSize, // Number of items to display per page
    total: itemCount || 0, // Total number of items in the data source
    showSizeChanger: true, // Show the "Show X items per page" dropdown
    showQuickJumper: true, // Show the quick jumper input field
    pageSizeOptions: ["10", "20", "30"], // Options for items per page dropdown
  };

  const numberValidation = (rule, value, callback) => {
    const numberPattern = /^[0-9]*$/;
    if (numberPattern.test(value)) {
      if (rule.field === "discountedPrice") {
        const originalPrice = updateForm.getFieldValue("originalPrice");
        const discountedPrice = updateForm.getFieldValue("discountedPrice");
        if (+discountedPrice > +originalPrice) {
          callback("Payout should be less than Revenue");
        } else {
          callback();
        }
      }
      callback();
    } else {
      callback("Please enter only numbers");
    }
  };

  useEffect(() => {
    (async () => {
      const response = await dispatch(getAllOffer(pageSize, currPage));
      setItemCount(response?.itemCount);
    })();
    // eslint-disable-next-line
  }, [pageSize, currPage]);

  useEffect(() => {
    updateForm
      .validateFields({
        validateOnly: true,
      })
      .then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        }
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <>
      {contextHolder}

      <Table
        columns={filteredColumns}
        dataSource={newData}
        onChange={handleChange}
        className="userTable"
        pagination={paginationConfig}
        loading={allOffers?.loading}
        scroll={{ x: 1300 }}
      />
      <Modal
        title=""
        centereds
        form={updateForm}
        open={updateModalOpen}
        className="p-0 modal_parent_wrapper"
        onOk={() => setUpdateModalOpen(false)}
        onCancel={() => setUpdateModalOpen(false)}
        footer={[
          <Button
            form="myUpdateForm"
            key="submit"
            htmlType="submit"
            type="primary"
            className="bg-[#002366] rounded-[50px] min-w-[130px] min-h-[45px]"
            disabled={!submittable}
          >
            Update
          </Button>,
        ]}
      >
        <h2 className="uppercase text-center bg-[#002366] text-white mb-4 p-4 text-[25px] font-medium">
          Update Offer
        </h2>
        <Form
          name="basic"
          layout="vertical"
          id="myUpdateForm"
          form={updateForm}
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={onUpdateFinish}
          className="p-4"
          onFinishFailed={onUpdateFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="image"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              listType="picture-card"
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleChangeP}
              className="text-center"
              name="image"
              beforeUpload={() => false}
            >
              {fileList.length >= 8 ? null : uploadButton}
            </Upload>
          </Form.Item>
          <Modal
            open={previewOpen}
            title={previewTitle}
            footer={null}
            onCancel={handleCancel}
          >
            <img alt="example" style={{ width: "100%" }} src={previewImage} />
          </Modal>
          <div className="flex gap-4 pt-5">
            <Form.Item
              label="Name"
              name="name"
              className="font-semibold mb-3 w-1/2"
              rules={[
                {
                  required: true,
                  message: "Please input your name!",
                },
              ]}
            >
              <Input className=" py-2 rounded-2xl w-full" />
            </Form.Item>
            {/* <Form.Item
                    label="Affiliate Link"
                    name="link"
                    className="font-semibold mb-3 w-1/2"
                    rules={[
                      {
                        required: true,
                        message: "Please input the link!",
                      },
                      { type: "url" },
                    ]}
                  >
                    <Input className=" py-2 rounded-2xl w-full" />
                  </Form.Item> */}
            <Form.Item
              label="Landing Page URL"
              name="landingPageUrl"
              className="font-semibold mb-3 w-1/2"
              rules={[
                {
                  required: true,
                  message: "Please input the Landing Page URL!",
                },
              ]}
            >
              <Input className=" py-2 rounded-2xl w-full" />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              label="Description"
              name="description"
              className="font-semibold mb-3 w-full"
              rules={[
                {
                  required: true,
                  message: "Please input description!",
                },
              ]}
            >
              <TextArea rows={3} />
            </Form.Item>
          </div>
          <div className="flex gap-4 pt-5">
            <Form.Item
              label="Preview URL"
              name="previewUrl"
              className="font-semibold mb-3 w-1/2"
              rules={[
                {
                  required: true,
                  message: "Please input the Preview link!",
                },
                { type: "url" },
              ]}
            >
              <Input className=" py-2 rounded-2xl w-full" />
            </Form.Item>
            <Form.Item
              label="Categories"
              name="category"
              className="font-semibold mb-3 w-1/2"
            >
              <Select>
                <Select.Option value="automotive">Automotive</Select.Option>
                <Select.Option value="baby">Baby</Select.Option>
                <Select.Option value="booksAndMagazines">
                  Books & Magazines
                </Select.Option>
                <Select.Option value="businessOpportunity">
                  Business Opportunity
                </Select.Option>
                <Select.Option value="clothingAndShoes">
                  Clothing & Shoes
                </Select.Option>
                <Select.Option value="computersAndNetworking">
                  Computers & Networking
                </Select.Option>
                <Select.Option value="consumerProducts">
                  Consumer Products
                </Select.Option>
                <Select.Option value="dating">Dating</Select.Option>
                <Select.Option value="education">Education</Select.Option>
                <Select.Option value="electronics">Electronics</Select.Option>
                <Select.Option value="email/zipSubmit">
                  Email / Zip submit
                </Select.Option>
                <Select.Option value="entertainment">
                  Entertainment
                </Select.Option>
                <Select.Option value="financeAndInsurance">
                  Finance & Insurance
                </Select.Option>
                <Select.Option value="gamesAndConsoles">
                  Games & Consoles
                </Select.Option>
                <Select.Option value="giftCardsAndCertificates">
                  Gift Cards & Certificates
                </Select.Option>
                <Select.Option value="healthAndBeauty">
                  Health & Beauty
                </Select.Option>
                <Select.Option value="home">Home</Select.Option>
                <Select.Option value="incentive">Incentive</Select.Option>
                <Select.Option value="internet">Internet</Select.Option>
                <Select.Option value="jewelryAndAccessories">
                  Jewelry & Accessories
                </Select.Option>
                <Select.Option value="kitchenwareAndAppliances">
                  Kitchenware & Appliances
                </Select.Option>
                <Select.Option value="lawnAndGarden">
                  Lawn & Garden
                </Select.Option>
                <Select.Option value="mobile">Mobile</Select.Option>
                <Select.Option value="musicAndVideo">
                  Music & Video
                </Select.Option>
                <Select.Option value="officeProducts">
                  Office Products
                </Select.Option>
                <Select.Option value="other">Other</Select.Option>
                <Select.Option value="personalAndHome">
                  Personal & Home
                </Select.Option>
                <Select.Option value="petSuppliesAndProducts">
                  Pet Supplies & Products
                </Select.Option>
                <Select.Option value="realEstate">Real Estate</Select.Option>
                <Select.Option value="socialMedia">Social Media</Select.Option>
                <Select.Option value="SportsAndOutdoors">
                  Sports & Outdoors
                </Select.Option>
                <Select.Option value="toysAndHobbies">
                  Toys & Hobbies
                </Select.Option>
                <Select.Option value="travel">Travel</Select.Option>
              </Select>
            </Form.Item>
          </div>

          <div className="flex gap-4 pt-5">
            <Form.Item
              label="Revenue"
              name="originalPrice"
              className="font-semibold mb-3 w-1/2"
              rules={[
                {
                  required: true,
                  message: "Please input a number!",
                },
                {
                  validator: numberValidation, // Use the custom validation function
                },
              ]}
            >
              <Input className=" py-2 rounded-2xl w-full" />
            </Form.Item>
            <Form.Item
              label="Payout"
              name="discountedPrice"
              className="font-semibold mb-3 w-1/2"
              rules={[
                {
                  required: true,
                  message: "Please input a number!",
                },
                {
                  validator: numberValidation, // Use the custom validation function
                },
              ]}
            >
              <Input className=" py-2 rounded-2xl w-full" />
            </Form.Item>
          </div>

          <div className="flex gap-4 pt-5">
            <Form.Item
              label="Payout Type"
              name="discountType"
              className="font-semibold mb-3 w-1/2"
            >
              <Select className="w-full" onChange={handleDiscountType}>
                <Select.Option value="fixed">Fixed</Select.Option>
                <Select.Option value="percentage">Percentage</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Value"
              name="discountedValue"
              className="font-semibold mb-3 w-1/2"
            >
              <Input className="py-2 rounded-2xl w-full" />
            </Form.Item>
          </div>

          <div className="flex justify-center"></div>
        </Form>
      </Modal>
    </>
  );
};

export default AllAdminOffers;
