import axios from "../../utils/interceptor/index";
import { GET_ALL_AUTHORITIES_URL } from "../../utils/api/urls";
import { AUTHORITIES_FAILURE, AUTHORITIES_REQUEST } from "../constants";


export const getAllAuthorities = (page = 10, currPage = 1) => {
    return async (dispatch) => {
      dispatch({ type: AUTHORITIES_REQUEST });
      try {
        const headers = {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          // Add any other headers if needed
        };
        const response = await axios.get(
          `${GET_ALL_AUTHORITIES_URL}`,
          { headers }
        );

        if (response?.data?.data) {
          return response?.data?.data
        }
      } catch (error) {
        dispatch({ type: AUTHORITIES_FAILURE, payload: { error } });
      }
    };
  };