import React, { useEffect, useState } from "react";
import { routes } from "./routes";
import { Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
import { Provider, useSelector } from "react-redux";
import store from "./redux/store";
import IdleTimeComponent from "./idle";
import { io } from "socket.io-client";
import Protected from "./modules/auth/protected/Protected";
import AdminDashboard from "./modules/auth0/Dashboard/adminDashboard";
// const socket = io("https://socialprofit.io", { path: "/sockets" });

const App = () => {
  const navigate = useNavigate();
  const location = useLocation()
  const profileId = useSelector((store) => store.profileData.data?._id);
  const [socketResponse,setSocketResponse] = useState({})
  // const [socket,setSocket] = useState([])
  

  useEffect(() => {
    const checkRoute = location.pathname.startsWith('/resetPassword')
    if(location.pathname && !checkRoute)
    if (!localStorage.getItem("accessToken")) {
      navigate("/login");
    } 
    // eslint-disable-next-line
  }, []);


  // useEffect(() => {
  //   if (profileId && socket) {
  //     socket.on(profileId, (data) => {
  //       console.log('data inside admin useEffect', data)
  //       setSocketResponse(data)
  //       // setNotificationData([data,...notificationData])
  //       // setNotificationLiveData(data?.message)
  //     });
  //   }
  
  //   // Cleanup the socket event listener when the component unmounts
  //   return () => {
  //     if (socket) {
  //       socket.off(profileId);
  //     }
  //   };
  // }, [socket, profileId]);







  // useEffect(() => {
  //   console.log("idddddd")
    
    
  //     socket.on(profileId, (data) => {
  //       console.log('data inside admin useEffect', data)
  //       // setNotificationData([data,...notificationData])
  //       // setNotificationLiveData(data?.message)
  //     });

    
  // }, [socket,profileId]);
  return (
    <div>
        <IdleTimeComponent/>
        <Routes>
          {routes.map((route) => {
            if(route.path==='/dashboard'){
              return(
                <Route
                exact
                path={"/dashboard"}
                element={<Protected
                  Component={AdminDashboard}
                  key={"adminDashboard"}
                  allowedRoles={['businessDirector', 'businessManager' , 'businessPartner','partnershipView']}
                  socketResponse={socketResponse}
                />}
                key={"dashboard"}
                
              />

              )
            }else{
              return (
                <Route
                  exact
                  path={route.path}
                  element={route.component}
                  key={route.key}
                  
                />
              );

            }
          })}
        </Routes>
    </div>
  );
};

export default App;
