import React, { useState } from "react";
import { Button, Checkbox, Form, Input, Spin, notification } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ArrowRightOutlined, LoadingOutlined,ArrowLeftOutlined } from "@ant-design/icons";
import { loginOption } from "../../../redux/actions/login";

const SubmitButton = ({ form, spinner }) => {
  const [submittable, setSubmittable] = React.useState(false);

  // Watch all values
  const values = Form.useWatch([], form);
  React.useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        }
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 22,
      }}
      spin
    />
  );
  return (
    <Button
      type="primary"
      htmlType="submit"
      className="bg-[#002366] px-10 min-h-[40px] flex items-center justify-center rounded-2xl capitalize font-semibold text-base"
      disabled={!submittable}
    >
      submit
      {spinner ? (
        <Spin indicator={antIcon} className="ml-1" />
      ) : (
        <ArrowRightOutlined className="" />
      )}
    </Button>
  );
};

const Login = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    const { email, password } = values;
    const result = await dispatch(loginOption({ email, password }));
    if (result?.data) {
      setLoading(false);
      form.resetFields();
      localStorage.setItem("accessToken", result?.data?.accessToken);

      if (result?.data?.role === "businessDirector") {
        localStorage.setItem("profile", "businessDirector");
        navigate("/dashboard");
      } else if (result?.data?.role === "businessManager") {
        localStorage.setItem("profile", "businessManager");
        navigate("/dashboard");
      }else if (result?.data?.role === "businessPartner") {
        localStorage.setItem("profile", "businessPartner");
        navigate("/dashboard");
      } else if (result?.data?.role === "premiumAffiliate") {
        localStorage.setItem("profile", "premiumAffiliate");
        navigate("/dashboard");
      } else if (result?.data?.role === "partnershipView") {
        localStorage.setItem("profile", "partnershipView");
        navigate("/dashboard");
      } else {
        localStorage.setItem("profile", "standardAffiliate");
        navigate("/affiliate/dashboard");
      }

      api.success({
        message: result?.message || "Success",
        duration: 5,
      });
    } else {
      setLoading(false);
      api.error({
        message: result?.message || "Something went wrong",
        duration: 5,
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      {contextHolder}
      <div className="flex md:flex-row flex-col relative">
        <div className="md:w-[45%] w-full bg-[url('/public/images/backJp.jpg')] bg-cover bg-no-repeat  gap-4 h-screen pt-28 md:block hidden">
          <Link to={'https://socialprofit.io/'}  className="absolute top-3 left-2 text-white font-light flex items-center "> <ArrowLeftOutlined className="mr-2"/> Back to home page</Link>
          <div className="text-white w-[70%] mx-auto">

            <h1 className="text-6xl mt-4 font-semibold pb-6 text-center">
              Welcome to our Login Page
            </h1>
            <p className="font-semibold text-base text-[#CBD5E1] text-center">
              Log in to SocialProfit, where social change meets affiliate marketing success
            </p>
          </div>
        </div>
        <div className="md:w-[55%] w-full bg-[#F8FAFC] mx-auto  flex md:gap-4 h-screen items-center showOnMobile">
          <div className="md:w-3/5 w-full sm:ml-[103px] sm:mr-0 mx-5  sm:min-h-[511px] rounded-md flex flex-col justify-center">
            <h1 className="text-5xl font-bold mt-0 mb-1 pb-4">Login</h1>
            <p className="text-base mb-2 text-[#656b76] pb-5">
              Enter your email and password to sign in
            </p>
            <Form
              form={form}
              name="basic"
              layout="vertical"
              style={{ maxWidth: 600 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              className="modal_parent_wrapper"
            >
              <Form.Item
                label="Email"
                name="email"
                className="font-semibold mb-3"
                rules={[
                  { required: true, message: "Please input your username!" },
                ]}
              >
                <Input className="w-full py-2 rounded-2xl" />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                className="font-semibold mb-3"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input.Password className="py-2 rounded-2xl" />
              </Form.Item>
              <div className="pt-6 flex md:flex-row flex-col md:gap-0 gap-3 w-full ">
                <Form.Item
                  name="remember"
                  valuePropName="checked"
                  noStyle
                  className="w-1/2"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error("Should accept agreement")
                            ),
                    },
                  ]}
                >
                  <Checkbox>I Agree the Terms & Conditions</Checkbox>
                </Form.Item>

               <Link
                  className="login-form-forgot float-right  text-[#002366] w-1/2 md:text-right"
                  to="/forgotPassword"
                >
                  Forgot password?
                </Link>
              
              </div>
              <Form.Item className="pt-5 ">
                <SubmitButton form={form} spinner={loading} />
                <p className="mt-4 text-[#64748B]">
                  {" "}
                  Don't have an account?{" "}
                  <Link to="/signup">
                    <strong className="text-[#002366]">Sign Up</strong>
                  </Link>
                </p>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
