import axios from "../../utils/interceptor/index";
import {
  CREATE_NEWS_SUCCESS,
  DELETE_NEWS_SUCCESS,
  GET_ALL_NEWS_SUCCESS,
  NEWS_FAILURE,
  NEWS_REQUEST,
  SEARCHED_NEWS_SUCCESS,
  UPDATE_NEWS_SUCCESS,
} from "../constants";
import {
  ADD_NEWS_URL,
  DELETE_NEWS_URL,
  GET_ALL_REQUESTED_NEWS_URL,
  UPDATE_NEWS_URl,
} from "../../utils/api/urls";

export const addNews = (payload) => {
  return async (dispatch) => {
    dispatch({ type: NEWS_REQUEST });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Add any other headers if needed
      };
      const response = await axios.post(ADD_NEWS_URL, payload, {
        headers,
      });
      if (response?.data) {
        dispatch({ type: CREATE_NEWS_SUCCESS, payload: response?.data?.data });
        return response?.data;
      }
    } catch (error) {
      dispatch({ type: NEWS_FAILURE, payload: { error } });
      return error?.response?.data;
    }
  };
};

export const getAllNews = (page = 10, currPage = 1) => {
  return async (dispatch) => {
    dispatch({ type: NEWS_REQUEST });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Add any other headers if needed
      };
      const response = await axios.get(
        `${GET_ALL_REQUESTED_NEWS_URL}?limit=${page}&pageNo=${currPage}`,
        { headers }
      );
      if (response?.data) {
        dispatch({
          type: GET_ALL_NEWS_SUCCESS,
          payload: response?.data?.data?.paginatedData,
        });
        return response?.data?.data;
      }
    } catch (error) {
      dispatch({ type: NEWS_FAILURE, payload: { error } });
    }
  };
};

export const deleteNews = (id) => {
  return async (dispatch) => {
    dispatch({ type: NEWS_REQUEST });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Add any other headers if needed
      };
      const result = await axios.delete(`${DELETE_NEWS_URL}/${id}`, {
        headers,
      });
      dispatch({
        type: DELETE_NEWS_SUCCESS,
        payload: result?.data?.data || {},
      });
      return result?.data;
    } catch (err) {
      dispatch({ type: NEWS_FAILURE, payload: err });
      return err?.response?.data;
    }
  };
};

export const updateNews = (id, payload) => {
  return async (dispatch) => {
    dispatch({ type: NEWS_REQUEST });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Add any other headers if needed
      };
      const result = await axios.put(`${UPDATE_NEWS_URl}/${id}`, payload, {
        headers,
      });

      dispatch({
        type: UPDATE_NEWS_SUCCESS,
        payload: result?.data?.data || {},
      });
      return result?.data;
    } catch (err) {
      dispatch({ type: NEWS_FAILURE, payload: err });
      return err?.response?.data;
    }
  };
};

export const searchNews = (searchParams) => {
  return async (dispatch) => {
    dispatch({ type: NEWS_REQUEST });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Add any other headers if needed
      };
      const response = await axios.get(
        `${GET_ALL_REQUESTED_NEWS_URL}?search=${searchParams}`,

        {
          headers,
        }
      );

      if (response?.data) {
        dispatch({
          type: SEARCHED_NEWS_SUCCESS,
          payload: response?.data?.data?.paginatedData || {},
        });
        return response?.data?.data || {};
      }
    } catch (error) {
      dispatch({ type: NEWS_FAILURE, payload: { error } });
    }
  };
};
