import axios from "../../utils/interceptor/index";
import { ADD_USER_URL,  DELETE_USER_URL, GET_ALL_USER_URL, UPDATE_USER_URL } from "../../utils/api/urls";
import {
  USER_FAILURE,
  USER_REQUEST,
  CREATE_USER_SUCCESS,
  GET_ALL_USER_SUCCESS,
  UPDATE_USER_SUCCESS,
  SEARCHED_USER_SUCCESS,
  DELETE_USER_SUCCESS,
} from "../constants";

export const addUser = (payload) => {
  return async (dispatch) => {
    dispatch({ type: USER_REQUEST });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Add any other headers if needed
      };
      const response = await axios.post(
        ADD_USER_URL,
        payload,

        {
          headers,
        }
      );
      if (response?.data) {
        dispatch({
          type: CREATE_USER_SUCCESS,
          payload: response?.data?.data || {},
        });
        return response?.data || {};
      }
    } catch (error) {
      dispatch({ type: USER_FAILURE, payload: { error } });
      return error?.response?.data;
    }
  };
};

export const searchUser = (searchParams) => {
  return async (dispatch) => {
    dispatch({ type: USER_REQUEST });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Add any other headers if needed
      };
      const response = await axios.get(
        `${GET_ALL_USER_URL}?search=${searchParams}`,

        {
          headers,
        }
      );

      if (response?.data) {
        dispatch({
          type: SEARCHED_USER_SUCCESS,
          payload: response?.data?.data?.paginatedData || {},
        });
        return response?.data?.data || {};
      }
    } catch (error) {
      dispatch({ type: USER_FAILURE, payload: { error } });
    }
  };
};

export const updateUser = (id, payload) => {
  return async (dispatch) => {
    dispatch({ type: USER_REQUEST });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Add any other headers if needed
      };
      const result = await axios.put(
        `${UPDATE_USER_URL}/${id}`,
        payload,
        { headers }
      );

      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: result?.data?.data || {},
      });
      return result?.data;
    } catch (err) {
      dispatch({ type: USER_FAILURE, payload: err });
      return err?.response?.data;
    }
  };
};

export const deleteUser = (id) => {
  return async (dispatch) => {
    dispatch({ type: USER_REQUEST });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Add any other headers if needed
      };
      const result = await axios.delete(
        `${DELETE_USER_URL}/${id}`,
        { headers }
      );
      dispatch({
        type: DELETE_USER_SUCCESS,
        payload: result?.data?.data || {},
      });
      return result?.data;
    } catch (err) {
      dispatch({ type: USER_FAILURE, payload: err });
      return err?.response?.data;
    }
  };
};

export const getAllUser = (page = 10, currPage = 1) => {
  return async (dispatch) => {
    dispatch({ type: USER_REQUEST });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Add any other headers if needed
      };
      const response = await axios.get(
        `${GET_ALL_USER_URL}?limit=${page}&pageNo=${currPage}`,
        { headers }
      );
      if (response?.data) {
        dispatch({
          type: GET_ALL_USER_SUCCESS,
          payload: response?.data?.data?.paginatedData || [],
        });
        return response?.data?.data || [];
      }
    } catch (error) {
      dispatch({ type: USER_FAILURE, payload: { error } });
    }
  };
};

export const approveDisapproveUser = (id, key, role) => {
  return async (dispatch) => {
    dispatch({ type: USER_REQUEST });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Add any other headers if needed
      };
      const response = await axios.put(
        `${UPDATE_USER_URL}/${id}`,
        {
          status: key,
          role: role,
        },
        {
          headers,
        }
      );

      if (response?.data) {
        dispatch({
          type: UPDATE_USER_SUCCESS,
          payload: response?.data?.data || {},
        });
        return response?.data;
      }
    } catch (error) {
      dispatch({
        type: USER_FAILURE,
        payload: error,
      });
      return error?.response?.data;
    }
  };
};
