import React from "react";
import { Table } from "antd";

const SmartLinkTable = () => {
  const columns = [
    {
      title: "Smart Link",
      dataIndex: "smartLink",
    },
    {
      title: "Allowed Traffic Types",
      dataIndex: "allowedTraffic",
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 3,
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      sorter: {
        compare: (a, b) => a.math - b.math,
        multiple: 2,
      },
    },
    {
      title: "Tracking Link",
      dataIndex: "trackingLink",
      sorter: {
        compare: (a, b) => a.english - b.english,
        multiple: 1,
      },
    },
  ];

  const data = [];
  const NoData = () => (
    <div className="font-Mulish font-semibold text-lg text-black">
      No Data Found
    </div>
  );


  return (
    <div className="bg-white">
      <div>
        <Table
          columns={columns}
          dataSource={data}
          locale={{ emptyText: <NoData /> }}
        />
      </div>
    </div>
  );
};

export default SmartLinkTable;
