import React, { useEffect, useState } from "react";

import {
  Input,
  Table,
  notification,
  Tooltip,
  Select,
  Modal,
  Form,
  Upload,
} from "antd";
import { CopyOutlined, PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import * as moment from "moment";
import {
  getAllOffer,
  requestOffer,
  searchOffer,
  searchOfferByCategories,
} from "../../../../redux/actions/offer";
import { CopyToClipboard } from "react-copy-to-clipboard";
import emptyImage from "../../../../common/assets/images/emptyImage.jpg";
import TextArea from "antd/es/input/TextArea";
import { useNavigate } from "react-router-dom";

const { Search } = Input;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const convertImageUrlToObject = async (imageUrl) => {
  const response = await fetch(imageUrl);
  const blob = await response.blob();

  // Create a File object with the Blob data
  const file = new File([blob], "image.jpg", { type: "image/jpeg" });
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      const fileObject = {
        uid: Date.now().toString(), // Generate a unique ID
        size: img.size, // You can set the actual size here if available
        name: imageUrl.split("/").pop(), // Extract the file name from the URL
        url: imageUrl,
        type: "image/jpeg", // Set the appropriate content type
        lastModified: Date.now(), // Set the current timestamp as last modified
        lastModifiedDate: new Date(),
        status: "done", // You can set the initial status here
        percent: 100,
        thumbUrl: imageUrl, // You can set a thumbnail URL if needed
        originFileObj: file, // You can set the original file object here if available
        response: null,
        error: null,
        linkProps: null,
        xhr: null,
        preview: imageUrl, // Set the preview URL
      };

      resolve(fileObject);
      return fileObject;
    };

    img.onerror = (error) => {
      reject(error);
    };
  });
};

const AllOfferTable = () => {
  const navigate = useNavigate()
  const offerData = useSelector((state) => state.offers);
  const [itemCount, setItemCount] = useState(offerData?.data?.length);
  const [pageSize, setPageSize] = useState(10);
  const [currPage, setCurrPage] = useState(1);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [api, contextHolder] = notification.useNotification();
  const [filterBySearch, setFilterBySearch] = useState("");
  const [filterByCategory, setFilterByCategory] = useState("");
  const [updateModalOpen, setUpdateModalOpen] = useState(false); // eslint-disable-next-line

  const [updateForm] = Form.useForm();

  const dispatch = useDispatch();

  const onSearch = async (e) => {
    setFilterBySearch(e.target.value);
    if (!filterByCategory) {
      await dispatch(searchOffer(e.target.value));
    } else {
      await dispatch(searchOfferByCategories(filterByCategory, e.target.value));
    }
  };

  const otherCopy = (e) => {
    
    api.success({
      message: "Copied",
      duration: 3,
    });
  };

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  // eslint-disable-next-line
  const handleChangeP = ({ fileList: newFileList }) => setFileList(newFileList);
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const columns = [
    {
      title: "Offer",
      dataIndex: "offer",
      fixed: "left",
    },
    {
      title: "Link",
      dataIndex: "link",
      ket: "link",
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 3,
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      ket: "description",
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 3,
      },
    },
    {
      title: "Category",
      dataIndex: "category",
      ket: "category",
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 3,
      },
    },
    // {
    //   title: "Original Price",
    //   dataIndex: "originalPrice",
    //   key: "type",
    //   ellipsis: true,
    // },
    {
      title: "Affiliate Commission",
      dataIndex: "discountedPrice",
      key: "value",
      ellipsis: true,
    },
    // {
    //   title: "Discount Type",
    //   dataIndex: "discountType",
    //   sorter: {
    //     compare: (a, b) => a.english - b.english,
    //     multiple: 1,
    //   },
    // },
    // {
    //   title: "Discount Percentage/ Value",
    //   dataIndex: "discountPercentage",
    //   sorter: {
    //     compare: (a, b) => a.english - b.english,
    //     multiple: 1,
    //   },
    // },
    {
      title: "Created At",
      dataIndex: "createdAt",
      sorter: {
        compare: (a, b) => a.english - b.english,
        multiple: 1,
      },
    },

    {
      title: "Action",
      dataIndex: "action",
    },
  ];

  const handleOfferRequest = async (id,event) => {
    event.stopPropagation();
    const response = await dispatch(requestOffer(id));
    if (response?.data) {
      api.success({
        message: response?.message || "Success",
        duration: 3,
      });
    } else {
      api.error({
        message: response?.message || "Something went wrong",
        duration: 3,
      });
    }
  };
  const newData = offerData?.data?.map((item) => {
    return {
      id: item._id,
      offer: (
        <div className="flex items-center gap-5">
          {/* <Rate count={1} className="affiliateTableStar" /> */}
          <img
            src={item.image || emptyImage}
            alt=""
            className="max-w-none"
            width={40}
            height={40}
          />
          <p className="font-Mulish font-semibold text-base max-w-[80px]">
            {item.name}
          </p>
        </div>
      ),
      link: (
        <>
          {item.landingPageUrl ? (
            <>
              <div className="flex gap-1 affilateOfferLinkWrapper" >
                <Tooltip title={item.landingPageUrl}>
                  <span className="affilateOfferLink">
                    {item.landingPageUrl}
                  </span>
                </Tooltip>
                <CopyToClipboard onCopy={otherCopy} text={item.landingPageUrl} >
                  <button onClick={(event)=> event.stopPropagation()}>
                    <CopyOutlined />
                  </button>
                </CopyToClipboard>
              </div>
            </>
          ) : (
            ""
          )}
        </>
      ),
      description: (
        <Tooltip title={item.description}>
          <p className="truncate">{item.description}</p>
        </Tooltip>
      ),
      category: item.category,
      originalPrice: item.originalPrice,
      discountedPrice: item.discountedPrice,
      discountPercentage: item.discountedValue,
      discountType: <p className="capitalize">{item.discountType}</p>,
      createdAt: moment(item.createdAt).format("DD-MM-YYYY"),
      action: (
        <>
          {item.hasRequested ? (
            <button
              className="bg-[#002366] opacity-75 rounded-full text-white py-2 px-5"
              disabled={item.hasRequested}
              onClick={(event)=> event.stopPropagation()}
            >
              Requested
            </button>
          ) : (
            <button
              className="bg-[#002366] rounded-full text-white py-2 px-5"
              
              onClick={(event) => handleOfferRequest(item._id,event)}
            >
              Request
            </button>
          )}
        </>
      ),
    };
  });

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const paginationConfig = {
    pageSize: pageSize, // Number of items to display per page
    total: itemCount || 0, // Total number of items in the data source
    showSizeChanger: true, // Show the "Show X items per page" dropdown
    showQuickJumper: true, // Show the quick jumper input field
    pageSizeOptions: ["10", "20", "30"], // Options for items per page dropdown
  };

  const onChangeTable = (pagination, filters, sorter, extra) => {
    setCurrPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const handleCategoriesFilter = async (e) => {
    setFilterByCategory(e);

    await dispatch(searchOfferByCategories(e, filterBySearch));
  };

  const handleRowClick = (record) => {
    
    
    const newArr = offerData?.data?.filter((item) => item._id === record);
    navigate(`/affiliate/offers/${record}`,{
      state:newArr
    })
    newArr.length > 0 &&
    //eslint-disable-next-line
      newArr.map((item) => {
        if (item.image && item.image.length > 0) {
          // If a new image is selected, use it
          // formData.append("image", image[0].originFileObj);
          convertImageUrlToObject(item.image)
            .then((result) => {
              setUpdateModalOpen(true);
              updateForm.setFieldsValue({
                name: item.name,
                image: [result],
                landingPageUrl: item.landingPageUrl,
                description: item.description,
                previewUrl: item.previewUrl,
                category: item.category,
                originalPrice: item.originalPrice,
                discountedPrice: item.discountedPrice,
                discountPercentage: item.discountPercentage,
                discountType: item.discountType,
                discountedValue: item.discountedValue,
              });
            })
            .catch((error) => {
              console.error("Error converting image:", error);
            });
        } else {
          setUpdateModalOpen(true);
          updateForm.setFieldsValue({
            name: item.name,
            landingPageUrl: item.landingPageUrl,
            description: item.description,
            previewUrl: item.previewUrl,
            category: item.category,
            originalPrice: item.originalPrice,
            discountedPrice: item.discountedPrice,
            discountPercentage: item.discountPercentage,
            discountType: item.discountType,
            discountedValue: item.discountedValue,
          });
        }
      });

    setUpdateModalOpen(true);
    // Handle row click event, e.g., navigate to a details page
  };

  const rowProps = (record) => {
    return {
      onClick: () => handleRowClick(record.id),
    };
  };

  useEffect(() => {
    (async () => {
      const response = await dispatch(getAllOffer(pageSize, currPage));
      setItemCount(response?.itemCount);
    })();
    // eslint-disable-next-line
  }, [pageSize, currPage]);

  return (
    <div className="bg-white">
      {contextHolder}
      <div className=" flex gap-6 px-5 pb-5 pt-3 ">
        <Search
          placeholder="input search text"
          // onSearch={onSearch}
          onChange={onSearch}
          enterButton
          className="max-w-[290px] affiliateOfferSearch"
        />

        <Select
          className="min-w-[230px]"
          placeholder="Filter by Categories"
          onChange={handleCategoriesFilter}
        >
          <Select.Option value={null}>Select a Category</Select.Option>
          <Select.Option value="automotive">Automotive</Select.Option>
          <Select.Option value="baby">Baby</Select.Option>
          <Select.Option value="booksAndMagazines">
            Books & Magazines
          </Select.Option>
          <Select.Option value="businessOpportunity">
            Business Opportunity
          </Select.Option>
          <Select.Option value="clothingAndShoes">
            Clothing & Shoes
          </Select.Option>
          <Select.Option value="computersAndNetworking">
            Computers & Networking
          </Select.Option>
          <Select.Option value="consumerProducts">
            Consumer Products
          </Select.Option>
          <Select.Option value="dating">Dating</Select.Option>
          <Select.Option value="education">Education</Select.Option>
          <Select.Option value="electronics">Electronics</Select.Option>
          <Select.Option value="email/zipSubmit">
            Email / Zip submit
          </Select.Option>
          <Select.Option value="entertainment">Entertainment</Select.Option>
          <Select.Option value="financeAndInsurance">
            Finance & Insurance
          </Select.Option>
          <Select.Option value="gamesAndConsoles">
            Games & Consoles
          </Select.Option>
          <Select.Option value="giftCardsAndCertificates">
            Gift Cards & Certificates
          </Select.Option>
          <Select.Option value="healthAndBeauty">Health & Beauty</Select.Option>
          <Select.Option value="home">Home</Select.Option>
          <Select.Option value="incentive">Incentive</Select.Option>
          <Select.Option value="internet">Internet</Select.Option>
          <Select.Option value="jewelryAndAccessories">
            Jewelry & Accessories
          </Select.Option>
          <Select.Option value="kitchenwareAndAppliances">
            Kitchenware & Appliances
          </Select.Option>
          <Select.Option value="lawnAndGarden">Lawn & Garden</Select.Option>
          <Select.Option value="mobile">Mobile</Select.Option>
          <Select.Option value="musicAndVideo">Music & Video</Select.Option>
          <Select.Option value="officeProducts">Office Products</Select.Option>
          <Select.Option value="other">Other</Select.Option>
          <Select.Option value="personalAndHome">Personal & Home</Select.Option>
          <Select.Option value="petSuppliesAndProducts">
            Pet Supplies & Products
          </Select.Option>
          <Select.Option value="realEstate">Real Estate</Select.Option>
          <Select.Option value="socialMedia">Social Media</Select.Option>
          <Select.Option value="SportsAndOutdoors">
            Sports & Outdoors
          </Select.Option>
          <Select.Option value="toysAndHobbies">Toys & Hobbies</Select.Option>
          <Select.Option value="travel">Travel</Select.Option>
        </Select>
      </div>

      <Modal
        title=""
        centereds
        form={updateForm}
        open={updateModalOpen}
        className="p-0 modal_parent_wrapper"
        onOk={() => setUpdateModalOpen(false)}
        onCancel={() => setUpdateModalOpen(false)}
        footer={false}
        width={800}
      >
        <h2 className="uppercase text-center bg-[#002366] text-white mb-4 p-4 text-[25px] font-medium">
          View Offer
        </h2>
        <Form
          name="basic"
          layout="vertical"
          disabled
          id="myUpdateForm"
          form={updateForm}
          initialValues={{ remember: true }}
          className="p-4 light_input_fields"
          autoComplete="off"
        >
          <Form.Item
            name="image"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              listType="picture-card"
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleChangeP}
              className="text-center"
              name="image"
              beforeUpload={() => false}
            >
              {fileList.length >= 8 ? null : uploadButton}
            </Upload>
          </Form.Item>
          <Modal
            open={previewOpen}
            title={previewTitle}
            footer={null}
            onCancel={handleCancel}
          >
            <img alt="example" style={{ width: "100%" }} src={previewImage} />
          </Modal>
          <div className="flex gap-4 pt-5">
            <Form.Item
              label="Name"
              name="name"
              className="font-semibold mb-3 w-1/2"
            >
              <Input className=" py-2 rounded-2xl w-full" />
            </Form.Item>
            {/* <Form.Item
                    label="Affiliate Link"
                    name="link"
                    className="font-semibold mb-3 w-1/2"
                    rules={[
                      {
                        required: true,
                        message: "Please input the link!",
                      },
                      { type: "url" },
                    ]}
                  >
                    <Input className=" py-2 rounded-2xl w-full" />
                  </Form.Item> */}
            <Form.Item
              label="Landing Page URL"
              name="landingPageUrl"
              className="font-semibold mb-3 w-1/2"
              rules={[
                {
                  required: true,
                  message: "Please input the Landing Page URL!",
                },
              ]}
            >
              <Input className=" py-2 rounded-2xl w-full" />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              label="Description"
              name="description"
              className="font-semibold mb-3 w-full"
              rules={[
                {
                  required: true,
                  message: "Please input description!",
                },
              ]}
            >
              <TextArea rows={3} />
            </Form.Item>
          </div>
          <div className="flex gap-4 pt-5">
            <Form.Item
              label="Preview URL"
              name="previewUrl"
              className="font-semibold mb-3 w-1/2"
              rules={[
                {
                  required: true,
                  message: "Please input the Preview link!",
                },
                { type: "url" },
              ]}
            >
              <Input className=" py-2 rounded-2xl w-full" />
            </Form.Item>
            <Form.Item
              label="Categories"
              name="category"
              className="font-semibold mb-3 w-1/2"
            >
              <Select>
                <Select.Option value="automotive">Automotive</Select.Option>
                <Select.Option value="baby">Baby</Select.Option>
                <Select.Option value="booksAndMagazines">
                  Books & Magazines
                </Select.Option>
                <Select.Option value="businessOpportunity">
                  Business Opportunity
                </Select.Option>
                <Select.Option value="clothingAndShoes">
                  Clothing & Shoes
                </Select.Option>
                <Select.Option value="computersAndNetworking">
                  Computers & Networking
                </Select.Option>
                <Select.Option value="consumerProducts">
                  Consumer Products
                </Select.Option>
                <Select.Option value="dating">Dating</Select.Option>
                <Select.Option value="education">Education</Select.Option>
                <Select.Option value="electronics">Electronics</Select.Option>
                <Select.Option value="email/zipSubmit">
                  Email / Zip submit
                </Select.Option>
                <Select.Option value="entertainment">
                  Entertainment
                </Select.Option>
                <Select.Option value="financeAndInsurance">
                  Finance & Insurance
                </Select.Option>
                <Select.Option value="gamesAndConsoles">
                  Games & Consoles
                </Select.Option>
                <Select.Option value="giftCardsAndCertificates">
                  Gift Cards & Certificates
                </Select.Option>
                <Select.Option value="healthAndBeauty">
                  Health & Beauty
                </Select.Option>
                <Select.Option value="home">Home</Select.Option>
                <Select.Option value="incentive">Incentive</Select.Option>
                <Select.Option value="internet">Internet</Select.Option>
                <Select.Option value="jewelryAndAccessories">
                  Jewelry & Accessories
                </Select.Option>
                <Select.Option value="kitchenwareAndAppliances">
                  Kitchenware & Appliances
                </Select.Option>
                <Select.Option value="lawnAndGarden">
                  Lawn & Garden
                </Select.Option>
                <Select.Option value="mobile">Mobile</Select.Option>
                <Select.Option value="musicAndVideo">
                  Music & Video
                </Select.Option>
                <Select.Option value="officeProducts">
                  Office Products
                </Select.Option>
                <Select.Option value="other">Other</Select.Option>
                <Select.Option value="personalAndHome">
                  Personal & Home
                </Select.Option>
                <Select.Option value="petSuppliesAndProducts">
                  Pet Supplies & Products
                </Select.Option>
                <Select.Option value="realEstate">Real Estate</Select.Option>
                <Select.Option value="socialMedia">Social Media</Select.Option>
                <Select.Option value="SportsAndOutdoors">
                  Sports & Outdoors
                </Select.Option>
                <Select.Option value="toysAndHobbies">
                  Toys & Hobbies
                </Select.Option>
                <Select.Option value="travel">Travel</Select.Option>
              </Select>
            </Form.Item>
          </div>

          <div className="flex gap-4 pt-5">
            <Form.Item
              label="Revenue"
              name="originalPrice"
              className="font-semibold mb-3 w-1/2"
            >
              <Input className=" py-2 rounded-2xl w-full" />
            </Form.Item>
            <Form.Item
              label="Payout"
              name="discountedPrice"
              className="font-semibold mb-3 w-1/2"
            >
              <Input className=" py-2 rounded-2xl w-full" />
            </Form.Item>
          </div>

          <div className="flex gap-4 pt-5">
            <Form.Item
              label="Payout Type"
              name="discountType"
              className="font-semibold mb-3 w-1/2"
            >
              <Select className="w-full">
                <Select.Option value="fixed">Fixed</Select.Option>
                <Select.Option value="percentage">Percentage</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Value"
              name="discountedValue"
              className="font-semibold mb-3 w-1/2"
            >
              <Input className="py-2 rounded-2xl w-full" />
            </Form.Item>
          </div>

          <div className="flex justify-center"></div>
        </Form>
      </Modal>

      <div className="px-5">
        <p className="float-right pb-2 text-base font-medium">
          {offerData?.data?.length || 0} Result
        </p>
        <Table
          columns={columns}
          dataSource={newData}
          onChange={onChangeTable}
          className="userTable rowPointer"
          pagination={paginationConfig}
          loading={offerData?.loading}
          scroll={{ x: 1600 }}
          onRow={rowProps}
        />
      </div>
    </div>
  );
};

export default AllOfferTable;
