import React, { useState } from "react";
import Layout from "../../../../common/layout";
import facebook from "../../../../common/assets/images/facebook.svg";
import totalRevenueIcon from "../../../../common/assets/images/totalRevenueIcon.svg"; 
import totalAffiliateIcon from "../../../../common/assets/images/totalAffiliateIcon.svg";
import adminFeeIcon from "../../../../common/assets/images/adminFeeIcon.svg";
import { Button, Dropdown, Modal, Space, Table } from "antd";
import {
  CaretDownOutlined,
  EyeOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import BarChart from "../../chart/BarChart";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
ChartJS.register(ArcElement, Tooltip, Legend);

const Traffic = () => {
  const [modalOpen, setModalOpen] = useState(false);
  
  const NoData = () => (
    <div className="font-Mulish font-semibold text-lg text-black">
      No Data Found
    </div>
  );



  const handleMenuClick = (item) => {
    const { key } = item;
    if (key === "1") {
      setModalOpen(true);
    }
  };

  const items = [
    {
      label: "View Traffic",
      key: "1",
      icon: <EyeOutlined />,
    },
    {
      label: "Delete Traffic",
      key: "3",
      icon: <DeleteOutlined />,
    },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const columns = [
    {
      title: "User name",
      dataIndex: "userName",
    },
    {
      title: "Affiliate name",
      dataIndex: "affiliateName",
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 3,
      },
    },
    {
      title: "Traffic source",
      dataIndex: "trafficSource",
      sorter: {
        compare: (a, b) => a.math - b.math,
        multiple: 2,
      },
    },
    {
      title: "Traffic",
      dataIndex: "traffic",
      sorter: {
        compare: (a, b) => a.english - b.english,
        multiple: 1,
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      sorter: {
        compare: (a, b) => a.english - b.english,
        multiple: 1,
      },
    },
  ];

  const trafficTableData = [
    {
      userName: "Phasellus",
      affiliateName: "Maecenas tempus",
      trafficSource: "Venenatis",
      traffic: "Venenatis",
      action: (
        <Dropdown
          menu={menuProps}
          className="rounded-2xl bg-[#E3E0E0] border-[#B6B5B5]"
        >
          <Button>
            <Space>
              Action
              <CaretDownOutlined />
            </Space>
          </Button>
        </Dropdown>
      ),
    },
  ];

  const options = {
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const total = dataset.data.reduce(
            (previousValue, currentValue) => previousValue + currentValue
          );
          const currentValue = dataset.data[tooltipItem.index];
          const percentage = ((currentValue / total) * 100).toFixed(2);
          return `${
            data.labels[tooltipItem.index]
          }: ${currentValue} (${percentage}%)`;
        },
      },
    },
  };

  const pieData = {
    labels: ["Linkedin", "Instagram", "Twitter", "Facebook"],
    datasets: [
      {
        label: "# of Votes",
        data: [8, 10, 3, 5],
        backgroundColor: ["#D81919", "#54A36A", "#F8B73E", "#3D5789"],
        borderColor: ["#D81919", "#54A36A", "#F8B73E", "#3D5789"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <Layout>
      <div>
        <h3 className="mt-3 mb-5 font-Mulish font-semibold text-2xl">
          Traffics
        </h3>
        <div className="relative">
          <Table
            columns={columns}
            dataSource={trafficTableData}
            locale={{ emptyText: <NoData /> }}
            className="userTable relative"
          />
          <Modal
            title=""
            open={modalOpen}
            className="p-0 modal_parent_wrapper fullwidth_modal_wrapper modal_closeIcon_wrapper min-h-min"
            onOk={() => setModalOpen(true)}
            onCancel={() => setModalOpen(false)}
            footer={null}
          >
            <div className="bg-white min-w-min w-full p-8">
              <div className="flex justify-between mt-5">
                <div className="flex gradTotalUser px-2 py-5 items-center rounded-lg max-w-[249px] w-full ">
                  <div className="border-dashed border-[2px] rounded-full max-w-[55px] max-h-[55px] min-w-[55px] min-h-[55px] p-2 flex items-center justify-center">
                    <img
                      alt=""
                      src={facebook}
                      className=" max-w-[32px] max-h-[32px]"
                    />
                  </div>

                  <div className="pl-4 text-white">
                    <h6 className=" text-lg capitalize font-Mulish font-medium">
                      facebook
                    </h6>
                    <p className="text-xs font-Mulish font-medium">87.5</p>
                    <p className="text-xs font-Mulish font-medium">
                      Since last Week
                    </p>
                  </div>
                </div>

                <div className="flex gradTotalRevenue px-2 py-5 items-center rounded-lg max-w-[249px] w-full">
                  <div className="border-dashed border-[2px] rounded-full max-w-[55px] max-h-[55px] min-w-[55px] min-h-[55px] p-2 flex items-center justify-center">
                    <img
                      alt=""
                      src={totalRevenueIcon}
                      className=" max-w-[32px] max-h-[32px]"
                    />
                  </div>
                  <div className="pl-4 text-white">
                    <h6 className="font-medium font-Mulish text-lg capitalize">
                      instagram
                    </h6>
                    <p className="font-medium  text-xs">87.5</p>
                    <p className="font-medium  text-xs">Since last Week</p>
                  </div>
                </div>

                <div className="flex gradTotalAffiliate px-2 py-5 items-center rounded-lg max-w-[249px] w-full">
                  <div className="border-dashed border-[2px] rounded-full max-w-[55px] max-h-[55px] min-w-[55px] min-h-[55px] p-2 flex items-center justify-center">
                    <img
                      alt=""
                      src={totalAffiliateIcon}
                      className=" max-w-[32px] max-h-[32px]"
                    />
                  </div>
                  <div className="pl-4 text-white">
                    <h6 className="font-medium font-Mulish text-lg capitalize">
                      twitter
                    </h6>
                    <p className="font-medium  text-xs">87.5</p>
                    <p className="font-medium  text-xs">Since last Week</p>
                  </div>
                </div>

                <div className="flex gradadminFee px-2 py-5 items-center rounded-lg max-w-[249px] w-full">
                  <div className="border-dashed border-[2px] rounded-full max-w-[55px] max-h-[55px] min-w-[55px] min-h-[55px] p-2 flex items-center justify-center">
                    <img
                      alt=""
                      src={adminFeeIcon}
                      className=" max-w-[32px] max-h-[32px]"
                    />
                  </div>
                  <div className="pl-4 text-white">
                    <h6 className="font-medium font-Mulish text-lg capitalize">
                      linkedin
                    </h6>
                    <p className="font-medium  text-xs">87.5</p>
                    <p className="font-medium  text-xs">Since last Week</p>
                  </div>
                </div>
              </div>
              <div className="flex max-h-[308px] ">
                <div className="w-1/2 bg-white mt-9 rounded">
                  <h3 className=" pb-5 font-Mulish font-semibold text-2xl">
                    Affiliate Analytics
                  </h3>
                  <BarChart />
                </div>
                <div className="w-1/2 bg-white mt-9 rounded min-h-[305px] flex justify-center">
                  <Pie data={pieData} options={options} />
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </Layout>
  );
};

export default Traffic;
