import React, { useState } from "react";
import affiliateDashboard from "../../../common/assets/images/affiliateDashboard.svg";
import affiliateNews from "../../../common/assets/images/affiliateNews.svg";
import { NavLink, useNavigate } from "react-router-dom";
import { Menu } from "antd";
import commissionIcon from "../../../common/assets/images/payout_management.svg";
import contentCreativeIcon from "../../../common/assets/images/creative_management.svg";
/** @jsxImportSource theme-ui */

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const items = [
  getItem(
    "Dashboard",
    "/affiliate/dashboard",
    <div className="bg-[#D8D8D8] border-[2px] border-[#6178A5] rounded-full max-w-[55px] max-h-[55px] min-w-[55px] min-h-[55px] p-2 flex items-center justify-center">
      <img
        src={affiliateDashboard}
        className=" max-w-[38px] max-h-[30px] mt-[-6px]"
        alt=""
      />
    </div>
  ),
  getItem(
    "News",
    "/affiliate/news",
    <div className="bg-[#D8D8D8] border-[2px] border-[#6178A5] rounded-full max-w-[55px] max-h-[55px] min-w-[55px] min-h-[55px] p-2 flex items-center justify-center">
      <img src={affiliateNews} className=" max-w-[32px] max-h-[32px]" alt="" />
    </div>
  ),
  getItem(
    "Offers",
    "/affiliate/offers",
    <div className="bg-[#D8D8D8] border-[2px] border-[#6178A5] rounded-full max-w-[55px] max-h-[55px] min-w-[55px] min-h-[55px] p-2 flex items-center justify-center">
      <img src={commissionIcon} className=" max-w-[32px] max-h-[32px]" alt="" />
    </div>
  ),
  getItem(
    "Invoice",
    "/affiliate/invoice",
    <div className="bg-[#D8D8D8] border-[2px] border-[#6178A5] rounded-full max-w-[55px] max-h-[55px] min-w-[55px] min-h-[55px] p-2 flex items-center justify-center">
   <img src={commissionIcon} className=" max-w-[32px] max-h-[32px]" alt="" />
    </div>
  ),
  getItem(
    "Reports",
    "/affiliate/reports",
    <div className="bg-[#D8D8D8] border-[2px] border-[#6178A5] rounded-full max-w-[55px] max-h-[55px] min-w-[55px] min-h-[55px] p-2 flex items-center justify-center">
   <img src={affiliateNews} className=" max-w-[32px] max-h-[32px]" alt="" />
    </div>
  ),
];

const AffiliateSidebar = (props) => {
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [collapsed, setCollapsed] = useState(false);
  const [selectedKey, setSelectedKey] = useState(window.location.pathname);

  const handleMenu = ({ key, domEvent }) => {
    setSelectedKey(key);
    navigate(key);
  };

  return (
    <div
      className="bg-[#002366] h-screen"
    >
      <NavLink to="/affiliate/dashboard">
        <div
          sx={{
            background: "primary",
          }}
          className=""
        >
          <h1 className="text-white text-center text-3xl font-semibold leading-[45px] pt-[17px] pb-[18px] capitalize">
            affiliate dashboard
          </h1>
          <hr />
        </div>
      </NavLink>
      <Menu
        onClick={handleMenu}
        selectedKeys={[selectedKey]}
        mode="inline"
        className="bg-[#002366] text-white sidebarItems"
        inlineCollapsed={collapsed}
        items={items}
      />
    </div>
  );
};

export default AffiliateSidebar;
