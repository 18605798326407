import axios from "../../utils/interceptor/index";
import {
  BLOGS_FAILURE,
  BLOGS_REQUEST,
  CREATE_BLOGS_SUCCESS,
  DELETE_BLOGS_SUCCESS,
  GET_ALL_BLOGS_SUCCESS,
  SEARCHED_BLOGS_SUCCESS,
  UPDATE_BLOGS_SUCCESS,
} from "../constants";
import {
  ADD_BLOGS_URL,
  DELETE_BLOGS_URL,
  GET_ALL_REQUESTED_BLOGS_URL,
  UPDATE_BLOGS_URl,
} from "../../utils/api/urls";

export const addBlogs = (payload) => {
  return async (dispatch) => {
    dispatch({ type: BLOGS_REQUEST });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Add any other headers if needed
      };
      const response = await axios.post(ADD_BLOGS_URL, payload, {
        headers,
      });
      if (response?.data) {
        dispatch({ type: CREATE_BLOGS_SUCCESS, payload: response?.data?.data });
        return response?.data;
      }
    } catch (error) {
      dispatch({ type: BLOGS_FAILURE, payload: { error } });
      return error?.response?.data;
    }
  };
};

export const getAllblogs = (page = 10, currPage = 1) => {
  return async (dispatch) => {
    dispatch({ type: BLOGS_REQUEST });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Add any other headers if needed
      };
      const response = await axios.get(
        `${GET_ALL_REQUESTED_BLOGS_URL}?limit=${page}&pageNo=${currPage}`,
        { headers }
      );
      if (response?.data) {
        dispatch({
          type: GET_ALL_BLOGS_SUCCESS,
          payload: response?.data?.data?.paginatedData,
        });
        return response?.data?.data;
      }
    } catch (error) {
      dispatch({ type: BLOGS_FAILURE, payload: { error } });
    }
  };
};

export const deleteBlogs = (id) => {
  return async (dispatch) => {
    dispatch({ type: BLOGS_REQUEST });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Add any other headers if needed
      };
      const result = await axios.delete(`${DELETE_BLOGS_URL}/${id}`, {
        headers,
      });
      dispatch({
        type: DELETE_BLOGS_SUCCESS,
        payload: result?.data?.data || {},
      });
      return result?.data;
    } catch (err) {
      dispatch({ type: BLOGS_FAILURE, payload: err });
      return err?.response?.data;
    }
  };
};

export const updateBlogs = (id, payload) => {
  return async (dispatch) => {
    dispatch({ type: BLOGS_REQUEST });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Add any other headers if needed
      };
      const result = await axios.put(`${UPDATE_BLOGS_URl}/${id}`, payload, {
        headers,
      });

      dispatch({
        type: UPDATE_BLOGS_SUCCESS,
        payload: result?.data?.data || {},
      });
      return result?.data;
    } catch (err) {
      dispatch({ type: BLOGS_FAILURE, payload: err });
      return err?.response?.data;
    }
  };
};

export const searchBlogs = (searchParams) => {
  return async (dispatch) => {
    dispatch({ type: BLOGS_REQUEST });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Add any other headers if needed
      };
      const response = await axios.get(
        `${GET_ALL_REQUESTED_BLOGS_URL}?search=${searchParams}`,

        {
          headers,
        }
      );

      if (response?.data) {
        dispatch({
          type: SEARCHED_BLOGS_SUCCESS,
          payload: response?.data?.data?.paginatedData || {},
        });
        return response?.data?.data || {};
      }
    } catch (error) {
      dispatch({ type: BLOGS_FAILURE, payload: { error } });
    }
  };
};
