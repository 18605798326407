import React from 'react'
import { Link } from 'react-router-dom';

const PageNotFound = () => {
  return (
    <div className="bg-gray-100 min-h-screen flex items-center justify-center">
            <div className="max-w-md mx-auto text-center p-8 bg-white rounded shadow">
                <h1 className="text-4xl font-semibold mb-4">Error 404</h1>
                <p className="text-gray-600 mb-4">The page you're looking for could not be found.</p>
                <Link to="/login" className="text-blue-500 hover:underline">Go back to the login page</Link>
            </div>
        </div>
  )
}

export default PageNotFound;