import {
  DELETE_AFFILIATE_INVOICE,
  GENERATE_INVOICE_SUCCESS,
  GET_ALL_INVOICE_AFFILIATE,
  INVOICE_FAILURE,
  INVOICE_REQUEST,
  UPDATE_INVOICE_SUCCESS,
} from "../constants";

const initialState = {
  data: [],
  loading: false,
  message: "",
  error: false,
};

export const AffiliateInvoicesReducer = (
  state = initialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case INVOICE_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        message: "",
      };
    case GENERATE_INVOICE_SUCCESS:
      return {
        ...state,
        data: [payload, ...state.data],
        loading: false,
        error: false,
        message: "",
      };
    case GET_ALL_INVOICE_AFFILIATE:
      return {
        ...state,
        data: payload,
        loading: false,
        error: false,
        message: "",
      };
    case DELETE_AFFILIATE_INVOICE:
      return {
        ...state,
        data: state.data.filter((invoice) => invoice._id !== payload._id),
        loading: false,
        error: false,
        message: "",
      };
      case UPDATE_INVOICE_SUCCESS:
        return {
          ...state,
          data: state?.data?.map((invoice) =>
            invoice._id === payload._id ? payload : invoice
          ),
          error: false,
          loading: false,
        };
  
    case INVOICE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        message: payload?.message,
      };
    default:
      return state;
  }
};
