export const BASE_URL = 'https://socialprofit.io/api'
// export const BASE_URL = 'https://gizor1x9vc.execute-api.eu-west-2.amazonaws.com/api'
// export const BASE_URL = 'http://192.168.1.92:9000/api'


export const LOGIN_URL = `${BASE_URL}/user/login`
export const FORGOT_PASSWORD_URL = '/user/forgetPassword'
export const RESET_PASSWORD_URL = '/user/resetPassword'
export const SIGNUP_URL = `${BASE_URL}/user/signUp`
export const GET_PROFILE_URL = `${BASE_URL}/user/profile`
export const UPDATE_PROFILE = `${BASE_URL}/user/updateProfile`
export const GET_ALL_USER_URL = `${BASE_URL}/admin/getAllUsers`
export const UPDATE_USER_URL = `${BASE_URL}/admin/updateUserById`
export const DELETE_USER_URL = `${BASE_URL}/admin/deleteUserById`
export const NOTIFICATIONS_URL = `${BASE_URL}/notifications/users`
export const ADD_USER_URL = `${BASE_URL}/admin/addUser` 
export const ADD_OFFER_URL = `${BASE_URL}/offer/addOffer`
export const GET_ALL_OFFER_URL = `${BASE_URL}/offer/getAllOffers`
export const GET_ALL_PROMOTED_URL = `${BASE_URL}/promotion/getAllPromotions`
export const UPDATE_OFFER_URL = `${BASE_URL}/offer/updateOfferById`
export const DELETE_OFFER_URL = `${BASE_URL}/offer/deleteOfferById`
export const REMOVE_PROMOTED_OFFER_URL = `${BASE_URL}/promotion/deletePromotion`
export const ADD_PROMOTE_OFFER_URL = `${BASE_URL}/promotion/createPromotion`
export const REQUEST_OFFER_URL = `${BASE_URL}/offer/requestForOffersById`
export const REVIEW_OFFER_REQUESTS_URL = `${BASE_URL}/offer/reviewOfferRequestsById`

export const GET_ALL_MY_APPROVED_OFFER_URL = `${BASE_URL}/offer/getAllOffers?onlyMyOffers=true`
export const GET_ALL_REQUESTED_OFFERS_URL = `${BASE_URL}/offer/getAllOfferRequests`;
export const ADD_NEWS_URL  = `${BASE_URL}/news/createNews`
export const GET_ALL_REQUESTED_NEWS_URL = `${BASE_URL}/news/getAllNews`
export const ADD_BLOGS_URL  = `${BASE_URL}/blogs/createBlog`
export const GET_ALL_REQUESTED_BLOGS_URL = `${BASE_URL}/blogs/getAllBlogs`
export const DELETE_NEWS_URL = `${BASE_URL}/news/deleteNewsById`
export const DELETE_BLOGS_URL = `${BASE_URL}/blogs/deleteBlogById`
export const UPDATE_NEWS_URl = `${BASE_URL}/news/updateNewsById`
export const UPDATE_BLOGS_URl = `${BASE_URL}/blogs/updateBlogById`
export const GENERATE_INVOICE_URL = `${BASE_URL}/invoice/generateInvoice`
export const GET_ALL_INVOICE_AFFILIATE_URL = `${BASE_URL}/invoice/getAllInvoiceByUserEmail`
export const DELETE_AFFILIATE_INVOICE_URL = `${BASE_URL}/invoice/deleteAffiliateInvoiceById`;
export const DELETE_ADMIN_INVOICE_URL = `${BASE_URL}/invoice/deleteInvoiceById`;
export const GET_INVOICE_URL = `${BASE_URL}/invoice/getAllInvoices`
export const REVIEW_INVOICE_URL = `${BASE_URL}/invoice/reviewInvoicesById`
export const UPDATE_INVOICE = `${BASE_URL}/invoice/updateInvoiceById`
export const GENERATE_REPORT = `${BASE_URL}/offer/generateReport`
export const GET_ALL_REPORT_URL = `${BASE_URL}/offer/getAllReports`
export const UPDATE_REPORT_URL =  `${BASE_URL}/offer/updateReportById`
export const DELETE_REPORT_URL =  `${BASE_URL}/offer/deleteOfferReportById`
export const SEARCHED_REPORT_URL =  `${BASE_URL}/offer/getAllReports`
export const GET_ALL_AFFILIATE_REPORT_URL = `${BASE_URL}/offer/getAllOfferReportsByUserEmail`
export const GET_ALL_DASHBOARD_REPORT_URL = `${BASE_URL}/offer/getAllOfferReportsAnalytics`
export const GET_AFFILIATE_DASHBOARD_REPORT_URL = `${BASE_URL}/offer/getUserRevenue`
export const SINGLE_USER_PROFILE_URL = `${BASE_URL}/user/profileById`
export const GET_ALL_AUTHORITIES_URL = `${BASE_URL}/admin/getAuthorities`
export const GET_ALL_USER_EMAIL = `${BASE_URL}/user/getAllAffiliateEmails`





