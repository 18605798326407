import {
  PROFILE_FAILURE,
  PROFILE_REQUEST,
  PROFILE_SUCCESS,
  PROFILE_UPDATE_SUCCESS,
} from "../constants";

const initialState = {
  data: {},
  loading: false,
  message: "",
  error: false,
};
export function profileReducer(profileState = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case PROFILE_REQUEST:
      return {
        ...profileState,
        loading: true,
        message: "",
        error: false,
      };
    case PROFILE_FAILURE:
      return {
        ...profileState,
        loading: false,
        message: payload,
        error: true,
      };

    case PROFILE_SUCCESS:
      return {
        ...profileState,
        loading: false,
        message: "",
        error: false,
        data: payload,
      };
    case PROFILE_UPDATE_SUCCESS: 
      return {
        ...profileState,
        data: payload,
        error: false,
        loading: false,
      };
    
    default:
      return profileState;
  }
}

