import React, { useEffect } from "react";
import { Tabs } from "antd";
import Layout from "../../../../common/layout";
import OfferTable from "./AllOffersTable";
import SmartLinkTable from "./SmartLinkTable";
import { getAllMyApprovedOffer, getAllOffer } from "../../../../redux/actions/offer";
import { useDispatch } from "react-redux";
import MyOfferTable from "./MyOfferTable";




const AffiliateOffer = () => {
  const dispatch= useDispatch()
  const items = [
    {
      key: "1",
      label: <p className="font-Mulish font-semibold text-xl">All Offers</p>,
      children: <OfferTable />,
    },
    {
      key: "2",
      label: <p className="font-Mulish font-semibold text-xl">My Offers</p>,
      children: <MyOfferTable />,
    },
    {
      key: "3",
      label: <p className="font-Mulish font-semibold text-xl">Smart Links</p>,
      children: <SmartLinkTable />,
    },
  ];
  const onChange = (key) => {
    if(key==="1"){
      dispatch(getAllOffer())
    }else if(key=== "2"){
      dispatch(getAllMyApprovedOffer())
    }
  };
  useEffect(()=>{
    // eslint-disable-next-line
    dispatch(getAllOffer());
    // eslint-disable-next-line
  },[])
  return (
    <Layout>
      <div>
        <h1 className="capitalize font-semibold text-3xl mt-3">offers</h1>
        <Tabs
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
          className="affiliateOfferWrapper mt-5"
        />
      </div>
    </Layout>
  );
};

export default AffiliateOffer;
