import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
      <span className="text-sm sm:text-center text-[#848484]">
        © 2023 <Link className="hover:underline">socialprofit.io </Link>. All
        Rights Reserved.
      </span>
      <div className="flex flex-wrap items-center mt-3 text-sm font-medium text-[#848484] sm:mt-0">
        <Link className="mr-3 px-3 border-r-2 border-[#ACA8A8]">
          Terms & Conditions
        </Link>
        <Link className="mr-3">Privacy Policy</Link>
      </div>
    </div>
  );
};

export default Footer;
