import { Input, Table, notification, Tooltip, Select } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import * as moment from "moment";
import emptyImage from "../../../../common/assets/images/emptyImage.jpg";
import { useEffect } from "react";
import { getAllOffer, getAllPromotedOffer, requestOffer } from "../../../../redux/actions/offer";
import { useState } from "react";

const AllPromotedOffer = () => {
    const dispatch = useDispatch()
  const offerData = useSelector((state) => state.offers);
  const [itemCount, setItemCount] = useState(offerData?.data?.length);
  const [pageSize, setPageSize] = useState(10);
  const [currPage, setCurrPage] = useState(1);
  const [api, contextHolder] = notification.useNotification();

  const handleOfferRequest = async (id) => {
    const response = await dispatch(requestOffer(id));
    if (response?.data) {
      api.success({
        message: response?.message || "Success",
        duration: 3,
      });
    } else {
      api.error({
        message: response?.message || "Something went wrong",
        duration: 3,
      });
    }
  };
  

  const otherCopy = () => {
    api.success({
      message: "Copied",
      duration: 3,
    });
  };

  const newData = offerData?.data?.map((item) => {
    return {
      offer: (
        <div className="flex items-center gap-5">
          {/* <Rate count={1} className="affiliateTableStar" /> */}
          <img
            src={item.image || emptyImage}
            alt=""
            className="max-w-none"
            width={40}
            height={40}
          />
          <p className="font-Mulish font-semibold text-base max-w-[80px]">
            {item.name}
          </p>
        </div>
      ),
      link: (
        <>
          {item.landingPageUrl ? (
            <>
              <div className="flex gap-1 affilateOfferLinkWrapper">
                <Tooltip title={item.landingPageUrl}>
                  <span className="affilateOfferLink">
                    {item.landingPageUrl}
                  </span>
                </Tooltip>
                <CopyToClipboard onCopy={otherCopy} text={item.landingPageUrl}>
                  <button>
                    <CopyOutlined />
                  </button>
                </CopyToClipboard>
              </div>
            </>
          ) : (
            ""
          )}
        </>
      ),
      description: <Tooltip title={item.description}><p className="truncate">{item.description}</p></Tooltip>,
      category: item.category,
      originalPrice: item.originalPrice,
      discountedPrice: item.discountedPrice,
      discountPercentage: item.discountedValue,
      discountType: <p className="capitalize">{item.discountType}</p>,
      createdAt: moment(item.createdAt).format("DD-MM-YYYY"),
      action: (
        <>
          {item.hasRequested ? (
            <button
              className="bg-[#002366] opacity-75 rounded-full text-white py-2 px-5"
              disabled={item.hasRequested}
            >
              Requested
            </button>
          ) : (
            <button
              className="bg-[#002366] rounded-full text-white py-2 px-5"
              onClick={() => handleOfferRequest(item._id)}
            >
              Request
            </button>
          )}
        </>
      ),
    };
  });

  const columns = [
    {
      title: "Offer",
      dataIndex: "offer",
      fixed: "left",
    },
    // {
    //   title: "Link",
    //   dataIndex: "link",
    //   ket: "link",
    //   sorter: {
    //     compare: (a, b) => a.chinese - b.chinese,
    //     multiple: 3,
    //   },
    // },
    {
      title: "Description",
      dataIndex: "description",
      ket: "description",
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 3,
      },
    },
    {
      title: "Category",
      dataIndex: "category",
      ket: "category",
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 3,
      },
    },
    // {
    //   title: "Original Price",
    //   dataIndex: "originalPrice",
    //   key: "type",
    //   ellipsis: true,
    // },
    {
      title: "Affiliate Commission",
      dataIndex: "discountedPrice",
      key: "value",
      ellipsis: true,
    },
    // {
    //   title: "Discount Type",
    //   dataIndex: "discountType",
    //   sorter: {
    //     compare: (a, b) => a.english - b.english,
    //     multiple: 1,
    //   },
    // },
    // {
    //   title: "Discount Percentage/ Value",
    //   dataIndex: "discountPercentage",
    //   sorter: {
    //     compare: (a, b) => a.english - b.english,
    //     multiple: 1,
    //   },
    // },
    {
      title: "Created At",
      dataIndex: "createdAt",
      sorter: {
        compare: (a, b) => a.english - b.english,
        multiple: 1,
      },
    },

    {
      title: "Action",
      dataIndex: "action",
    },
  ];
      const paginationConfig = {
        pageSize: pageSize, // Number of items to display per page
        total: itemCount || 0, // Total number of items in the data source
        showSizeChanger: true, // Show the "Show X items per page" dropdown
        showQuickJumper: true, // Show the quick jumper input field
        pageSizeOptions: ["10"], // Options for items per page dropdown
      };
    
      const onChangeTable = (pagination, filters, sorter, extra) => {
        setCurrPage(pagination.current);
        setPageSize(pagination.pageSize);
      };

      useEffect(() => {
        (async () => {
          const response = await dispatch(getAllPromotedOffer(pageSize, currPage));
        })();
        // eslint-disable-next-line
      }, [pageSize, currPage]);
  return (
    <div>
    {contextHolder}
    <Table
    columns={columns}
    dataSource={newData}
    onChange={onChangeTable}
    className="userTable"
    pagination={paginationConfig}
    loading={offerData?.loading}
    scroll={{ x: 1600 }}
  />
  </div>
  )
}

export default AllPromotedOffer