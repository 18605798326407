import ForgotPassword from "../modules/auth/forgotPassword/ForgotPassword";
import Login from "../modules/auth/login/Login";
import SignUp from "../modules/auth/signup/SignUp";
import Protected from "../modules/auth/protected/Protected";
import PageNotFound from "../common/pageNotFound/";
import User from "../modules/auth0/admin/User";
import Offer from "../modules/auth0/affiliate/Offer";
import CommissionPayout from "../modules/auth0/admin/CommissionPayout";
import AdminNews from "../modules/auth0/admin/News";
import AffiliateDashboard from "../modules/auth0/Dashboard/affiliateDashboard";
import News from "../modules/auth0/affiliate/News";
import Traffic from "../modules/auth0/affiliate/Traffic";
import AffiliateInvoice from "../modules/auth0/affiliate/AffiliateInvoice";
import AdminDashboard from "../modules/auth0/Dashboard/adminDashboard";
import AdminOffer from "../modules/auth0/admin/Offer";
import AdminBlogs from "../modules/auth0/admin/Blogs";
import Profile from "../modules/auth0/profile";
import Reports from "../modules/auth0/affiliate/Reports";
import ReportsAndAnalytics from "../modules/auth0/admin/ReportsAndAnalytics";
import SingleUserProfile from "../modules/auth0/admin/adminProfile";
import SingleOffer from "../modules/auth0/affiliate/Offer/SingleOffer";
import ConfirmPassword from "../modules/auth/forgotPassword/ConfirmPassword";
export const routes = [
  {
    path: "*",
    key: "none",
    component: <PageNotFound />,
  },
  {
    path: "/",
    key: "root",
    component: <Login />,
  },
  {
    path: "/login",
    key: "login",
    component: <Login />,
  },
  {
    path: "/forgotPassword",
    key: "forgotPassword",
    component: <ForgotPassword />,
  },
  {
    path: "/resetPassword/:token",
    key: "confirmPassword",
    component: <ConfirmPassword />,
  },
  {
    path: "/signup",
    key: "signup",
    component: <SignUp />,
  },
  {
    path: "/dashboard",
    key: "dashboard",
    component: (
      <Protected
        Component={AdminDashboard}
        key={"adminDashboard"}
        allowedRoles={['businessDirector', 'businessManager' , 'businessPartner','partnershipView']}
      />
    ),
  },
  {
    path: "/dashboard/user-management",
    key: "dashboard-user-management",
    component: (
      <Protected
        Component={User}
        key={"user-management"}
        allowedRoles={['businessDirector', 'businessManager' , 'businessPartner','partnershipView']}
      />
    ),
  },
  {
    path: "/dashboard/user-management/profile/:id",
    key: "dashboard-user-management-profile",
    component: (
      <Protected
        Component={SingleUserProfile}
        allowedRoles={['businessDirector', 'businessManager' , 'businessPartner','partnershipView']}
      />
    ),
  },
  {
    path: "/dashboard/profile",
    key: "profile",
    component: (
      <Protected
      Component={Profile}
      key={"profile"}
      allowedRoles={['businessDirector', 'businessManager' , 'businessPartner' ,'partnershipView', 'standardAffiliate','premiumAffiliate',]}
    />
    )
  },
  {
    path: "/dashboard/offer-management",
    key: "dashboard-offer-management",
    
    component: (
      <Protected
        Component={AdminOffer}
        key={"offer-management"}
        allowedRoles={['businessDirector', 'businessManager' , 'businessPartner','partnershipView' ]}
      />
    ),
    
  },
  {
    path: "/dashboard/commission-payout",
    key: "dashboard-commission-payout",
    component: (
      <Protected
        Component={CommissionPayout}
        key={"commission-payout"}
        allowedRoles={['businessDirector', 'businessManager' , 'businessPartner','partnershipView' ]}
      />
    ),
  },
  {
    path: "dashboard/news",
    key: "dashboard-news",
    component: (
      <Protected
        Component={AdminNews}
        key={"news"}
        allowedRoles={['businessDirector', 'businessManager' , 'businessPartner','partnershipView']}
      />
    ),
  },
  {
    path: "dashboard/blogs",
    key: "dashboard-blogs",
    component: (
      <Protected
        Component={AdminBlogs}
        key={"blogs"}
        allowedRoles={['businessDirector', 'businessManager' , 'businessPartner','partnershipView' ]}
      />
    ),
  },
  {
    path: "dashboard/reportsAndAnalytics",
    key: "dashboard-reportsAndAnalytics",
    component: (
      <Protected
        Component={ReportsAndAnalytics}
        key={"reportsAndAnalytics"}
        allowedRoles={['businessDirector', 'businessManager' , 'businessPartner','partnershipView']}
      />
    ),
  },
  {
    path: "affiliate/dashboard",
    key: "affiliate-dashboard",
    component: (
      <Protected
        Component={AffiliateDashboard}
        key={"affiliate"}
        allowedRoles={['standardAffiliate','premiumAffiliate']}
      />
    ),
  },
  {
    path: "affiliate/news",
    key: "affiliate-news",
    component: (
      <Protected
        Component={News}
        key={"affiliateNews"}
        allowedRoles={['standardAffiliate','premiumAffiliate']}
      />
    ),
  },
  {
    path: "affiliate/offers",
    key: "affiliate-offers",
    component: (
      <Protected
        Component={Offer}
        key={"affiliateOffers"}
        allowedRoles={['standardAffiliate','premiumAffiliate']}
      />
    ),
  },
  {
    path: "affiliate/offers/:id",
    key: "affiliate-single-offer",
    component: (
      <Protected
        Component={SingleOffer}
        key={"affiliateSingleOffers"}
        allowedRoles={['standardAffiliate','premiumAffiliate']}
      />
    ),
  },
  {
    path: "/affiliate/invoice",
    key: "affiliate-invoice",
    component: (
      <Protected
        Component={AffiliateInvoice}
        key={"affiliateInvoice"}
        allowedRoles={['standardAffiliate','premiumAffiliate']}
      />
    ),
  },
  {
    path: "/affiliate/reports",
    key: "affiliate-reports",
    component: (
      <Protected
        Component={Reports}
        key={"reports"}
        allowedRoles={['standardAffiliate','premiumAffiliate']}
      />
    ),
  },
];
