import axios from "../../utils/interceptor/index";
import {
  LOGIN_USER_FAILURE,
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  SIGNUP_USER_FAILURE,
  SIGNUP_USER_REQUEST,
  SIGNUP_USER_SUCCESS,
} from "../constants";
import { LOGIN_URL, SIGNUP_URL } from "../../utils/api/urls";

export const loginOption = (payload) => {
  return async (dispatch) => {
    dispatch({ type: LOGIN_USER_REQUEST });
    try {
      const response = await axios.post(LOGIN_URL, payload);
      if (response?.data) {
        dispatch({
          type: LOGIN_USER_SUCCESS,
          payload: response?.data || {},
        });
        return response?.data
        
        
      }
      
    } catch (error) {
      dispatch({ type: LOGIN_USER_FAILURE, payload: { error } });
      return error?.response?.data
    }
  };
};

export const signupOption = (payload) => {
  return async (dispatch) => {
    dispatch({ type: SIGNUP_USER_REQUEST });
    try {
      const response = await axios.post(SIGNUP_URL, payload);
      if (response?.data) {
        dispatch({
          type: SIGNUP_USER_SUCCESS,
          payload: response?.data || {},
        });
        return response?.data
      }
    } catch (error) {
      dispatch({ type: SIGNUP_USER_FAILURE, payload: { error } });
      return error?.response?.data
    }
  };
};