import axios from "../../utils/interceptor/index";
import { DELETE_REPORT_URL, GENERATE_REPORT, GET_ALL_REPORT_URL, GET_ALL_USER_EMAIL, SEARCHED_REPORT_URL, UPDATE_REPORT_URL } from "../../utils/api/urls";
import {
  CREATE_REPORT_SUCCESS,
  DELETE_REPORT_SUCCESS,
  FAILURE_REPORT,
  GET_ALL_REPORT_SUCCESS,
  REQUEST_REPORT,
  SEARCHED_REPORT_SUCCESS,
  UPDATE_REPORT_SUCCESS,
} from "../constants";

export const addReport = (payload) => {
  return async (dispatch) => {
    dispatch({ type: REQUEST_REPORT });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Add any other headers if needed
      };
      const response = await axios.post(
        GENERATE_REPORT,
        payload,

        {
          headers,
        }
      );
      if (response?.data) {
        dispatch({
          type: CREATE_REPORT_SUCCESS,
          payload: response?.data?.data || {},
        });
        return response?.data || {};
      }
    } catch (error) {
      dispatch({ type: FAILURE_REPORT, payload: { error } });
      return error?.response?.data;
    }
  };
};

export const getAllEmail = ()=>{
  return async (dispatch)=>{
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Add any other headers if needed
      };
     
      const response = await axios.get( GET_ALL_USER_EMAIL,
        { headers }
      );
      console.log("!@!@!response",response);

      if (response?.data) {
        
        return response?.data?.data?.paginatedData        || [];
      }
    } catch (error) {
      return error
    }

  }
}

export const getAllReports = (page = 10, currPage = 1 , filterReports) => {
  
  return async (dispatch) => {
    dispatch({ type: REQUEST_REPORT });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Add any other headers if needed
      };
     
      const response = await axios.get( !filterReports ||  !filterReports[0]? 
        `${GET_ALL_REPORT_URL}?limit=${page}&pageNo=${currPage}` : `${GET_ALL_REPORT_URL}?limit=${page}&pageNo=${currPage}&startDate=${filterReports[0]}&endDate=${filterReports[1]}`,
        { headers }
      );
      if (response?.data) {
        dispatch({
          type: GET_ALL_REPORT_SUCCESS,
          payload: response?.data?.data?.paginatedData || [],
        });
        return response?.data?.data || [];
      }
    } catch (error) {
      dispatch({ type: FAILURE_REPORT, payload: { error } });
    }
  };
};

export const updateReports = (id, payload) => {
  return async (dispatch) => {
    dispatch({ type: REQUEST_REPORT });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Add any other headers if needed
      };
      const result = await axios.put(
        `${UPDATE_REPORT_URL}/${id}`,
        payload,
        { headers }
      );

      dispatch({
        type: UPDATE_REPORT_SUCCESS,
        payload: result?.data?.data || {},
      });
      return result?.data;
    } catch (err) {
      dispatch({ type: FAILURE_REPORT, payload: err });
      return err?.response?.data;
    }
  };
};

export const deleteReports = (id) => {
  return async (dispatch) => {
    dispatch({ type: REQUEST_REPORT });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Add any other headers if needed
      };
      const result = await axios.delete(
        `${DELETE_REPORT_URL}/${id}`,
        { headers }
      );
      dispatch({
        type: DELETE_REPORT_SUCCESS,
        payload: result?.data?.data || {},
      });
      return result?.data;
    } catch (err) {
      dispatch({ type: FAILURE_REPORT, payload: err });
      return err?.response?.data;
    }
  };
};


export const searchReport = (searchParams) => {
  return async (dispatch) => {
    dispatch({ type: REQUEST_REPORT });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Add any other headers if needed
      };
      const response = await axios.get(
        `${SEARCHED_REPORT_URL}?search=${searchParams}`,

        {
          headers,
        }
      );

      if (response?.data) {
        dispatch({
          type: SEARCHED_REPORT_SUCCESS,
          payload: response?.data?.data?.paginatedData || {},
        });
        return response?.data?.data || {};
      }
    } catch (error) {
      dispatch({ type: FAILURE_REPORT, payload: { error } });
    }
  };
};