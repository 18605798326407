import { Table, Tooltip } from "antd";
import Column from "antd/es/table/Column";
import ColumnGroup from "antd/es/table/ColumnGroup";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllAffiliateReports } from "../../../../../redux/actions/affiliateReports";

const GeneralTable = ({setShowTotalPayout,monthFilter}) => {
  const allAffiliateReports = useSelector((store) => store.affiliateReports);
  const [itemCount, setItemCount] = useState(allAffiliateReports?.data?.length);
  const [pageSize, setPageSize] = useState(10);
  const [currPage, setCurrPage] = useState(1);
  const dispatch = useDispatch();
  const newData =
    allAffiliateReports?.data?.length > 0
      ? allAffiliateReports?.data?.map((item) => {
        
          return {
            key: item._id,
            name: item.name,
            email: item.email,
            offerId: item.offerId.name,
            gross: item.clicks.gross,
            unique: item.clicks.unique,
            invalid: item.clicks.invalid,
            approvedqty: item.approvedConversions.qty,
            approvedCr: `${item.approvedConversions.cr}%`,
            approvedPayout: `$${item.approvedConversions.payout}`,
            pendingQty: item.pendingConversions.qty,
            pendingCr: `${item.pendingConversions.cr}%`,
            rejectedQty: item.rejectedConversions.qty,
            rejectedCr: `${item.rejectedConversions.cr}%`,
            calculationsAr: `${item.calculations.ar}%`,
          };
        })
      : [];
      const paginationConfig = {
        pageSize: pageSize, // Number of items to display per page
        total: itemCount || 0, // Total number of items in the data source
        showSizeChanger: true, // Show the "Show X items per page" dropdown
        showQuickJumper: true, // Show the quick jumper input field
        pageSizeOptions: ["10", "20", "30"], // Options for items per page dropdown
      };

      const handleChange = (pagination, filters, sorter) => {
        setCurrPage(pagination.current);
        setPageSize(pagination.pageSize);
       
      };
    

  useEffect(() => {
    (async () => {
      const response = await dispatch(getAllAffiliateReports(pageSize, currPage,monthFilter));
      setItemCount(response?.itemCount);
      setShowTotalPayout(response?.paginatedData)
    })();
    // eslint-disable-next-line
  }, [pageSize, currPage, monthFilter]);
  return (
    <div className="my-5">
      <Table
        dataSource={newData}
        className="reportsTableCell"
        bordered
        scroll={{ x: 1750 }}
        loading={allAffiliateReports?.loading}
        handleChange={handleChange}
        pagination={paginationConfig}
      >
        <Column title="Offer" dataIndex="offerId" key="offerId" fixed="left"/>
        <ColumnGroup title="Clicks">
          <Column title="Gross" dataIndex="gross" key="gross" />
          <Column title="Unique" dataIndex="unique" key="unique" />
          <Column title="Invalid" dataIndex="invalid" key="invalid" />
        </ColumnGroup>
        <ColumnGroup
          title={
            <div className="flex items-center">
              <Tooltip title="Approved Conversion">
                <div
                  className="w-[9px] h-[9px] rounded-full"
                  style={{ backgroundColor: "rgb(39, 174, 96)" }}
                ></div>
              </Tooltip>
              <span className="pl-4">Conversions</span>
            </div>
          }
          className="approved_conversion relative before:content-[''] before:w-2 before:h-2 before:absolute before:left-0 before:top-0 "
        >
          <Column title="Qty" dataIndex="approvedqty" key="approvedqty" />
          <Column title="CR" dataIndex="approvedCr" key="approvedCr" />
          <Column
            title="Payout"
            dataIndex="approvedPayout"
            key="approvedPayout"
          />
        </ColumnGroup>
        <ColumnGroup
          title={
            <div className="flex items-center">
              <Tooltip title="Pending Conversion">
                <div
                  className="w-[9px] h-[9px] rounded-full"
                  style={{ backgroundColor: "rgb(243, 183, 90)" }}
                ></div>
              </Tooltip>
              <span className="pl-4">Conversions</span>
            </div>
          }
        >
          <Column title="Qty" dataIndex="pendingQty" key="pendingQty" />
          <Column title="CR" dataIndex="pendingCr" key="pendingCr" />
          {/* <Column title="Payout" dataIndex="payout" key="payout" /> */}
        </ColumnGroup>
        <ColumnGroup
          title={
            <div className="flex items-center">
              <Tooltip title="Rejected Conversion">
                <div
                  className="w-[9px] h-[9px] rounded-full"
                  style={{ backgroundColor: "rgb(246, 90, 99)" }}
                ></div>
              </Tooltip>
              <span className="pl-4">Conversions</span>
            </div>
          }
        >
          <Column title="Qty" dataIndex="rejectedQty" key="rejectedQty" />
          <Column title="CR" dataIndex="rejectedCr" key="rejectedCr" />
          {/* <Column title="Payout" dataIndex="payout" key="payout" /> */}
        </ColumnGroup>
        <ColumnGroup title="Calculations">
          <Column title="AR" dataIndex="calculationsAr" key="calculationsAr" />
        </ColumnGroup>
      </Table>
    </div>
  );
};

export default GeneralTable;
