import React, { useEffect } from "react";
import {
  Button,
  Form,
  Modal,
  Popconfirm,
  Select,
  Space,
  Table,
  Upload,
  notification,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useState } from "react";
import { Input } from "antd";
import {
  CaretDownOutlined,
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Dropdown } from "antd";
import Layout from "../../../../common/layout";
import { useDispatch, useSelector } from "react-redux";
import addIcon from "../../../../common/assets/images/addImage.svg";
import {
  addUser,
  approveDisapproveUser,
  deleteUser,
  getAllUser,
  searchUser,
  updateUser,
} from "../../../../redux/actions/user";
import { useWatch } from "antd/es/form/Form";
import { useNavigate } from "react-router-dom";
import { singleUserProfileData } from "../../../../redux/actions/profile";
import { getAllAuthorities } from "../../../../redux/actions/allAuthorities";
const { Search } = Input;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const convertImageUrlToObject = async (imageUrl) => {
  const response = await fetch(imageUrl);
  const blob = await response.blob();

  // Create a File object with the Blob data
  const file = new File([blob], "image.jpg", { type: "image/jpeg" });
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      const fileObject = {
        uid: Date.now().toString(), // Generate a unique ID
        size: img.size, // You can set the actual size here if available
        name: imageUrl.split("/").pop(), // Extract the file name from the URL
        url: imageUrl,
        type: "image/jpeg", // Set the appropriate content type
        lastModified: Date.now(), // Set the current timestamp as last modified
        lastModifiedDate: new Date(),
        status: "done", // You can set the initial status here
        percent: 100,
        thumbUrl: imageUrl, // You can set a thumbnail URL if needed
        originFileObj: file, // You can set the original file object here if available
        response: null,
        error: null,
        linkProps: null,
        xhr: null,
        preview: imageUrl, // Set the preview URL
      };

      resolve(fileObject);
      return fileObject;
    };

    img.onerror = (error) => {
      reject(error);
    };
  });
};
const User = () => {
  const allUsers = useSelector((store) => store?.users);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [updateForm] = Form.useForm();
  const dispatch = useDispatch();
  const values = useWatch([], form);
  const [submittable, setSubmittable] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [currPage, setCurrPage] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [id, setId] = useState("");
  const [itemCount, setItemCount] = useState(allUsers?.data?.length);
  const [allAuthorities, setAuthorities] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const currentUserRole = localStorage.getItem("profile");
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const handleChangeP = ({ fileList: newFileList }) => setFileList(newFileList);
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const handleMenuClick = async (
    id,
    image,
    name,
    mediaName,
    affiliateWithOtherSites,
    email,
    assignedTo,
    role,
    e
  ) => {
    const { key } = e;
    setId(id);
    if (key === "edit") {
      if (image && image.length > 0) {
        // If a new image is selected, use it
        // formData.append("image", image[0].originFileObj);
        convertImageUrlToObject(image)
          .then((result) => {
            setUpdateModalOpen(true);
            updateForm.setFieldsValue({
              key: id,
              name: name,
              image: [result],
              mediaName: mediaName,
              email: email,
              assignedTo: assignedTo,
              role: role,
            });
          })
          .catch((error) => {
            console.error("Error converting image:", error);
          });
      } else {
        setUpdateModalOpen(true);
        updateForm.setFieldsValue({
          key: id,
          name: name,
          mediaName: mediaName,
          email: email,
          assignedTo: assignedTo,
          role: role,
        });
      }
    } else if (key === "delete") {
      return;
    } else {
      const approveDisapproveUserResult = await dispatch(
        approveDisapproveUser(id, key, role)
      );
      if (approveDisapproveUserResult?.data) {
        api.success({
          message: approveDisapproveUserResult?.message || "Success",
          duration: 5,
        });
      } else {
        api.error({
          message:
            approveDisapproveUserResult?.message || "Something went wrong",
          duration: 5,
        });
      }
    }
  };
  const handleDeleteUser = async () => {
    const deleteUserResult = await dispatch(deleteUser(id));
    if (deleteUserResult?.data) {
      api.success({
        message: deleteUserResult?.message || "Success",
        duration: 5,
      });
    } else {
      api.error({
        message: deleteUserResult?.message || "Something went wrong",
        duration: 5,
      });
    }
  };
  const items = [
    {
      label: "Approve",
      key: "approved",

      icon: <CheckOutlined />,
    },
    {
      label: "Reject",
      key: "rejected",

      icon: <CloseOutlined />,
    },
    {
      label: "Edit",
      key: "edit",

      icon: <EditOutlined />,
    },
    {
      label: (
        <Popconfirm
          description="Are you sure you want to delete this user?"
          okText="Yes"
          cancelText="No"
          onConfirm={handleDeleteUser}
        >
          <DeleteOutlined />
          &nbsp; Delete
        </Popconfirm>
      ),
      key: "delete",

      icon: "",
    },
  ];

  const newData =
    allUsers?.data?.length > 0
      ? allUsers?.data?.map((item) => {
          return {
            key: item._id,
            name: (
              <p
                className="cursor-pointer"
                onClick={() => handleRowClick(item._id)}
              >
                {item.name}
              </p>
            ),
            mediaName: item.mediaName,
            affiliateLink: item.affiliateWithOtherSites || (
              <p className="text-center">-</p>
            ),
            email: item.email,
            assignedTo: item.assignedTo?.name,
            role: item.role,
            status:
              item.status === "rejected" ? (
                <p className="font-Mulish font-semibold text-base text-[#FC0303]">
                  Rejected
                </p>
              ) : item.status === "pending" ? (
                <p className="font-Mulish font-semibold text-base text-[#FFA500]">
                  Pending
                </p>
              ) : item.status === "approved" ? (
                <p className="font-Mulish font-semibold text-base text-[#047B25]">
                  Approved
                </p>
              ) : null,
            action: (
              <Dropdown
                menu={{
                  items,
                  onClick: (e) =>
                    handleMenuClick(
                      item._id,
                      item.image,
                      item.name,
                      item.mediaName,
                      item.affiliateWithOtherSites,
                      item.email,
                      item.assignedTo?._id,
                      item.role,
                      e
                    ),
                }}
                className="rounded-2xl bg-[#E3E0E0] border-[#B6B5B5]"
              >
                <Button>
                  <Space>
                    Action
                    <CaretDownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            ),
          };
        })
      : [];

  const onFinish = async ({
    image,
    name,
    mediaName,
    email,
    password,
    assignedTo,
    role,
  }) => {
    let formData = new FormData();
    formData.append("name", name);
    formData.append("mediaName", mediaName);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("assignedTo", assignedTo);
    formData.append("role", role);

    if (image && image.length > 0) {
      // If a new image is selected, use it
      formData.append("image", image[0].originFileObj);
    }
    const result = await dispatch(addUser(formData));
    if (result?.data) {
      setModalOpen(false);
      api.success({
        message: result?.message || "Success",
        duration: 5,
      });
      form.resetFields();
    } else {
      api.error({
        message: result?.message || "Something went wrong",
        duration: 5,
      });
    }
  };

  const onUpdateFinish = async ({
    image,
    name,
    mediaName,
    email,
    password,
    assignedTo,
    role,
  }) => {
    let formData = new FormData();
    formData.append("name", name);
    formData.append("mediaName", mediaName);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("assignedTo", assignedTo);
    formData.append("role", role);

    if (image && image.length > 0) {
      // If a new image is selected, use it
      formData.append("image", image[0].originFileObj);
    }
    const updateUserResult = await dispatch(updateUser(id, formData));
    if (updateUserResult?.data) {
      setUpdateModalOpen(false);
      updateForm.resetFields();
      api.success({
        message: updateUserResult?.message || "Success",
        duration: 3,
      });
    } else {
      api.error({
        message: updateUserResult?.message || "Something went wrong",
        duration: 3,
      });
      setUpdateModalOpen(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onUpdateFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleChange = (pagination, filters, sorter) => {
    setCurrPage(pagination.current);
    setPageSize(pagination.pageSize);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const handleRowClick = async (id) => {
    await dispatch(singleUserProfileData(id));
    navigate(`/dashboard/user-management/profile/${id}`);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      filteredValue: filteredInfo.name || null,
      onFilter: (value, record) => record.name.includes(value),
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "Media",
      dataIndex: "mediaName",
      key: "mediaName",
      filteredValue: filteredInfo.name || null,
      onFilter: (value, record) => record.mediaName.includes(value),
      sorter: (a, b) => a.mediaName.length - b.mediaName.length,
      sortOrder: sortedInfo.columnKey === "mediaName" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "Affiliate Link",
      dataIndex: "affiliateLink",
      key: "affiliateLink",
      filteredValue: filteredInfo.affiliateLink || null,
      onFilter: (value, record) => record.affiliateLink.includes(value),
      sorter: (a, b) => a.affiliateLink.length - b.affiliateLink.length,
      sortOrder:
        sortedInfo.columnKey === "affiliateLink" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      filteredValue: filteredInfo.email || null,
      onFilter: (value, record) => record.email.includes(value),
      sorter: (a, b) => a.email.length - b.email.length,
      sortOrder: sortedInfo.columnKey === "email" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "Assigned To",
      dataIndex: "assignedTo",
      key: "role",
      sorter: (a, b) => a.age - b.age,
      sortOrder:
        sortedInfo.columnKey === "assignedTo" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      sorter: (a, b) => a.age - b.age,
      sortOrder: sortedInfo.columnKey === "role" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      filteredValue: filteredInfo.address || null,
      onFilter: (value, record) => record.address.includes(value),
      sorter: (a, b) => a.address.length - b.address.length,
      sortOrder: sortedInfo.columnKey === "address" ? sortedInfo.order : null,
      ellipsis: true,
    },
  ];
  // Conditionally filter out columns based on user role
  const filteredColumns = columns.filter((column) => {
    // Define logic to determine if the column should be visible for the current user role
    if (currentUserRole === "businessDirector") {
      return true; // Show all columns for admin
    } else if (currentUserRole === "partnershipView") {
      return column.key !== "action"; // Hide column with key 'key2' for regular users
    }
    return true; // Default to showing the column
  });
  const onSearch = (value) => dispatch(searchUser(value.target.value));

  const onRoleChange = (value) => {
    switch (value) {
      case "demo":
        form.setFieldsValue({ note: "demo" });
        break;
      case "demo1":
        form.setFieldsValue({ note: "demo1" });
        break;
      default:
        form.setFieldValue({ note: "hello" });
    }
  };
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const handleCancelModal = () => {
    setModalOpen(false);
    form.resetFields();
  };
  const validateUserName = (rule, value, callback) => {
    const regex = /^[A-Za-z_ ]+$/; // Allow only letters (both uppercase and lowercase)
    if (!regex.test(value)) {
      callback("Only text characters are allowed!");
    } else {
      callback();
    }
  };
  const validateEmail = (rule, value, callback) => {
    // You can use a regular expression to validate the email format
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (!emailRegex.test(value)) {
      callback("Invalid email format");
    } else {
      callback();
    }
  };

  const paginationConfig = {
    pageSize: pageSize, // Number of items to display per page
    total: itemCount || 0, // Total number of items in the data source
    showSizeChanger: true, // Show the "Show X items per page" dropdown
    showQuickJumper: true, // Show the quick jumper input field
    pageSizeOptions: ["10", "20", "30"], // Options for items per page dropdown
  };
  const directorOptions = [
    <>
      <Select.Option value="businessDirector">Business Directors</Select.Option>
      <Select.Option value="businessPartner">Business Partners</Select.Option>
      <Select.Option value="businessManager">Business Managers</Select.Option>
      <Select.Option value="partnershipView">Partnership View</Select.Option>
      <Select.Option value="standardAffiliate">
        Standard Affiliate
      </Select.Option>
      <Select.Option value="premiumAffiliate">Premium Affiliate</Select.Option>
    </>,
  ];
  const partnerOptions = [
    <>
      <Select.Option value="standardAffiliate">
        Standard Affiliate
      </Select.Option>
      <Select.Option value="premiumAffiliate">Premium Affiliate</Select.Option>
    </>,
  ];

  useEffect(() => {
    (async () => {
      const response = await dispatch(getAllUser(pageSize, currPage));
      const authoritesData = await dispatch(getAllAuthorities());
      setAuthorities(authoritesData);

      setItemCount(response?.itemCount);
    })();

    // eslint-disable-next-line
  }, [pageSize, currPage]);

  useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        }
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <Layout>
      <div>
        {contextHolder}
        <h1 className="pt-5 font-semibold text-2xl font-Mulish">
          Users Management
        </h1>
        <div className="bg-[#fff] w-full flex my-4 justify-between shadow">
          <div className="w-[40%]">
            <div className="flex">
              <button className="py-4 pl-3 border-r-2 border-[#ACA8A8] pr-5 text-xl font-Mulish font-semibold" onClick={() => setPageSize(allUsers?.data?.length || 10)}>
                All Users
              </button>
              {currentUserRole !== "partnershipView" ? (
                <div className="flex items-center pl-5 gap-3">
                  <img
                    alt=""
                    src={addIcon}
                    className="mb-[-2px] cursor-pointer"
                    onClick={() => setModalOpen(true)}
                  />
                  <button
                    className="align-top pt-4 pb-3 text-xl font-Mulish font-semibold"
                    onClick={() => setModalOpen(true)}
                  >
                    Add User
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
            <Modal
              title=""
              centered
              form={form}
              open={modalOpen}
              className="p-0 modal_parent_wrapper"
              onOk={() => setModalOpen(false)}
              onCancel={handleCancelModal}
              footer={[
                <Button
                  form="myForm"
                  key="submit"
                  htmlType="submit"
                  type="primary"
                  className="bg-[#002366] rounded-[50px] min-w-[130px] min-h-[45px]"
                  disabled={!submittable}
                >
                  Submit
                </Button>,
              ]}
            >
              <h2 className="uppercase text-center bg-[#002366] text-white mb-4 p-4 text-[25px] font-medium">
                Add User
              </h2>
              <Form
                name="basic"
                layout="vertical"
                id="myForm"
                form={form}
                style={{ maxWidth: 600 }}
                className="p-4"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  name="image"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                >
                  <Upload
                    // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChangeP}
                    className="text-center"
                    beforeUpload={() => false}
                    name="image"
                  >
                    {fileList.length >= 8 ? null : uploadButton}
                  </Upload>
                </Form.Item>
                <Modal
                  open={previewOpen}
                  title={previewTitle}
                  footer={null}
                  onCancel={handleCancel}
                >
                  <img
                    alt="example"
                    style={{ width: "100%" }}
                    src={previewImage}
                  />
                </Modal>

                <div className="flex gap-4 pt-5">
                  <Form.Item
                    label="Name"
                    name="name"
                    className="font-semibold mb-3 w-1/2"
                    rules={[
                      {
                        required: true,
                        message: "Please input your name!",
                      },
                      {
                        validator: validateUserName,
                      },
                    ]}
                  >
                    <Input className=" py-2 rounded-2xl w-full" />
                  </Form.Item>
                  <Form.Item
                    label="Media Name"
                    name="mediaName"
                    className="font-semibold mb-3 w-1/2"
                    rules={[
                      {
                        required: true,
                        message: "Please input your name!",
                      },
                    ]}
                  >
                    <Input className=" py-2 rounded-2xl w-full" />
                  </Form.Item>
                </div>
                <div className="flex gap-4">
                  <Form.Item
                    label="Email Address"
                    name="email"
                    className="font-semibold mb-3 w-1/2"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your email address",
                      },
                      { validator: validateEmail }, // Custom validation rule
                    ]}
                  >
                    <Input className="py-2 rounded-2xl w-full" />
                  </Form.Item>
                  <Form.Item
                    label="Password"
                    name="password"
                    className="font-semibold mb-3 w-1/2"
                    rules={[
                      { required: true, message: "Please enter your password" },
                      {
                        min: 8,
                        message: "Password must be at least 8 characters long",
                      },
                      {
                        pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]*$/,
                        message:
                          "Password must contain at least one letter and one number",
                      },
                    ]}
                  >
                    <Input.Password className="py-2 rounded-2xl w-full" />
                  </Form.Item>
                </div>
                <div className="flex gap-4">
                  <Form.Item
                    label="Assigned To"
                    name="assignedTo"
                    className="font-semibold mb-3 w-1/2"
                    rules={[
                      {
                        required: true,
                        message: "Please Assign!",
                      },
                    ]}
                  >
                    <Select showSearch className="w-full"  optionFilterProp="children">
                      {allAuthorities?.map((item) => (
                        <Select.Option value={item._id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Choose role"
                    className="font-semibold mb-3 w-1/2"
                    name="role"
                    rules={[
                      {
                        required: true,
                        message: "Please choose role!",
                      },
                    ]}
                  >
                    <Select className="w-full" onChange={onRoleChange}>
                      {currentUserRole === "businessDirector"
                        ? directorOptions
                        : partnerOptions}
                    </Select>
                  </Form.Item>
                </div>
              </Form>
            </Modal>
            <Modal
              title=""
              centered
              form={updateForm}
              open={updateModalOpen}
              className="p-0 modal_parent_wrapper"
              onOk={() => setUpdateModalOpen(false)}
              onCancel={() => setUpdateModalOpen(false)}
              footer={[
                <Button
                  form="myUpdateForm"
                  key="submit"
                  htmlType="submit"
                  type="primary"
                  className="bg-[#002366] rounded-[50px] min-w-[130px] min-h-[45px]"
                >
                  Update
                </Button>,
              ]}
            >
              <h2 className="uppercase text-center bg-[#002366] text-white mb-4 p-4 text-[25px] font-medium">
                Update User
              </h2>
              <Form
                name="basic"
                layout="vertical"
                id="myUpdateForm"
                form={updateForm}
                style={{ maxWidth: 600 }}
                className="p-4"
                initialValues={{ remember: true }}
                onFinish={onUpdateFinish}
                onFinishFailed={onUpdateFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  name="image"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                >
                  <Upload
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChangeP}
                    className="text-center"
                    name="image"
                    beforeUpload={() => false}
                  >
                    {fileList.length >= 8 ? null : uploadButton}
                  </Upload>
                </Form.Item>
                <Modal
                  open={previewOpen}
                  title={previewTitle}
                  footer={null}
                  onCancel={handleCancel}
                >
                  <img
                    alt="example"
                    style={{ width: "100%" }}
                    src={previewImage}
                  />
                </Modal>

                <div className="flex gap-4 pt-5">
                  <Form.Item
                    label="Name"
                    name="name"
                    className="font-semibold mb-3 w-1/2"
                    rules={[
                      {
                        required: true,
                        message: "Please input your name!",
                      },
                      {
                        validator: validateUserName,
                      },
                    ]}
                  >
                    <Input className=" py-2 rounded-2xl w-full" />
                  </Form.Item>
                  <Form.Item
                    label="Media Name"
                    name="mediaName"
                    className="font-semibold mb-3 w-1/2"
                    rules={[
                      {
                        required: true,
                        message: "Please input your name!",
                      },
                    ]}
                  >
                    <Input className=" py-2 rounded-2xl w-full" />
                  </Form.Item>
                </div>
                <div className="flex gap-4">
                  <Form.Item
                    label="Email Address"
                    name="email"
                    className="font-semibold mb-3 w-1/2"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your email address",
                      },
                      { validator: validateEmail }, // Custom validation rule
                    ]}
                  >
                    <Input className="py-2 rounded-2xl w-full" />
                  </Form.Item>
                  <Form.Item
                    label="Password"
                    name="password"
                    className="font-semibold mb-3 w-1/2"
                    rules={[
                      {
                        min: 8,
                        message: "Password must be at least 8 characters long",
                      },
                      {
                        pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]*$/,
                        message:
                          "Password must contain at least one letter and one number",
                      },
                    ]}
                  >
                    <Input.Password className="py-2 rounded-2xl w-full" />
                  </Form.Item>
                </div>
                <div className="flex gap-4">
                  <Form.Item
                    label="Assigned To"
                    name="assignedTo"
                    className="font-semibold mb-3 w-1/2"
                  >
                    <Select className="w-full">
                      {allAuthorities?.map((item) => (
                        <Select.Option value={item._id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Choose role"
                    className="font-semibold mb-3 w-1/2"
                    name="role"
                  >
                    <Select className="w-full" onChange={onRoleChange}>
                      {currentUserRole === "businessDirector"
                        ? directorOptions
                        : partnerOptions}
                    </Select>
                  </Form.Item>
                </div>
              </Form>
            </Modal>
          </div>
          <div className="w-[60%] flex justify-end pt-4 pb-2 pr-3">
            <Search
              placeholder="search..."
              // onSearch={onSearch}
              onChange={onSearch}
              enterButton
              className="w-1/2 user_Search "
            />
          </div>
        </div>

        <Table
          columns={filteredColumns}
          dataSource={newData}
          onChange={handleChange}
          className="userTable"
          pagination={paginationConfig}
          loading={allUsers?.loading}
        />
      </div>
    </Layout>
  );
};

export default User;
