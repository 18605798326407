import React  from "react";
import AdminSidebar from "../../modules/auth0/sidebar/AdminSidebar";
import Footer from "../footer";
import AffiliateSidebar from "../../modules/auth0/sidebar/AffiliateSidebar";
import TopNavBar from "../topNavBar";
/** @jsxImportSource theme-ui */

const Layout = ({ children , profileId}) => {

  const admin = localStorage.getItem("profile");

  return (
    <>
      <div className="w-full flex min-h-screen">
        <div className="w-[23%] sticky top-0 h-[100vh]">
          {admin === "standardAffiliate" ? <AffiliateSidebar /> : <AdminSidebar />}
        </div>
        <div className="w-[77%] h-full">
          <TopNavBar profileId={profileId}/>
          <div className="py-1 px-5 min-h-[calc(100vh_-_132px)] relative bg-[#EEE]">
            {children}
          </div>
          <footer className="bg-white shadow  sticky bottom-0 z-10 ">
            <Footer />
          </footer>
        </div>
      </div>
    </>
  );
};

export default Layout;
