import React, { useState } from "react";
import Layout from "../../../../common/layout";
import { Select, Tabs } from "antd";
import GeneralTable from "./reportTables/GeneralTable";



const Reports = () => {



  const [activeTab, setActiveTab] = useState(); 
  const [showTotalPayout,setShowTotalPayout] = useState()
  const [monthFilter,setMonthFilter] = useState(0)

  const totalPayout = showTotalPayout?.reduce((total, item) => {
    if (item.approvedConversions && item.approvedConversions.payout) {
      return total + parseInt(item.approvedConversions.payout, 10);
    }
    return total;
  }, 0);

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  




  const tabItems = [
    {
      key: "general",
      label: <p className="font-Mulish font-semibold text-xl">General</p>,
      children: <GeneralTable setShowTotalPayout={setShowTotalPayout} monthFilter={monthFilter}/>,
    },
    // {
    //   key: "daily",
    //   label: (
    //     <p className="font-Mulish font-semibold text-xl">Daily</p>
    //   ),
    //   // children: <RequestedOffers />,
    // },
    // {
    //   key: "monthly",
    //   label: (
    //     <p className="font-Mulish font-semibold text-xl">Monthly</p>
    //   ),
    //   // children: <RequestedOffers />,
    // },
    // {
    //   key: "offer",
    //   label: (
    //     <p className="font-Mulish font-semibold text-xl">Offer</p>
    //   ),
    //   // children: <RequestedOffers />,
    // },
    // {
    //   key: "goal",
    //   label: (
    //     <p className="font-Mulish font-semibold text-xl">Goal</p>
    //   ),
    //   // children: <RequestedOffers />,
    // },
    // {
    //   key: "country",
    //   label: (
    //     <p className="font-Mulish font-semibold text-xl">Country</p>
    //   ),
    //   // children: <RequestedOffers />,
    // },
    // {
    //   key: "deviceType",
    //   label: (
    //     <p className="font-Mulish font-semibold text-xl">Device Type</p>
    //   ),
    //   // children: <RequestedOffers />,
    // },
    // {
    //   key: "deviceOs",
    //   label: (
    //     <p className="font-Mulish font-semibold text-xl">Device OS</p>
    //   ),
    //   // children: <RequestedOffers />,
    // },
    // {
    //   key: "connectionType",
    //   label: (
    //     <p className="font-Mulish font-semibold text-xl">Connection Type</p>
    //   ),
    //   // children: <RequestedOffers />,
    // },
    // {
    //   key: "smartLink",
    //   label: (
    //     <p className="font-Mulish font-semibold text-xl">Smart Link</p>
    //   ),
    //   // children: <RequestedOffers />,
    // },
    // {
    //   key: "referrals",
    //   label: (
    //     <p className="font-Mulish font-semibold text-xl">Referrals</p>
    //   ),
    //   // children: <RequestedOffers />,
    // },
  ];
  const handleTabChange = (key) => {
    setActiveTab(key);
    // setOffersTabKey(key);
    // setReRender(true);
  };
  
  return (
    <Layout>
      <>
        <div className=" mt-3 mb-5">
          <div className="flex justify-between items-center">
          <h1 className="capitalize font-semibold text-xl">Reports</h1>
          
          <p  className="capitalize font-medium text-lg">Total Payout- {totalPayout|| 0}</p>
          <Select defaultValue="January" style={{ width: 170 }} onChange={(e)=> setMonthFilter(e)}>
      {months.map((month, index) => (
        <Select.Option  value={index}>
          {month}
        </Select.Option>
      ))}
    </Select>
          </div>
          <div className=" w-full my-4 justify-between">
              <Tabs
                activeKey={activeTab}
                items={tabItems}
                className="adminOffers "
                onChange={(e) => handleTabChange(e)}
              />
            </div>
            
          </div>
      </>
    </Layout>
  );
};

export default Reports;
