import { BLOGS_FAILURE, BLOGS_REQUEST, CREATE_BLOGS_SUCCESS, DELETE_BLOGS_SUCCESS, GET_ALL_BLOGS_SUCCESS, SEARCHED_BLOGS_SUCCESS, UPDATE_BLOGS_SUCCESS } from "../constants";


const initialState = {
    data: [],
    loading: false,
    message: "",
    error: false,
  };
  

export function adminBlogsReducer(blogsState = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case BLOGS_REQUEST:
        return {
          ...blogsState,
          loading: true,
          error: false,
          message: "",
        };
      case BLOGS_FAILURE:
        return {
          ...blogsState,
          loading: false,
          error: true,
          message: payload?.message,
        };
  
      case CREATE_BLOGS_SUCCESS:
        return {
          ...blogsState,
          loading: false,
          error: false,
           data: [payload, ...blogsState.data],
        };
  
      case GET_ALL_BLOGS_SUCCESS:
        return {
          ...blogsState,
          data: payload,
          loading: false,
          error: false,
        };
  
      case UPDATE_BLOGS_SUCCESS: {
        return {
          ...blogsState,
          data: blogsState.data.map((blog) =>
            blog._id === payload._id ? payload : blog
          ),
          error: false,
          loading: false,
        };
      }
  
    //   case UPDATE_OFFER_ISREQUESTED_SUCCESS:
    //     return {
    //       ...blogsState,
    //       data: blogsState.data.map((offer) => {
    //         if (offer._id === payload.offer) {
    //           // eslint-disable-next-line
    //           return { ...offer, ["hasRequested"]: payload.hasRequested };
    //         }
    //         return offer;
    //       }),
    //       error: false,
    //       loading: false,
    //     };
  
      case DELETE_BLOGS_SUCCESS:
        return {
          ...blogsState,
          data: blogsState.data.filter((blog) => blog._id !== payload._id),
          error: false,
          loading: false,
        };
  
      case SEARCHED_BLOGS_SUCCESS:
        return {
          ...blogsState,
          data: payload,
          error: false,
          loading: false,
        };
  
      default:
        return blogsState;
    }
  }