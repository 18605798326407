import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { resetTimeOnIdle } from "./common/helpers/idleTime";

const IdleTimeComponent = () => {
    const [time, setTime] = useState(0);
    const navigate = useNavigate()
  
    useEffect(() => {
      if (time >= 900){
        localStorage.removeItem('accessToken')
        localStorage.removeItem('profile')
        navigate('/login')

      } ;
      const timer = setTimeout(() => {
        setTime(time + 1);
      }, 1000);
      return () => clearTimeout(timer);
      // eslint-disable-next-line
    }, [time]);
  
    resetTimeOnIdle(setTime);
  
    return <div />;
  };
  
  export default IdleTimeComponent;