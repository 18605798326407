import React from 'react'
import { Line } from 'react-chartjs-2'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js'

  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  )

const AffiliateLineChart = (props) => {

    const data = {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December"
        ],
        datasets: [
          {
            label: 'Revenue',
            data: props?.reports,
            backgroundColor: '#DE2121',
            borderColor: '#DE2121',
            borderWidth: 2,
          },
        ],
      };

     

  return (
    <>
    <Line data={data} />
    </>
  )
}

export default AffiliateLineChart