import { CREATE_REPORT_SUCCESS, DELETE_REPORT_SUCCESS, FAILURE_REPORT, GET_ALL_REPORT_SUCCESS, REQUEST_REPORT, SEARCHED_REPORT_SUCCESS, UPDATE_REPORT_SUCCESS } from "../constants";




const initialState = {
    data: [],
    loading: false,
    message: "",
    error: false,
  };
  
  export function reportReducer(reportState = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case REQUEST_REPORT:
        return {
          ...reportState,
          loading: true,
          error: false,
          message: "",
        };
  
      case FAILURE_REPORT:
        return {
          ...reportState,
          message: payload.message,
          loading: false,
          error: true,
        };
  
      case CREATE_REPORT_SUCCESS:
        return {
          ...reportState,
          data: [payload, ...reportState.data],
          error: false,
          loading: false,
        };
  
      case GET_ALL_REPORT_SUCCESS:
        return {
          ...reportState,
          data: payload,
          error: false,
          loading: false,
        };
  
      case UPDATE_REPORT_SUCCESS:
        return {
          ...reportState,
          data: reportState?.data?.map((user) =>
            user._id === payload._id ? payload : user
          ),
          error: false,
          loading: false,
        };
  
      case DELETE_REPORT_SUCCESS:
        return {
          ...reportState,
          data: reportState.data.filter((user) => user._id !== payload._id),
          error: false,
          loading: false,
        };
  
      case SEARCHED_REPORT_SUCCESS:
        return {
          ...reportState,
          data: payload,
          error: false,
          loading: false,
        };
  
      default:
        return reportState;
    }
  }