import {
  CREATE_NEWS_SUCCESS,
  DELETE_NEWS_SUCCESS,
  GET_ALL_NEWS_SUCCESS,
  NEWS_FAILURE,
  NEWS_REQUEST,
  SEARCHED_NEWS_SUCCESS,
  UPDATE_NEWS_SUCCESS,
} from "../constants";

const initialState = {
  data: [],
  loading: false,
  message: "",
  error: false,
};

export function adminNewsReducer(newsState = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case NEWS_REQUEST:
      return {
        ...newsState,
        loading: true,
        error: false,
        message: "",
      };
    case NEWS_FAILURE:
      return {
        ...newsState,
        loading: false,
        error: true,
        message: payload?.message,
      };

    case CREATE_NEWS_SUCCESS:
      return {
        ...newsState,
        loading: false,
        error: false,
        data: [payload, ...newsState.data],
      };

    case GET_ALL_NEWS_SUCCESS:
      return {
        ...newsState,
        data: payload,
        loading: false,
        error: false,
      };

    case UPDATE_NEWS_SUCCESS: {
      return {
        ...newsState,
        data: newsState.data.map((news) =>
          news._id === payload._id ? payload : news
        ),
        error: false,
        loading: false,
      };
    }

    //   case UPDATE_OFFER_ISREQUESTED_SUCCESS:
    //     return {
    //       ...newsState,
    //       data: newsState.data.map((offer) => {
    //         if (offer._id === payload.offer) {
    //           // eslint-disable-next-line
    //           return { ...offer, ["hasRequested"]: payload.hasRequested };
    //         }
    //         return offer;
    //       }),
    //       error: false,
    //       loading: false,
    //     };

    case DELETE_NEWS_SUCCESS:
      return {
        ...newsState,
        data: newsState.data.filter((news) => news._id !== payload._id),
        error: false,
        loading: false,
      };

    case SEARCHED_NEWS_SUCCESS:
      return {
        ...newsState,
        data: payload,
        error: false,
        loading: false,
      };

    default:
      return newsState;
  }
}
