import { AFFILIATE_REPORT_FAILURE, AFFILIATE_REPORT_REQUEST, GET_ALL_AFFILIATE_REPORT_SUCCESS } from "../constants";



const initialState = {
    data: [],
    loading: false,
    message: "",
    error: false,
  };
  
  export function affiliateReportReducer(affiliateReportState = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case AFFILIATE_REPORT_REQUEST:
        return {
          ...affiliateReportState,
          loading: true,
          error: false,
          message: "",
        };
  
      case AFFILIATE_REPORT_FAILURE:
        return {
          ...affiliateReportState,
          message: payload.message,
          loading: false,
          error: true,
        };

      case GET_ALL_AFFILIATE_REPORT_SUCCESS:
        return {
          ...affiliateReportState,
          data: payload,
          error: false,
          loading: false,
        };
  
  
    //   case SEARCHED_REPORT_SUCCESS:
    //     return {
    //       ...affiliateReportState,
    //       data: payload,
    //       error: false,
    //       loading: false,
    //     };
  
      default:
        return affiliateReportState;
    }
  }