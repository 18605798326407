import axios from "../../utils/interceptor/index";
import { GET_ALL_DASHBOARD_REPORT_URL } from "../../utils/api/urls";
import { DASHBOARD_REPORT_FAILURE, DASHBOARD_REPORT_REQUEST, GET_ALL_DASHBOARD_REPORT_SUCCESS } from "../constants";

export const getAllDashboardReports = (filterValue) => {
    return async (dispatch) => {
      dispatch({ type: DASHBOARD_REPORT_REQUEST });
      try {
        const headers = {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          // Add any other headers if needed
        };
        const response = await axios.get(!filterValue || !filterValue[0]?`${GET_ALL_DASHBOARD_REPORT_URL}`:

        `${GET_ALL_DASHBOARD_REPORT_URL}?startDate=${filterValue[0]}&endDate=${filterValue[1]}`,

          { headers }
        );
        if (response?.data) {
          dispatch({
            type: GET_ALL_DASHBOARD_REPORT_SUCCESS,
            payload: response?.data?.data || {},
          });
          return response?.data?.data || {};
        }
      } catch (error) {
        dispatch({ type: DASHBOARD_REPORT_FAILURE, payload: { error } });
      }
    };
  };