import { combineReducers } from "redux";
import { loginReducer, signupReducer } from "./reducers/login";
import { profileReducer } from "./reducers/profile";
import { userReducer } from "./reducers/user";
import { getAllRequestedOfferReducer, offerReducer } from "./reducers/offer";
import { adminNewsReducer } from "./reducers/adminNews";
import { adminBlogsReducer } from "./reducers/adminBlogs";
import { AffiliateInvoicesReducer} from "./reducers/affiliateInvoice";
import { reportReducer } from "./reducers/reportsAndAnalytics";
import { getAllAdminInvoicesReducer } from "./reducers/adminInvoice";
import { affiliateReportReducer } from "./reducers/affiliateReports";
import { dashboardReportReducer } from "./reducers/dashboardReports";
import { singleProfileReducer } from "./reducers/singleUserProfile";


const rootReducer = combineReducers({
    loginUser: loginReducer,
    signupUser: signupReducer,
    profileData: profileReducer,
    users: userReducer,
    offers: offerReducer,
    allRequestedOffer : getAllRequestedOfferReducer,
    news: adminNewsReducer,
    blogs:adminBlogsReducer,
    affiliateInvoice:AffiliateInvoicesReducer,
    adminInvoice:getAllAdminInvoicesReducer,
    reports: reportReducer,
    affiliateReports: affiliateReportReducer,
    dashboardReport: dashboardReportReducer,
    singleUserProfile: singleProfileReducer

})

export default rootReducer;