import React, { useEffect } from "react";
import {
  Button,
  Form,
  Modal,
  Popconfirm,
  Space,
  Table,
  Upload,
  notification,
} from "antd";
import { useState } from "react";
import { Input } from "antd";
import {
  CaretDownOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import { Dropdown } from "antd";
import Layout from "../../../../common/layout";
import addIcon from "../../../../common/assets/images/addImage.svg";
import TextArea from "antd/es/input/TextArea";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  addBlogs,
  deleteBlogs,
  getAllblogs,
  searchBlogs,
  updateBlogs,
} from "../../../../redux/actions/adminBlogs";
import emptyImage from "../../../../common/assets/images/emptyImage.jpg";

const { Search } = Input;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const convertImageUrlToObject = async (imageUrl) => {
  const response = await fetch(imageUrl);
  const blob = await response.blob();

  // Create a File object with the Blob data
  const file = new File([blob], "image.jpg", { type: "image/jpeg" });
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      const fileObject = {
        uid: Date.now().toString(), // Generate a unique ID
        size: img.size, // You can set the actual size here if available
        name: imageUrl.split("/").pop(), // Extract the file name from the URL
        url: imageUrl,
        type: "image/jpeg", // Set the appropriate content type
        lastModified: Date.now(), // Set the current timestamp as last modified
        lastModifiedDate: new Date(),
        status: "done", // You can set the initial status here
        percent: 100,
        thumbUrl: imageUrl, // You can set a thumbnail URL if needed
        originFileObj: file, // You can set the original file object here if available
        response: null,
        error: null,
        linkProps: null,
        xhr: null,
        preview: imageUrl, // Set the preview URL
      };

      resolve(fileObject);
      return fileObject;
    };

    img.onerror = (error) => {
      reject(error);
    };
  });
};

const Blogs = () => {
  const [api, contextHolder] = notification.useNotification();

  const allblogs = useSelector((store) => store.blogs);
  const [updateForm] = Form.useForm();

  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [currPage, setCurrPage] = useState(1);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [itemCount, setItemCount] = useState(allblogs?.data?.length);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [previewImage, setPreviewImage] = useState("");
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm();
  const currentUserRole = localStorage.getItem("profile");
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const handleChangeP = ({ fileList: newFileList }) => setFileList(newFileList);
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const handleDeleteUser = async () => {
    const deleteUserResult = await dispatch(deleteBlogs(id));
    if (deleteUserResult?.data) {
      api.success({
        message: deleteUserResult?.message || "Success",
        duration: 5,
      });
    } else {
      api.error({
        message: deleteUserResult?.message || "Something went wrong",
        duration: 5,
      });
    }
  };

  const onFinish = async ({ title, image, description }) => {
    let formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    if (image && image.length > 0) {
      // If a new image is selected, use it
      formData.append("image", image[0].originFileObj);
    } else {
      formData.append("image", "");
    }

    const result = await dispatch(addBlogs(formData));
    if (result?.data) {
      setModalOpen(false);
      api.success({
        message: result?.message || "Success",
        duration: 5,
      });
      form.resetFields();
    } else {
      api.error({
        message: result?.error || "Something went wrong",
        duration: 5,
      });
    }
  };

  const items = [
    {
      label: "Edit",
      key: "edit",
      icon: <EditOutlined />,
    },
    {
      label: (
        <Popconfirm
          description="Are you sure you want to delete this blog?"
          okText="Yes"
          cancelText="No"
          onConfirm={handleDeleteUser}
        >
          <DeleteOutlined />
          &nbsp; Delete
        </Popconfirm>
      ),
      key: "delete",

      icon: "",
    },
  ];

  const handleCancelModal = () => {
    setModalOpen(false);
    form.resetFields();
  };

  const handleMenuClick = (id, image, title, description, e) => {
    const { key } = e;
    setId(id);
    setTitle(title);
    setDescription(description);
    if (key === "edit") {
      if (image && image.length > 0) {
        // If a new image is selected, use it
        // formData.append("image", image[0].originFileObj);
        convertImageUrlToObject(image)
          .then((result) => {
            setUpdateModalOpen(true);
            updateForm.setFieldsValue({
              key: id,
              image: [result],
              title: title,
              description: description,
            });
          })
          .catch((error) => {
            console.error("Error converting image:", error);
          });
      } else {
        setUpdateModalOpen(true);
        updateForm.setFieldsValue({
          key: id,
          title: title,
          description: description,
        });
      }
    } else if (key === "delete") {
      return;
    }
  };

  const data =
    allblogs?.data?.length > 0
      ? allblogs?.data?.map((item) => {
          return {
            id: item._id,
            newsImage: (
              <img
                src={item.image || emptyImage}
                alt="blogs images"
                width={50}
                height={50}
              />
            ),

            title: item.title,
            description: <p className="truncate">{item.description}</p>,
            createdAt: moment(item.createdAt).format("DD/MM/YYYY"),
            action: (
              <Dropdown
                menu={{
                  items,
                  onClick: (e) =>
                    handleMenuClick(
                      item._id,
                      item.image,
                      item.title,
                      item.description,
                      e
                    ),
                }}
                className="rounded-2xl bg-[#E3E0E0] border-[#B6B5B5]"
              >
                <Button>
                  <Space>
                    Action
                    <CaretDownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            ),
          };
        })
      : [];

  const handleChange = (pagination, filters, sorter) => {
    setCurrPage(pagination.current);
    setPageSize(pagination.pageSize);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "newsImage",
      key: "newsImage",
      filteredValue: filteredInfo.newsImage || null,
      onFilter: (value, record) => record.newsImage.includes(value),
      sorter: (a, b) => a.newsImage.length - b.newsImage.length,
      sortOrder: sortedInfo.columnKey === "newsImage" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      filteredValue: filteredInfo.title || null,
      onFilter: (value, record) => record.title.includes(value),
      sorter: (a, b) => a.title.length - b.title.length,
      sortOrder: sortedInfo.columnKey === "title" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",

      filteredValue: filteredInfo.description || null,
      onFilter: (value, record) => record.description.includes(value),
      sorter: (a, b) => a.description.length - b.description.length,
      sortOrder:
        sortedInfo.columnKey === "description" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",

      filteredValue: filteredInfo.createdAt || null,
      onFilter: (value, record) => record.createdAt.includes(value),
      sorter: (a, b) => a.createdAt.length - b.createdAt.length,
      sortOrder: sortedInfo.columnKey === "createdAt" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      filteredValue: filteredInfo.action || null,
      onFilter: (value, record) => record.action.includes(value),
      sorter: (a, b) => a.action.length - b.action.length,
      sortOrder: sortedInfo.columnKey === "action" ? sortedInfo.order : null,
      ellipsis: true,
    },
  ];
  // Conditionally filter out columns based on user role
  const filteredColumns = columns.filter((column) => {
    // Define logic to determine if the column should be visible for the current user role
    if (currentUserRole === "businessDirector") {
      return true; // Show all columns for admin
    } else if (currentUserRole === "partnershipView") {
      return column.key !== "action"; // Hide column with key 'key2' for regular users
    }
    return true; // Default to showing the column
  });
  const handleImageRemove = (e) => {
    if (e.status === "done") {
      updateForm.setFieldsValue({
        key: id,
        title: title,
        description: description,
      });
    }
  };
  const onUpdateFinish = async ({ title, image, description }) => {
    let formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    if (image && image.length > 0) {
      // If a new image is selected, use it
      formData.append("image", image[0].originFileObj);
    } else {
      formData.append("image", "");
    }
    const updateUserResult = await dispatch(updateBlogs(id, formData));
    if (updateUserResult?.data) {
      setUpdateModalOpen(false);
      updateForm.resetFields();
      api.success({
        message: updateUserResult?.message || "Success",
        duration: 3,
      });
    } else {
      api.error({
        message: updateUserResult?.error || "Something went wrong",
        duration: 3,
      });
      setUpdateModalOpen(false);
    }
  };

  const onUpdateFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onSearch = (value) => dispatch(searchBlogs(value.target.value));

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const paginationConfig = {
    pageSize: pageSize, // Number of items to display per page
    total: itemCount || 0, // Total number of items in the data source
    showSizeChanger: true, // Show the "Show X items per page" dropdown
    showQuickJumper: true, // Show the quick jumper input field
    pageSizeOptions: ["10", "20", "30"], // Options for items per page dropdown
  };

  useEffect(() => {
    (async () => {
      const response = await dispatch(getAllblogs(pageSize, currPage));
      setItemCount(response?.itemCount);
    })();

    // eslint-disable-next-line
  }, [pageSize, currPage]);

  return (
    <Layout>
      {contextHolder}
      <div>
        <h1 className="pt-5 font-semibold text-2xl font-Mulish">Blogs</h1>
        <div className="bg-[#fff] w-full flex my-4 justify-between shadow">
          <div className="w-[50%]">
            <div className="flex">
              <button
                className="py-4 px-3 border-r-2 border-[#ACA8A8] pr-5 text-xl font-Mulish font-semibold"
                onClick={() => setPageSize(allblogs?.data?.length || 10)}
              >
                All Blogs
              </button>
              {currentUserRole === "businessDirector" ? (
                <div className="flex items-center pl-5 gap-3">
                  <img
                    alt=""
                    src={addIcon}
                    className="mb-[-2px] cursor-pointer"
                    onClick={() => setModalOpen(true)}
                  />
                  <button
                    className="align-top pt-4 pb-3 text-xl font-Mulish font-semibold"
                    onClick={() => setModalOpen(true)}
                  >
                    Add Blogs
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>

            <Modal
              title=""
              centered
              open={modalOpen}
              onOk={() => setModalOpen(true)}
              onCancel={handleCancelModal}
              className="p-0 modal_parent_wrapper content-form-wrapper news_modal_wrapper"
              footer={[
                <Button
                  key="submit"
                  type="primary"
                  className="bg-[#002366] rounded-[50px] min-w-[130px] min-h-[45px] capitalize"
                  form="myForm"
                  htmlType="submit"
                >
                  submit
                </Button>,
              ]}
            >
              <h2 className="uppercase text-center bg-[#002366] text-white mb-4 p-4 text-[25px] font-medium">
                ADD BLOGS
              </h2>

              <Form
                name="basic"
                layout="vertical"
                form={form}
                id="myForm"
                className="p-4"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                autoComplete="off"
              >
                <Form.Item
                  name="image"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                >
                  <Upload
                    action={true}
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChangeP}
                    className="text-center"
                    name="image"
                    maxCount={1}
                    beforeUpload={() => false}
                  >
                    {fileList.length >= 8 ? null : uploadButton}
                  </Upload>
                </Form.Item>
                <Modal
                  open={previewOpen}
                  title={previewTitle}
                  footer={null}
                  onCancel={handleCancel}
                >
                  <img
                    alt="example"
                    style={{ width: "100%" }}
                    src={previewImage}
                  />
                </Modal>

                <div className="pt-5">
                  <Form.Item
                    label="Title"
                    name="title"
                    className="font-semibold mb-3 w-full"
                    rules={[
                      {
                        required: true,
                        message: "Please input content title!",
                      },
                    ]}
                  >
                    <Input className=" py-2 rounded-2xl w-full" />
                  </Form.Item>

                  <Form.Item
                    label="Description"
                    name="description"
                    className="font-semibold mb-3 w-full"
                    rules={[
                      {
                        required: true,
                        message: "Please input description!",
                      },
                    ]}
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </div>
              </Form>
            </Modal>

            <Modal
              title=""
              centered
              form={updateForm}
              open={updateModalOpen}
              className="p-0 modal_parent_wrapper"
              onOk={() => setUpdateModalOpen(false)}
              onCancel={() => setUpdateModalOpen(false)}
              footer={[
                <Button
                  form="myUpdateForm"
                  key="submit"
                  htmlType="submit"
                  type="primary"
                  className="bg-[#002366] rounded-[50px] min-w-[130px] min-h-[45px]"
                >
                  Update
                </Button>,
              ]}
            >
              <h2 className="uppercase text-center bg-[#002366] text-white mb-4 p-4 text-[25px] font-medium">
                Update Blogs
              </h2>
              <Form
                name="basic"
                layout="vertical"
                id="myUpdateForm"
                form={updateForm}
                style={{ maxWidth: 600 }}
                className="p-4"
                initialValues={{ remember: true }}
                onFinish={onUpdateFinish}
                onFinishFailed={onUpdateFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  name="image"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                >
                  <Upload
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChangeP}
                    onRemove={handleImageRemove}
                    className="text-center"
                    name="image"
                    maxCount={1}
                    beforeUpload={() => false}
                  >
                    {fileList.length >= 8 ? null : uploadButton}
                  </Upload>
                </Form.Item>
                <Modal
                  open={previewOpen}
                  title={previewTitle}
                  footer={null}
                  onCancel={handleCancel}
                >
                  <img
                    alt="example"
                    style={{ width: "100%" }}
                    src={previewImage}
                  />
                </Modal>

                <div className="pt-5">
                  <Form.Item
                    label="Title"
                    name="title"
                    className="font-semibold mb-3 w-full"
                    rules={[
                      {
                        required: true,
                        message: "Please input content title!",
                      },
                    ]}
                  >
                    <Input className=" py-2 rounded-2xl w-full" />
                  </Form.Item>

                  <Form.Item
                    label="Description"
                    name="description"
                    className="font-semibold mb-3 w-full"
                    rules={[
                      {
                        required: true,
                        message: "Please input description!",
                      },
                    ]}
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </div>
              </Form>
            </Modal>
          </div>
          <div className="w-[50%] flex justify-end pt-4 pb-2 pr-3">
            <Search
              placeholder="search..."
              onChange={onSearch}
              enterButton
              className="w-1/2 user_Search"
            />
          </div>
        </div>

        <Table
        
          columns={filteredColumns}
          dataSource={data}
          onChange={handleChange}
          className="userTable"
          loading={allblogs?.loading}
          pagination={paginationConfig}
        />
      </div>
    </Layout>
  );
};

export default Blogs;
