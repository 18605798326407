import axios from "../../utils/interceptor/index";
import { GET_ALL_AFFILIATE_REPORT_URL } from "../../utils/api/urls";
import { AFFILIATE_REPORT_FAILURE, AFFILIATE_REPORT_REQUEST, GET_ALL_AFFILIATE_REPORT_SUCCESS } from "../constants";


export const getAllAffiliateReports = (page = 10, currPage = 1, monthFilter=0 ) => {
    return async (dispatch) => {
      dispatch({ type: AFFILIATE_REPORT_REQUEST });
      try {
        const headers = {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          // Add any other headers if needed
        };
        const response = await axios.get(
          `${GET_ALL_AFFILIATE_REPORT_URL}?limit=${page}&pageNo=${currPage}&month=${monthFilter}`,
          { headers }
        );
        
        if (response?.data) {
          dispatch({
            type: GET_ALL_AFFILIATE_REPORT_SUCCESS,
            payload: response?.data?.data?.paginatedData || [],
          });
          return response?.data?.data || [];
        }
      } catch (error) {
        dispatch({ type: AFFILIATE_REPORT_FAILURE, payload: { error } });
      }
    };
  };