import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  CaretDownOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Form,
  Input,
  Modal,
  Popconfirm,
  Radio,
  Select,
  Space,
  Table,
  notification,
} from "antd";
import bitcoin from "../../../../common/assets/images/bitcoin.png";
import wireTransfer from "../../../../common/assets/images/wireTransfer.png";
import paypal from "../../../../common/assets/images/paypal.png";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAffiliateInvoice,
  generateInvoice,
  getAllInvoices,
  updateInvoice,
} from "../../../../redux/actions/affiliateInvoice";

const PaymentMethod = () => {
  const dispatch = useDispatch();
  const allInvoices = useSelector((store) => store?.affiliateInvoice?.data);
  const [modalOpen, setModalOpen] = useState(false);
  const [editPaymentModal, setEditPaymentModal] = useState(false);
  const [showPayementMethods, setShowPayementMethods] = useState(false);
  const [isCheck, setIsCheck] = useState("");
  const [accountDetails, setAccountDetails] = useState("");
  const [payoutAmount,setPayoutAmount] = useState(0);
  const [payoutMonth,setPayoutMonth] = useState();
  const [itemCount, setItemCount] = useState(allInvoices?.data?.length);
  const [api, contextHolder] = notification.useNotification();
  const [pageSize, setPageSize] = useState(10);
  const [currPage, setCurrPage] = useState(1);
  const [id, setId] = useState();
  const [form] = Form.useForm();
  const [updateForm] = Form.useForm();

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthOptions = [];
  for (let i = 0; i < months.length; i++) {
    monthOptions.push({
      value:  i,
      label: months[i],
    });
  }
  const handleEditPaymentMethods = async (
    e,
    id,
    currency,
    paymentMethod,
    payoutAmount,
    payoutMonth,
    paymentInfo
  ) => {
    const { key } = e;
    if (key === "edit") {
      setId(id);
      updateForm.setFieldsValue({
        key: id,
        currency: currency,
        paymentMethod: paymentMethod,
        payoutAmount:payoutAmount,
        payoutMonth:payoutMonth,
        paymentInfo: paymentInfo,
      });
      setIsCheck(paymentMethod);
      setAccountDetails(paymentInfo);
      setEditPaymentModal(true);
    } else {
      setId(id);
    }
  };
  const handleUserDeleteCancel = () => {
    // setDeletePopup(false)
  };
  const handleDeleteOffer = async () => {
    const deleteInvoice = await dispatch(deleteAffiliateInvoice(id));
    if (deleteInvoice?.data) {
      api.success({
        message: deleteInvoice?.message || "Success",
        duration: 3,
      });
    } else {
      api.error({
        message: deleteInvoice?.message || "Something went wrong",
        duration: 3,
      });
    }
  };
  const items = [
    {
      label: "Edit",
      key: "edit",
      icon: <EditOutlined />,
    },
    {
      label: (
        <Popconfirm
          description="Are you sure you want to delete this Invoice?"
          okText="Yes"
          cancelText="No"
          onConfirm={handleDeleteOffer}
          onCancel={handleUserDeleteCancel}
          style={{ maxWidth: "100px" }}
        >
          <DeleteOutlined />
          &nbsp; Delete
        </Popconfirm>
      ),
      key: "delete",

      icon: "",
    },
  ];

  const columns = [
    {
      title: "Currency",
      dataIndex: "currency",
    },
    {
      title: "Payment Method",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 3,
      },
    },
    {
      title: "Payout",
      dataIndex: "payoutAmount",
      sorter: {
        compare: (a, b) => a.math - b.math,
        multiple: 2,
      },
    },
    {
      title: "Payout Month",
      dataIndex: "payoutMonth",
      sorter: {
        compare: (a, b) => a.math - b.math,
        multiple: 2,
      },
    },
    // {
    //   title: "Balance Due",
    //   dataIndex: "balanceDue",
    //   sorter: {
    //     compare: (a, b) => a.math - b.math,
    //     multiple: 2,
    //   },
    // },
    // {
    //   title: "Next Invoice Date",
    //   dataIndex: "nextInvoiceDate",
    //   sorter: {
    //     compare: (a, b) => a.english - b.english,
    //     multiple: 1,
    //   },
    // },
    {
      title: "Payment Status",
      dataIndex: "payoutStatus",
      sorter: {
        compare: (a, b) => a.english - b.english,
        multiple: 1,
      },
    },
    {
      title: "Action",
      dataIndex: "action",
    },
  ];

  const data = allInvoices?.map((invoice) => {
    return {
      key: invoice._id,
      currency: invoice.currency,
      paymentMethod: <p className="truncate ... w-28">{invoice.paymentInfo}</p>,
      payoutAmount: invoice.payoutAmount,
      // balanceDue: "$0,00",
      payoutMonth:months[invoice.payoutMonth] ,
      payoutStatus:
        invoice.status === "reject" ? (
          <p className="font-Mulish font-semibold text-base text-[#FC0303]">
            Rejected
          </p>
        ) : invoice.status === "pending" ? (
          <p className="font-Mulish font-semibold text-base text-[#FFA500]">
            Pending
          </p>
        ) : invoice.status === "approve" ? (
          <p className="font-Mulish font-semibold text-base text-[#047B25]">
            Approved
          </p>
        ) : null,

      action: (
        <Dropdown
          menu={{
            items,
            onClick: (e) =>
              handleEditPaymentMethods(
                e,
                invoice._id,
                invoice.currency,
                invoice.paymentMethod,
                invoice.payoutAmount,
                invoice.payoutMonth,
                invoice.paymentInfo
              ),
          }}
          className="rounded-2xl bg-[#E3E0E0] border-[#B6B5B5]"
        >
          <Button>
            <Space>
              Action
              <CaretDownOutlined />
            </Space>
          </Button>
        </Dropdown>
      ),
    };
  });

  const onCurrencyChange = () => {
    setShowPayementMethods(true);
  };
  const handlePayementMethods = (value) => {
    setIsCheck(value.target.value);
  };
  const handleSavePaymentMethod = async ({ currency, paymentMethod }) => {
    const values = {
      currency: currency,
      paymentMethod: paymentMethod,
      payoutAmount:payoutAmount,
      payoutMonth:payoutMonth,
      paymentInfo: accountDetails,
    };
    const invoice = await dispatch(generateInvoice(values));
    if (invoice?.data) {
      form.resetFields();
      setAccountDetails(null);
      setShowPayementMethods(false);
      setIsCheck(null);
      setModalOpen(false);
    }
  };
  const handleTextAreaChange = (e) => {
    setAccountDetails(e.target.value); // Update text area value
  };
  const paymentInfoValidator = (rule, value, callback) => {
    if (rule.field === "paymentInfo" && accountDetails.length <= 0) {
      callback("Please enter account details.");
    } else {
      callback();
    }
  };
  const numberValidation = (rule, value, callback) => {
    const numberPattern = /^[0-9]*$/;
    if (numberPattern.test(value)) {
      callback();
    } else {
      callback("Please enter only numbers");
    }
  };
  const paginationConfig = {
    pageSize: pageSize, // Number of items to display per page
    total: itemCount || 0, // Total number of items in the data source
    showSizeChanger: true, // Show the "Show X items per page" dropdown
    showQuickJumper: true, // Show the quick jumper input field
    pageSizeOptions: ["10", "20", "30"], // Options for items per page dropdown
  };
  const onChangeTable = (pagination, filters, sorter, extra) => {
    setCurrPage(pagination.current);
    setPageSize(pagination.pageSize);
  };
  const onUpdateFinish = async ({ currency, paymentMethod}) => {
    const values = {
      currency: currency,
      paymentMethod: paymentMethod,
      paymentInfo: accountDetails,
      payoutAmount:payoutAmount,
      payoutMonth:payoutMonth,
    };
    const response = await dispatch(updateInvoice(id, values));
    if (response?.data) {
      setEditPaymentModal(false);
      api.success({
        message: response?.message || "Success",
        duration: 3,
      });
    } else {
      api.error({
        message: response?.message || "Something went wrong",
        duration: 3,
      });
    }
  };
  useEffect(() => {
    (async () => {
      const allAdminInvoices = await dispatch(
        getAllInvoices(pageSize, currPage)
      );
      setItemCount(allAdminInvoices?.itemCount);
    })();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {contextHolder}
      <div className="flex justify-between mt-3 mb-5">
        <h1 className="capitalize font-semibold text-xl">Payment Methods</h1>
        <button
          className="bg-[#002366] text-white py-2 px-4 rounded-[7px] flex items-center gap-3"
          onClick={() => setModalOpen(true)}
        >
          <PlusOutlined /> Add Payment Method
        </button>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        onChange={onChangeTable}
        className="userTable"
        pagination={paginationConfig}
        loading={allInvoices?.loading}
      />
      <Modal
        title="Add Payment Method"
        centered
        open={modalOpen}
        onOk={() => setModalOpen(false)}
        onCancel={() => {
          setModalOpen(false);
          form.resetFields();
          setShowPayementMethods(false);
          setIsCheck(null);
        }}
        okText="Save"
        width={600}
        className="paymentMethodsModal"
        footer={[
          <Button
            form="paymentMethodForm"
            key="submit"
            htmlType="submit"
            type="primary"
            className="bg-[#002366] rounded-[50px] min-w-[130px] min-h-[45px]"
          >
            Submit
          </Button>,
        ]}
      >
        <Form
          name="basic"
          layout="vertical"
          id="paymentMethodForm"
          form={form}
          style={{ maxWidth: 600 }}
          className="p-4 paymentMethodForms"
          initialValues={{ remember: true }}
          onFinish={handleSavePaymentMethod}
          // onFinishFailed={onUpdateFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Currency"
            className="font-semibold mb-3 w-1/2"
            name="currency"
          >
            <Select
              className="w-full"
              onChange={onCurrencyChange}
              defaultValue={"select"}
              options={[
                // { value: "", label: "Select" },
                { value: "usdollar", label: "USD - US Dollar" },
                { value: "euro", label: "Euro" },
                { value: "pound", label: "Pound" },
              ]}
            />
          </Form.Item>
          {showPayementMethods ? (
            <>
              <div className="pt-5">
                <Form.Item name="paymentMethod">
                  <Radio.Group
                    className="w-full paymentMethods"
                    onChange={handlePayementMethods}
                    name="paymentMethod"
                  >
                    <Space direction="vertical" className="w-full">
                      <Radio
                        value="bitcoin"
                        className="w-full bg-[#EEE] p-3 mb-1"
                      >
                        <div className="w-full flex justify-between">
                          <div className="">
                            <h3 className="font-semibold text-base">Bitcoin</h3>
                            <p>Minimum Threshold : $100,00</p>
                            <p>Commission: 0%</p>
                          </div>
                          <img
                            src={bitcoin}
                            alt="bitcoin"
                            className="w-[64px] h-[64px]"
                          />
                        </div>
                        {isCheck === "bitcoin" ? (
                          <div>
                            <div>
                              <div className="flex gap-2">
                                <Form.Item
                                  name="payoutAmount"
                                  label="Enter Payout"
                                  className="font-semibold w-1/2"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input a number!",
                                    },
                                    {
                                      validator: numberValidation, // Use the custom validation function
                                    },
                                  ]}
                                >
                                  <Input placeholder="Enter payout" onChange={(e)=>setPayoutAmount(e.target.value)} />
                                </Form.Item>
                                <Form.Item
                                  name="payoutMonth"
                                  label="Select Payout Month"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please Select Month",
                                    },
                                  ]}
                                >
                                  <Select
                                  removeIcon
                                    defaultValue={months[0]}
                                    className="w-full block paymentSelect"
                                    style={{width: 230}}
                                    onChange={(e) => setPayoutMonth(e)}
                                    // placeholder="please select month"
                                    
                                  >
                                    {months.map((month, index) => (
                                      <Select.Option value={index}>
                                        {month}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </div>

                              <Form.Item
                                name="paymentInfo"
                                rules={[
                                  // {
                                  //   required: true,
                                  //   message: "Please enter account details",
                                  // },
                                  {
                                    validator: paymentInfoValidator, // Use the custom validation function
                                  },
                                ]}
                              >
                                <Input.TextArea
                                  placeholder="Account ID, Number, etc"
                                  rows={4}
                                  value={accountDetails}
                                  onChange={handleTextAreaChange}
                                />
                                <p className="mt-1">
                                  Please enter your payment details for the
                                  selected payment method.
                                </p>
                              </Form.Item>
                            </div>
                          </div>
                        ) : null}
                      </Radio>
                      <Radio
                        value="wireTransfer"
                        className="w-full bg-[#EEE] p-3 mb-1"
                      >
                        <div className="w-full flex justify-between">
                          <div className="">
                            <h3 className="font-semibold text-base">
                              Wire Transfer
                            </h3>
                            <p>Minimum Threshold : $100,00</p>
                            <p>Commission: 0%</p>
                          </div>
                          <img
                            src={wireTransfer}
                            alt="wireTransfer"
                            className="w-[64px] h-[64px]"
                          />
                        </div>
                        {isCheck === "wireTransfer" ? (
                          <div>
                            <div>
                            <div className="flex gap-2">
                                <Form.Item
                                  name="payoutAmount"
                                  label="Enter Payout"
                                  className="font-semibold w-1/2"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input a number!",
                                    },
                                    {
                                      validator: numberValidation, // Use the custom validation function
                                    },
                                  ]}
                                >
                                  <Input placeholder="Enter payout" onChange={(e)=>setPayoutAmount(e.target.value)} />
                                </Form.Item>
                                <Form.Item
                                  name="payoutMonth"
                                  label="Select Payout Month"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please Select Month",
                                    },
                                  ]}
                                >
                                  <Select
                                  removeIcon
                                    defaultValue={months[0]}
                                    className="w-full block paymentSelect"
                                    style={{width: 230}}
                                    onChange={(e) => setPayoutMonth(e)}
                                    // placeholder="please select month"
                                  >
                                    {months.map((month, index) => (
                                      <Select.Option value={index}>
                                        {month}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </div>
                              <Form.Item
                                name="paymentInfo"
                                rules={[
                                  {
                                    validator: paymentInfoValidator, // Use the custom validation function
                                  },
                                ]}
                              >
                                <Input.TextArea
                                  placeholder="Account ID, Number, etc"
                                  rows={4}
                                  value={accountDetails}
                                  onChange={handleTextAreaChange}
                                />
                                <p className="mt-1">
                                  Please enter your payment details for the
                                  selected payment method.
                                </p>
                              </Form.Item>
                            </div>
                          </div>
                        ) : null}
                      </Radio>
                      <Radio
                        value="paypal"
                        className="w-full bg-[#EEE] p-3 mb-1"
                      >
                        <div className="w-full flex justify-between">
                          <div className="">
                            <h3 className="font-semibold text-base">Paypal</h3>
                            <p>Minimum Threshold : $100,00</p>
                            <p>Commission: 0%</p>
                          </div>
                          <img
                            src={paypal}
                            alt="paypal"
                            className="w-[64px] h-[64px]"
                          />
                        </div>
                        {isCheck === "paypal" ? (
                          <div>
                                   <div className="flex gap-2">
                                <Form.Item
                                  name="payoutAmount"
                                  label="Enter Payout"
                                  className="font-semibold w-1/2"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input a number!",
                                    },
                                    {
                                      validator: numberValidation, // Use the custom validation function
                                    },
                                  ]}
                                >
                                  <Input placeholder="Enter payout" onChange={(e)=>setPayoutAmount(e.target.value)} />
                                </Form.Item>
                                <Form.Item
                                  name="payoutMonth"
                                  label="Select Payout Month"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please Select Month",
                                    },
                                  ]}
                                >
                                  <Select
                                  removeIcon
                                    defaultValue={months[0]}
                                    className="w-full block paymentSelect"
                                    style={{width: 230}}
                                    onChange={(e) => setPayoutMonth(e)}
                                    // placeholder="please select month"
                                  >
                                    {months.map((month, index) => (
                                      <Select.Option value={index}>
                                        {month}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </div>
                            <Form.Item
                              name="paymentInfo"
                              rules={[
                                {
                                  validator: paymentInfoValidator, // Use the custom validation function
                                },
                              ]}
                            >
                              <Input.TextArea
                                placeholder="Account ID, Number, etc"
                                rows={4}
                                value={accountDetails}
                                onChange={handleTextAreaChange}
                              />
                              <p className="mt-1">
                                Please enter your payment details for the
                                selected payment method.
                              </p>
                            </Form.Item>
                          </div>
                        ) : null}
                      </Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </div>
            </>
          ) : (
            ""
          )}
        </Form>
      </Modal>
      <Modal
        title="Update Payment Method"
        centered
        open={editPaymentModal}
        onOk={() => setEditPaymentModal(false)}
        onCancel={() => setEditPaymentModal(false)}
        width={600}
        className="paymentMethodsModal"
        okText="Save"
        footer={[
          <Button
            form="myUpdateForm"
            key="submit"
            htmlType="submit"
            type="primary"
            className="bg-[#002366] rounded-[50px] min-w-[130px] min-h-[45px]"
          >
            Submit
          </Button>,
        ]}
      >
        <Form
          name="basic"
          layout="vertical"
          id="myUpdateForm"
          form={updateForm}
          style={{ maxWidth: 600 }}
          className="p-4"
          initialValues={{ remember: true }}
          onFinish={onUpdateFinish}
          // onFinishFailed={onUpdateFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Currency"
            className="font-semibold mb-3 w-1/2"
            name="currency"
          >
            <Select
              className="w-full"
              onChange={onCurrencyChange}
              defaultValue={"select"}
              options={[
                // { value: "", label: "Select" },
                { value: "usdollar", label: "USD - US Dollar" },
                { value: "euro", label: "Euro" },
                { value: "pound", label: "Pound" },
              ]}
            />
          </Form.Item>
          {/* {showPayementMethods ? (
            <> */}
          <div className="pt-5">
            <Form.Item name="paymentMethod">
              <Radio.Group
                className="w-full paymentMethods"
                onChange={handlePayementMethods}
                name="paymentMethod"
              >
                <Space direction="vertical" className="w-full">
                  <Radio value="bitcoin" className="w-full bg-[#EEE] p-3 mb-1">
                    <div className="w-full flex justify-between">
                      <div className="">
                        <h3 className="font-semibold text-base">Bitcoin</h3>
                        <p>Minimum Threshold : $100,00</p>
                        <p>Commission: 0%</p>
                      </div>
                      <img
                        src={bitcoin}
                        alt="bitcoin"
                        className="w-[64px] h-[64px]"
                      />
                    </div>
                    {isCheck === "bitcoin" ? (
                      <div>
                        <div>
                        <div className="flex gap-2">
                                <Form.Item
                                  name="payoutAmount"
                                  label="Enter Payout"
                                  className="font-semibold w-1/2"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input a number!",
                                    },
                                    {
                                      validator: numberValidation, // Use the custom validation function
                                    },
                                  ]}
                                >
                                  <Input placeholder="Enter payout" onChange={(e)=>setPayoutAmount(e.target.value)} />
                                </Form.Item>
                                <Form.Item
                                  name="payoutMonth"
                                  label="Select Payout Month"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please Select Month",
                                    },
                                  ]}
                                >
                                  <Select
                                  removeIcon
                                    defaultValue={months[0]}
                                    className="w-full block paymentSelect"
                                    style={{width: 230}}
                                    onChange={(e) => setPayoutMonth(e)}
                                    // placeholder="please select month"
                                  >
                                    {months.map((month, index) => (
                                      <Select.Option value={index}>
                                        {month}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </div>
                          <Form.Item
                            name="paymentInfo"
                            rules={[
                              // {
                              //   required: true,
                              //   message: "Please enter account details",
                              // },
                              {
                                validator: paymentInfoValidator, // Use the custom validation function
                              },
                            ]}
                          >
                            <Input.TextArea
                              placeholder="Account ID, Number, etc"
                              rows={4}
                              value={accountDetails}
                              onChange={handleTextAreaChange}
                            />
                            <p className="mt-1">
                              Please enter your payment details for the selected
                              payment method.
                            </p>
                          </Form.Item>
                        </div>
                      </div>
                    ) : null}
                  </Radio>
                  <Radio
                    value="wireTransfer"
                    className="w-full bg-[#EEE] p-3 mb-1"
                  >
                    <div className="w-full flex justify-between">
                      <div className="">
                        <h3 className="font-semibold text-base">
                          Wire Transfer
                        </h3>
                        <p>Minimum Threshold : $100,00</p>
                        <p>Commission: 0%</p>
                      </div>
                      <img
                        src={wireTransfer}
                        alt="wireTransfer"
                        className="w-[64px] h-[64px]"
                      />
                    </div>
                    {isCheck === "wireTransfer" ? (
                      <div>
                        <div>
                        <div className="flex gap-2">
                                <Form.Item
                                  name="payoutAmount"
                                  label="Enter Payout"
                                  className="font-semibold w-1/2"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input a number!",
                                    },
                                    {
                                      validator: numberValidation, // Use the custom validation function
                                    },
                                  ]}
                                >
                                  <Input placeholder="Enter payout" onChange={(e)=>setPayoutAmount(e.target.value)} />
                                </Form.Item>
                                <Form.Item
                                  name="payoutMonth"
                                  label="Select Payout Month"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please Select Month",
                                    },
                                  ]}
                                >
                                  <Select
                                  removeIcon
                                    defaultValue={months[0]}
                                    className="w-full block paymentSelect"
                                    style={{width: 230}}
                                    onChange={(e) => setPayoutMonth(e)}
                                    // placeholder="please select month"
                                  >
                                    {months.map((month, index) => (
                                      <Select.Option value={index}>
                                        {month}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </div>
                          <Form.Item
                            name="paymentInfo"
                            rules={[
                              {
                                validator: paymentInfoValidator, // Use the custom validation function
                              },
                            ]}
                          >
                            <Input.TextArea
                              placeholder="Account ID, Number, etc"
                              rows={4}
                              value={accountDetails}
                              onChange={handleTextAreaChange}
                            />
                            <p className="mt-1">
                              Please enter your payment details for the selected
                              payment method.
                            </p>
                          </Form.Item>
                        </div>
                      </div>
                    ) : null}
                  </Radio>
                  <Radio value="paypal" className="w-full bg-[#EEE] p-3 mb-1">
                    <div className="w-full flex justify-between">
                      <div className="">
                        <h3 className="font-semibold text-base">Paypal</h3>
                        <p>Minimum Threshold : $100,00</p>
                        <p>Commission: 0%</p>
                      </div>
                      <img
                        src={paypal}
                        alt="paypal"
                        className="w-[64px] h-[64px]"
                      />
                    </div>
                    {isCheck === "paypal" ? (
                      <div>
                          <div className="flex gap-2">
                                <Form.Item
                                  name="payoutAmount"
                                  label="Enter Payout"
                                  className="font-semibold w-1/2"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input a number!",
                                    },
                                    {
                                      validator: numberValidation, // Use the custom validation function
                                    },
                                  ]}
                                >
                                  <Input placeholder="Enter payout" onChange={(e)=>setPayoutAmount(e.target.value)} />
                                </Form.Item>
                                <Form.Item
                                  name="payoutMonth"
                                  label="Select Payout Month"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please Select Month",
                                    },
                                  ]}
                                >
                                  <Select
                                  removeIcon
                                    defaultValue={months[0]}
                                    className="w-full block paymentSelect"
                                    style={{width: 230}}
                                    onChange={(e) => setPayoutMonth(e)}
                                    // placeholder="please select month"
                                  >
                                    {months.map((month, index) => (
                                      <Select.Option value={index}>
                                        {month}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </div>
                        <Form.Item
                          name="paymentInfo"
                          rules={[
                            {
                              validator: paymentInfoValidator, // Use the custom validation function
                            },
                          ]}
                        >
                          <Input.TextArea
                            placeholder="Account ID, Number, etc"
                            rows={4}
                            value={accountDetails}
                            onChange={handleTextAreaChange}
                          />
                          <p className="mt-1">
                            Please enter your payment details for the selected
                            payment method.
                          </p>
                        </Form.Item>
                      </div>
                    ) : null}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </div>
          {/* </>
          ) : (
            ""
          )} */}
        </Form>
      </Modal>
    </>
  );
};

export default PaymentMethod;
