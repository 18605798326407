export const LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE";

//forgot password
export const FORGOT_PASSWORD_REQUEST = "LOGIN_USER_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "LOGIN_USER_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "LOGIN_USER_FAILURE";

//signup
export const SIGNUP_USER_REQUEST = "SIGNUP_USER_REQUEST";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNUP_USER_FAILURE = "SIGNUP_USER_FAILURE";

//profile
export const PROFILE_REQUEST = "PROFILE_REQUEST";
export const PROFILE_SUCCESS = "PROFILE_SUCCESS";
export const PROFILE_FAILURE = "PROFILE_FAILURE";
export const PROFILE_UPDATE_SUCCESS = "PROFILE_UPDATE_SUCCESS";

//approve and disapprove
export const APPROVE_DISAPPROVE_REQUEST = "APPROVE_DISAPPROVE_REQUEST";
export const APPROVE_DISAPPROVE_SUCCESS = "APPROVE_DISAPPROVE_SUCCESS";
export const APPROVE_DISAPPROVE_FAILURE = "APPROVE_DISAPPROVE_FAILURE";

// user
export const USER_REQUEST = "USER_REQUEST";
export const USER_FAILURE = "USER_FAILURE";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const GET_ALL_USER_SUCCESS = "GET_ALL_USER_SUCCESS";
export const SEARCHED_USER_SUCCESS = "SEARCHED_USER_SUCCESS"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"

// offer
export const OFFER_REQUEST = "OFFER_REQUEST";
export const OFFER_FAILURE = "OFFER_FAILURE";
export const CREATE_OFFER_SUCCESS = "CREATE_OFFER_SUCCESS";
export const UPDATE_OFFER_SUCCESS = "UPDATE_OFFER_SUCCESS";
export const DELETE_OFFER_SUCCESS = "DELETE_OFFER_SUCCESS";
export const REMOVE_PROMOTED_OFFER_SUCCESS = "REMOVE_PROMOTED_OFFER_SUCCESS";
export const GET_ALL_OFFER_SUCCESS = "GET_ALL_OFFER_SUCCESS";
export const GET_ALL_PROMOTED_OFFER_SUCCESS = "GET_ALL_PROMOTED_OFFER_SUCCESS"
export const CREATE_PROMOTE_SUCCESS = 'CREATE_PROMOTE_SUCCESS';
export const GET_ALL_MY_APPROVED_OFFER_SUCCESS = 'GET_ALL_MY_APPROVED_OFFER_SUCCESS';
export const SEARCHED_OFFER_SUCCESS = "SEARCHED_OFFER_SUCCESS"
export const SEARCHED_OFFER_BY_CATEGORY_SUCCESS = "SEARCHED_OFFER_BY_CATEGORY_SUCCESS";

// requested offers
export const GET_ALL_REQUESTED_OFFER_SUCCESS = "GET_ALL_REQUESTED_OFFER_SUCCESS";
export const UPDATE_REQUESTED_OFFER_SUCCESS = 'UPDATE_REQUESTED_OFFER_SUCCESS';
export const UPDATE_REQUESTED_OFFER_LINK_SUCCESS = 'UPDATE_REQUESTED_OFFER_LINK_SUCCESS';
export const UPDATE_OFFER_ISREQUESTED_SUCCESS = 'UPDATE_OFFER_ISREQUESTED_SUCCESS';
export const SEARCHED_REQUESTED_OFFERS = 'SEARCHED_REQUESTED_OFFERS';


//news
export const NEWS_REQUEST = 'NEWS_REQUEST'
export const NEWS_FAILURE = 'NEWS_FAILURE'
export const CREATE_NEWS_SUCCESS = 'CREATE_NEWS_SUCCESS'
export const UPDATE_NEWS_SUCCESS = 'UPDATE_NEWS_SUCCESS'
export const GET_ALL_NEWS_SUCCESS = 'GET_ALL_NEWS_SUCCESS'
export const DELETE_NEWS_SUCCESS = 'DELETE_NEWS_SUCCESS'
export const SEARCHED_NEWS_SUCCESS = 'SEARCHED_NEWS_SUCCESS'

//blogs
export const BLOGS_REQUEST = 'BLOGS_REQUEST'
export const BLOGS_FAILURE = 'BLOGS_FAILURE'
export const CREATE_BLOGS_SUCCESS = 'CREATE_BLOGS_SUCCESS'
export const UPDATE_BLOGS_SUCCESS = 'UPDATE_BLOGS_SUCCESS'
export const GET_ALL_BLOGS_SUCCESS = 'GET_ALL_BLOGS_SUCCESS'
export const DELETE_BLOGS_SUCCESS = 'DELETE_BLOGS_SUCCESS'
export const SEARCHED_BLOGS_SUCCESS = 'SEARCHED_BLOGS_SUCCESS'

//Invoice
export const INVOICE_REQUEST = 'INVOICE_REQUEST'
export const INVOICE_FAILURE = 'INVOICE_FAILURE'
//Affiliate Invoice
export const GENERATE_INVOICE_SUCCESS = 'GENERATE_INVOICE_SUCCESS';
export const GET_ALL_INVOICE_AFFILIATE = 'GET_ALL_INVOICE_AFFILIATE';
export const EDIT_INVOICE = 'EDIT_INVOICE';
export const DELETE_AFFILIATE_INVOICE = 'DELETE_AFFILIATE_INVOICE';
export const UPDATE_INVOICE_SUCCESS = 'UPDATE_INVOICE_SUCCESS';
//Admin Invoice List
export const GET_ALL_INVOICE = 'GET_ALL_INVOICE';
export const DELETE_ADMIN_INVOICE = 'DELETE_ADMIN_INVOICE';

//approve and disapprove Invoice
export const INVOICE_REVIEW_REQUEST = "INVOICE_REVIEW_REQUEST";
export const INVOICE_REVIEW_SUCCESS = "INVOICE_REVIEW_SUCCESS";
export const INVOICE_REVIEW_FAILURE = "INVOICE_REVIEW_FAILURE";

//Reports
export const REQUEST_REPORT = 'REQUEST_REPORT';
export const FAILURE_REPORT = 'FAILURE_REPORT';
export const CREATE_REPORT_SUCCESS = 'CREATE_REPORT_SUCCESS';
export const UPDATE_REPORT_SUCCESS = 'UPDATE_REPORT_SUCCESS';
export const DELETE_REPORT_SUCCESS = 'DELETE_REPORT_SUCCESS';
export const GET_ALL_REPORT_SUCCESS = 'GET_ALL_REPORT_SUCCESS';
export const SEARCHED_REPORT_SUCCESS = 'SEARCHED_REPORT_SUCCESS';


//affiliate reports
export const AFFILIATE_REPORT_REQUEST = 'AFFILIATE_REPORT_REQUEST';
export const AFFILIATE_REPORT_FAILURE = 'AFFILIATE_REPORT_FAILURE';
export const GET_ALL_AFFILIATE_REPORT_SUCCESS = 'GET_ALL_AFFILIATE_REPORT_SUCCESS';


//dashboard reports and analytics

export const DASHBOARD_REPORT_REQUEST = 'DASHBOARD_REPORT_REQUEST'
export const DASHBOARD_REPORT_FAILURE = 'DASHBOARD_REPORT_FAILURE'
export const GET_ALL_DASHBOARD_REPORT_SUCCESS = 'GET_ALL_DASHBOARD_REPORT_SUCCESS'
export const FILTER_DASHBOARD_REPORT_SUCCESS = 'FILTER_DASHBOARD_REPORT_SUCCESS'

// single user profile

export const SINGLE_USER_REQUEST = 'SINGLE_USER_REQUEST'
export const SINGLE_USER_FAILURE = 'SINGLE_USER_FAILURE'
export const GET_SINGLE_USER_SUCCESS = 'GET_SINGLE_USER_SUCCESS'

// all admin 
export const AUTHORITIES_REQUEST = 'AUTHORITIES_REQUEST'
export const AUTHORITIES_FAILURE = 'AUTHORITIES_FAILURE'
export const GET_ALL_AUTHORITIES_SUCCESS = 'GET_ALL_AUTHORITIES_SUCCESS'

//affiliate dashboard reports 
export const AFFILIATE_DASHBOARD_REPORT_REQUEST = 'AFFILIATE_DASHBOARD_REPORT_REQUEST'
export const AFFILIATE_DASHBOARD_REPORT_FAILURE = 'AFFILIATE_DASHBOARD_REPORT_FAILURE'
export const GET_ALL_AFFILIATE_DASHBOARD_REPORT_SUCCESS = 'GET_ALL_AFFILIATE_DASHBOARD_REPORT_SUCCESS'