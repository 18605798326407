import axios from "../../utils/interceptor/index";
import {
  DELETE_AFFILIATE_INVOICE,
  GENERATE_INVOICE_SUCCESS,
  GET_ALL_INVOICE_AFFILIATE,
  INVOICE_FAILURE,
  INVOICE_REQUEST,
  UPDATE_INVOICE_SUCCESS,
} from "../constants";
import {
  DELETE_AFFILIATE_INVOICE_URL,
  GENERATE_INVOICE_URL,
  GET_ALL_INVOICE_AFFILIATE_URL,
  UPDATE_INVOICE,
} from "../../utils/api/urls";

export const generateInvoice = (payload) => {
  return async (dispatch) => {
    dispatch({ type: INVOICE_REQUEST });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Add any other headers if needed
      };
      const response = await axios.post(GENERATE_INVOICE_URL, payload, {
        headers,
      });
      if (response?.data) {
        dispatch({
          type: GENERATE_INVOICE_SUCCESS,
          payload: response?.data?.data,
        });
      }
      return response?.data;
    } catch (error) {
      dispatch({ type: INVOICE_FAILURE });
    }
  };
};

export const getAllInvoices = () => {
  return async (dispatch) => {
    dispatch({ type: INVOICE_REQUEST });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Add any other headers if needed
      };
      const invoiceData = await axios.get(GET_ALL_INVOICE_AFFILIATE_URL, {
        headers,
      });
      if (invoiceData?.data) {
        dispatch({
          type: GET_ALL_INVOICE_AFFILIATE,
          payload: invoiceData?.data?.data?.paginatedData,
        });
        return invoiceData?.data;
      }
    } catch (error) {
      return error?.response?.data;
    }
  };
};
export const updateInvoice = (id,payload) => {
  return async (dispatch) => {
    dispatch({ type: INVOICE_REQUEST });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        // Add any other headers if needed
      };
      const response = await axios.put(`${UPDATE_INVOICE}/${id}`, payload, { headers });  
      if(response?.data)
      {
        dispatch({
          type:UPDATE_INVOICE_SUCCESS,
          payload:response?.data?.data,
        })
      }
      return response?.data;
    } catch (error) {
      console.log("errorrrr", error);
    }
  };
};
export const deleteAffiliateInvoice = (id) => {
  return async (dispatch) => {
    dispatch({ type: INVOICE_REQUEST });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      };
      const response = await axios.delete(
        `${DELETE_AFFILIATE_INVOICE_URL}/${id}`,
        { headers }
      );
      if (response?.data) {
        dispatch({
          type: DELETE_AFFILIATE_INVOICE,
          payload: response?.data?.data,
        });
      }
      return response?.data;
    } catch (error) {
      console.log("error", error);
    }
  };
};
